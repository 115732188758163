import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import {
    Row,
    Col,
} from "antd";
import Restaurant from '../../assets/images/restaurant.png'
import Beach from '../../assets/images/beach.png'

function Nearby({ property_id }) {
    const [nearby, setNearby] = useState(null)
    useEffect(() => {
        Axios.get(`https://api.roamhome.in/api/v2/public/misc/nearby/${property_id}`)
            .then((response) => {
                setNearby(response.data)
            })
            .catch(() => {
                setNearby(null)
            })
    }, [property_id])


    return (
        <div>
            {nearby !== null && (
                <>
                    <h2>Places Nearby</h2>
                    <Row gutter={8}>
                        <Col xs={24} lg={12}>
                            <div className="places-nearby">
                                <div className="title-places">
                                    <h3>Top Restaurants</h3>
                                </div>
                                <div className="items">
                                    {nearby.resturants.slice(0, 5).map((item, idx) => (
                                        <div className="item">
                                            <Row align="middle" gutter={8}>
                                                <Col xs={3}>
                                                    <div className="item-logo">
                                                        <img src={Restaurant} alt="resturants" />
                                                    </div>
                                                </Col>
                                                <Col xs={21}>
                                                    <div className="item-description">
                                                        <div className="item-title">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24}>
                                                    <div className="item-details">
                                                        {item.distance} km, rated {item.rating} / 5 by {item.user_ratings_total} people.
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="places-nearby">
                                <div className="title-places">
                                    <h3>Attractions</h3>
                                </div>
                                <div className="items">
                                    {nearby.attractions.slice(0, 5).map((item, idx) => (
                                        <div className="item">
                                            <Row align="middle" gutter={8}>
                                                <Col xs={3}>
                                                    <div className="item-logo">
                                                        <img src={Beach} alt="beach" />
                                                    </div>
                                                </Col>
                                                <Col xs={21}>
                                                    <div className="item-description">
                                                        <div className="item-title">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24}>
                                                    <div className="item-details">
                                                        {item.distance} km, rated {item.rating} / 5 by {item.user_ratings_total} people.
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default Nearby
