import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

//const { Sider } = Layout;

const Sidebar = ({ toggle }) => {
  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        style={{ background: "#f0f2f5" }}
      >
        <Menu.Item key="Terms">
          <Link to={`/terms`}>
            <span>General Terms and Condtions</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="Payment">
          <Link to={`/terms/payment`}>
            <span>Payments Policy</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="cancellation">
          <Link to={`/terms/cancel`}>
            <span>Cancellation Policy</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="add">
          <Link to={`/terms/add`}>
            <span>Additional Points</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="early">
          <Link to={`/terms/early`}>
            <span>Early and Late Check Out</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="double">
          <Link to={`/terms/double`}>
            <span>Double Bookings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="indemnity">
          <Link to={`/terms/indemnity`}>
            <span>Indemnity</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="juridiction">
          <Link to={`/terms/juridiction`}>
            <span>Jurisdiction</span>
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Sidebar;
