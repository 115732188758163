import React from "react";

function Adding() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        – A booking cannot be cancelled/modified after the check-in time
        mentioned in the property description, and in the event of any such
        cancellations/modifications (including when a guest checks out prior to
        the specified check-out date in the booking), Roamhome will not be
        liable to provide you with any refunds.
      </p>
      <p>
        – The check-in time is as per the Indian standard time, and will be
        assumed to be 1 PM, IST on the date of the check-in, in case the same is
        not specified in the property description.
      </p>
      <p>
        – Please reach out to the Roamhome team if your request for
        cancellation/modification is due to any emergency (including, death in
        the family, medical conditions, governmental disruptions, acts of God,
        etc.) Roamhome will, at its discretion, waive any and all penalties and
        provide refunds, after reviewing any provided documentation and
        evaluating the genuineness of the circumstances. Please do note that
        this will be done on a case to case basis and is the exception and not
        the norm.
      </p>
      <p>
        – Please do reach out to the Roamhome crew, within 24 hours of your
        check-in time (in Indian Standard Time) in case of any
        issues/disagreements in relation to the properties. Roamhome will work
        closely with you to resolve any issues and provide refunds for any
        consequent cancellations/modifications or assist you to find comparable
        accommodation, at its sole discretion. The decision of Roamhome in this
        regard will be considered final and binding.
      </p>
      <p>
        –  Roamhome reserves the right to communicate with the user, or prospective user, via any electronic means (such as e-mail, whatsapp, sms) or non-electronic means (phone call), as it deems fit and appropriate to ensure timely and requisite communication in furtherance of maintaining quality standards of service.
      </p>
      <p>
        – Roamhome has the right to modify or cancel any bookings in the event
        the guest is violating the rules of the property or the homeowner has
        documented any reasonable concerns in relation to the guest’s stay.
        Roamhome shall not be responsible for refunding any booking amount in
        such a situation, and in its discretion may levy reasonable penalties.
        Rest assured, if Roamhome cancels/modifies your bookings due to
        emergencies or circumstances unrelated to the conduct of the guests, it
        shall refund the entire booking amount/the booking amount for the
        remainder of your stay.
      </p>
      <p>
        – Please contact Roamhome for any other issue with respect to the
        cancellation/modification/re-scheduling of your stay. Roamhome shall use
        its discretion to resolve the issue and decide on the requirement of
        levying penalties, and its decision in this regard will be considered
        final and binding.
      </p>
      <p>
        – Extension of stay would be provided on the basis of availability and
        on the prices as are available at the time the extension is sought.
      </p>
      <p>
        – Any damage caused to the property, or any portion thereof, whether movable or immovable, superficial or otherwise, in the duration of the guests stay at the property, will need to be borne by the guests in cash, before the checkout from the property. And in case the damage is reported after checkout, then within two working days of when information of the damage is communicated to them. Failure to do so will invoke legal proceedings. It is the duty of the guests to report prior damage at the time of check-in, or during the course of their stay, otherwise it shall be assumed that such damage was caused by them and they will be required to compensate for it immediately.
      </p>
      <p>
        – Roamhome reserves the right to cancel the booking, and have the guests vacate the property with immediate effect in case it finds the guest guilty of carrying a firearm, dangerous knife, consuming alcohol, or behaving inappropriately. Roamhome's decision on such an occasion shall be final and binding on all parties. The guest will not be entitled to any refund in such a situation.
      </p>
    </div>
  );
}

export default Adding;
