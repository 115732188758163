import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Input,
  Button as Button1,
  Modal,
  Form,
  message,
  Typography,
 
} from "antd";

import axios from "axios";

const { Text, Title } = Typography;

const style = {
  marginTop: "50px",
  marginLeft: "50px",
  width: "420px",
};

export default function Header({ history }) {
  const [user, setUser] = useState();
  const [state, setState] = useState({ visible: false });
  //   const [email, setEmail] = useState("");

  const requestPassword = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/forgot_password`,
        { email: user },
        config
      );

      const data = await res.data;
      if (data.status === 200) {
        setState({
          visible: true,
        });
        
      } else {
        message.info(data.remarks)
      }
    } catch (err) {
      message.error("No Such User")
      
    }
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const redirect = () => {
    sleep(1500).then(() => {
      history.push("/")
    })
    
  }

  const resetPassword = async (values) => {
  
    const dataTo = {
      email: user,
      otp: parseInt(values.otp),
      password: values.password,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
    `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/reset_password`,
      dataTo,
      config
    );
    const data = await res.data;
    if(data.status === 200) {
      setState({
        visible: false,
      });
     
      message.success(data.remarks)
      redirect()

    } else {
      message.info(data.remarks)
    }
  };

  const handleCancel = (e) => {
    
    setState({
      visible: false,
    });
  };

  const onFinishFailed = (errorInfo) => {
    
  };



  return (
    <div
      className="header-wrapper"
      style={{
        padding: "45px",
        textAlign: "center",
      }}
    >
      <h1>Request Password Reset </h1>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Form>
          <Col>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input Email!" }]}
            >
              <Input
                style={{ width: "260px" }}
                placeholder="Email"
                onChange={(e) => setUser(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button1
                type="primary"
                onClick={requestPassword}
                style={{ textAlign: "center" }}
              >
                Submit
              </Button1>
            </Form.Item>
          </Col>
        </Form>
      </Row>
      <div>
        <Modal
          title={
            <Title level={2} style={{ textAlign: "center" }}>
              <Text strong>Set New Password</Text>
            </Title>
          }
          className="pop_up_Reset"
          visible={state.visible}
          onCancel={handleCancel}
          id="myForm"
          footer={[
            <div>
              <Button1 onClick={handleCancel} key="submit" htmlType="submit">
                Cancel
              </Button1>
              <Button1 form="myForm" key="submit" htmlType="submit">
                Submit
              </Button1>
            </div>,
          ]}
        >
          <Row>
            <Col xs={8} lg={8}>
              <Form
                id="myForm"
                onFinish={resetPassword}
                onFinishFailed={onFinishFailed}
                size="large"
                style={style}
              >
                <Col style={{ padding: "8px" }}>
                  <label>Email</label>
                  <Input
                    value={user}
                    disabled
                    style={{ textAlign: "center" }}
                  />
                </Col>
                <Col style={{ padding: "8px" }}>
                  <label>OTP</label>
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please input your OTP!",
                      },
                    ]}
                  >
                    <Input style={{ textAlign: "center" }} />
                  </Form.Item>
                </Col>

                <Col style={{ padding: "8px" }}>
                  <label>New Password</label>
                  <Form.Item
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="input password" />
                  </Form.Item>
                </Col>
                <Col style={{ padding: "8px" }}>
                  <label>Confirm Password</label>
                  <Form.Item
                    name=""
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="input password" />
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
}
