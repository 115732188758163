import React from "react";
import Slider from "react-slick";
import { Row, Col, Rate } from "antd";
// import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
/*import feedbackOne from "../../assets/images/hero-image.jpg"; */
import User from "../../assets/images/user.jpg";
import Ann from "../../assets/images/,,.svg";
import moment from "moment";

function Testimonials({ data }) {

  const settings = {
    className: "slideTestimonial",
    //centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    // autoplay: true,
    // autoplaySpeed: 6000,
  };

  return (
    <div className="testimonials container">
      <h1>{data.title}</h1>
      <div className="slider">
        <Slider {...settings}>
          {data.testimonials ? data.testimonials.map((x, i) => (
                <div className="slide" key={i}>
                  <Row className="item">
                    <Col className="testimony-img" md={9}>
                      <img src={x.property_img} alt="feedback" />
                    </Col>
                    <Col className="testimony-text" md={15}>
                      <img src={Ann} alt="ann" className="ann" />

                      <p className="testimony-description">{x.description}</p>
                      <div className=" text-center">
                        <Rate
                          disabled
                          defaultValue={x.rating}
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="testimony-user">
                        <div className="user-avatar">
                          <img
                            src={x.user_img ? x.user_img : User}
                            alt="user"
                          />
                        </div>
                        <div className="user-title">
                          <div className="user_text">
                          <h3>{x.name}</h3>,&nbsp; 
                          <p className="user_location">{x.location}</p>
                          </div>
                          <p className="tesstimonail_display">
                            {x.property_name}
                          </p>
                          <p>{moment(x.timestamp).format("ll")}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
            : ""}
        </Slider>
      </div>
    </div>
  );
}
export default Testimonials;
