import React, { useState } from "react";
import "antd/dist/antd.css";
import {
  Button as Button1,
  Drawer,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import LoginRegister from "./Login";

import {
  MenuOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/images/black_logo.svg";


function Header(props) {

  const [visible, setVisible] = useState(false);



  const onClose = (e) => {
    setVisible(!visible);
  };

  const handleClickLink = (val) => {
    if (val === "about") {
      props.history.push("/about-us");
    } else if (val === "contact") {
      props.history.push("/contact-us");
    } else if (val === "home") {
      props.history.push("/");
    } else if (val === "listings") {
      props.history.push("/search");
    } else if (val === "terms") {
      props.history.push("/terms");
    } else if (val === "policies") {
      props.history.push("/policies");
    } else if (val === "culture") {
      props.history.push("/culture");
    } else if (val === "career") {
      props.history.push("/careers");
    } else if (val === "faqs") {
      props.history.push("/faqs");
    } else if (val === "host") {
      props.history.push("/host");
    } else if (val === "covid") {
      props.history.push("/covid")
    } else {
      props.history.push("/");
    }

    setVisible(false);
  };

  return (
    <div className="header-wrapper">
      <Drawer
        title={
          <div>
            <LoginRegister />
          </div>
        }
        placement="right"
        closable
        onClose={onClose}
        visible={visible}
        width={300}
      >
        <div className="sidebar-content">
          <div className="sidebar-content-menu">
            <ul>
              <li onClick={() => handleClickLink("home")}>Home</li>
              <li onClick={() => handleClickLink("covid")}>
                {" "}
                {/* <Covid /> */}
                Covid
              </li>
              <li onClick={() => handleClickLink("listings")}>Stays</li>
              <li onClick={() => handleClickLink("about")}>About Us</li>
              <li onClick={() => handleClickLink("contact")}>Contact Us</li>
              <li onClick={() => handleClickLink("terms")}>Terms Of Use</li>
              <li onClick={() => handleClickLink("policies")}>
                Privacy Policies
              </li>
              <li onClick={() => handleClickLink("culture")}>Culture</li>
              <li onClick={() => handleClickLink("career")}>Careers</li>
              <li onClick={() => handleClickLink("faqs")}>FAQ's</li>
            </ul>
          </div>
          <div className="sidebar-content-host-button">
            <Button1
              onClick={() => handleClickLink("host")}
              className="btn menu_btn"
            >
              Host With Us
            </Button1>
          </div>
          <ul className="sidebar-content-footer">
            {/* <div className="social_icons"> */}
            <li className="social">
              <span className="fa fa-facebook"></span>
            </li>
            <li className="social">
              <span className="fa fa-linkedin"></span>
            </li>
            <li className="social">
              <span className="fa fa-instagram"></span>
            </li>
            <li className="social">
              <span className="fa fa-twitter"></span>
            </li>
            {/* </div> */}
          </ul>
        </div>
      </Drawer>
      <div className="navBar my_fonts">
        <Link to="/">
          <div className="header_logo">
            <img src={Logo} className="img_logo " alt="Roamhome - Logo" height="30px" />

            <h2 className="logo-title">Roamhome</h2>
          </div>
        </Link>
        <div className="menu_list">
          { /* <Covid /> */}
          <div className="covid_with_us_btn"> 
            <Link to="/covid">
              <Button1 className="host  menu_btn2" type="link">
                COVID-19
              </Button1>
            </Link>
          </div>
          
          <div className="host_with_us_btn" style={{ marginRight: "2rem" }}>
            
            <Link to="/host">
              <Button1 className="host  menu_btn" type="link">
                Host With Us
              </Button1>
            </Link>
          </div>
          <LoginRegister />
        </div>

        <div className="hamburger-icon">
          <Button1
            className="hamburger-icon-btn"
            icon={<MenuOutlined />}
            onClick={() => setVisible(!visible)}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
