import React from "react";
import { Row, Col } from "antd";
/* import Img from "../../assets/images/hero-image.jpg"; */
import { Link } from "react-router-dom";

function OurStory({ data }) {
 
  return (
    <Col lg={24} xs={24} className="container">
      <Row align="middle" justify="space-between our-story">
        <Col xs={24} sm={24} lg={10} className="story-wrapper">
          <h1>{data.title}</h1>
          <div>
            <p>{data.description}</p>
            {/*<p>{desc[0]}</p>
            <p>{desc[1]}</p>*/}
          </div>

          <div className="read-more" style={{ marginTop: "2rem" }}>
            <Link to="/about-us">
              <p className="btn">Read more</p>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} className="story-image">
          <img src={data.img} alt="ourstory" height="300px" />
        </Col>
      </Row>
    </Col>
  );
}

export default OurStory;
