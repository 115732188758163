import AirCondition from "../../assets/images/viewSvg/Amenities/air_conditioning.svg";
import Balcony from "../../assets/images/viewSvg/Amenities/balcony.svg";
import BarbequeGrill from "../../assets/images/viewSvg/Amenities/barbeque_grill.svg";
import BathroomSlippers from "../../assets/images/viewSvg/Amenities/bathroom_slippers.svg";
import BedLinen from "../../assets/images/viewSvg/Amenities/bed_linen.svg";
//import Breakfast from "../../assets/images/viewSvg/Amenities/breakfast.svg";
import CableTv from "../../assets/images/viewSvg/Amenities/cable_tv.svg";
// import carbonMonoxideAlarm from "../../assets/images/viewSvg/carbon_monoxide_alarm.svg";
import cleaningBeforeCheckout from "../../assets/images/viewSvg/Amenities/cleaning_before_checkout.svg";
import coffeeMachine from "../../assets/images/viewSvg/Amenities/coffee_machine.svg";
import cooking_basics from "../../assets/images/viewSvg/Amenities/cooking_basics.svg";
import dishes_and_silverware from "../../assets/images/viewSvg/Amenities/dishes_and_silverware.svg";
import dryer from "../../assets/images/viewSvg/Amenities/dryer.svg";
import essentials from "../../assets/images/viewSvg/Amenities/essentials.svg";
import extra_pillows_and_blankets from "../../assets/images/viewSvg/Amenities/extra_pillows_and_blankets.svg";
import fire_extinguisher from "../../assets/images/viewSvg/Amenities/fire_extinguisher.svg";
import fireplace from "../../assets/images/viewSvg/Amenities/fireplace.svg";
import first_aid_kit from "../../assets/images/viewSvg/Amenities/first_aid_kit.svg";
import free_parking_on_premises from "../../assets/images/viewSvg/Amenities/free_parking_on_premises.svg";
import garden_backyard from "../../assets/images/viewSvg/Amenities/garden_backyard.svg";
import geyser from "../../assets/images/viewSvg/Amenities/geyser.svg";
import hair_dryer from "../../assets/images/viewSvg/Amenities/hair_dryer.svg";
import hangers from "../../assets/images/viewSvg/Amenities/hangers.svg";
import host_greets_you from "../../assets/images/viewSvg/Amenities/host_greets_you.svg";
import hot_tub from "../../assets/images/viewSvg/Amenities/hot_tub.svg";
import iron from "../../assets/images/viewSvg/Amenities/iron.svg";
import jacuzzi from "../../assets/images/viewSvg/Amenities/jacuzzi.svg";
//import kettle from "../../assets/images/viewSvg/Amenities/kettle.svg";
import kitchen from "../../assets/images/viewSvg/Amenities/kitchen.svg";
import laundry_bag from "../../assets/images/viewSvg/Amenities/laundry_bag.svg";
import long_term_stay from "../../assets/images/viewSvg/Amenities/long_term_stay.svg";
import luggage_dropoff from "../../assets/images/viewSvg/Amenities/luggage_dropoff.svg";
import microwave from "../../assets/images/viewSvg/Amenities/microwave.svg";
import oven from "../../assets/images/viewSvg/Amenities/oven.svg";
import parking from "../../assets/images/viewSvg/Amenities/parking.svg";
import pots_pans_salt_pepper from "../../assets/images/viewSvg/Amenities/pots_pans_salt_pepper.svg";
import private_entrance from "../../assets/images/viewSvg/Amenities/private_entrance.svg";
import private_pool from "../../assets/images/viewSvg/Amenities/private-pool.svg";
import Refrigerator from "../../assets/images/viewSvg/Amenities/Refrigerator.svg";
import room_heater from "../../assets/images/viewSvg/Amenities/room_heater.svg";
import sanitory_bag from "../../assets/images/viewSvg/Amenities/sanitory_bag.svg";
import separate_building_entry from "../../assets/images/viewSvg/Amenities/separate_building_entry.svg";
import sewing_kit from "../../assets/images/viewSvg/Amenities/sewing_kit.svg";
import shampoo from "../../assets/images/viewSvg/Amenities/shampoo.svg";
import shared_pool from "../../assets/images/viewSvg/Amenities/shared_pool.svg";
import shaving_kit from "../../assets/images/viewSvg/Amenities/shaving_kit.svg";
import shower_cap from "../../assets/images/viewSvg/Amenities/shower_cap.svg";
//import smoke_detector from "../../assets/images/viewSvg/Amenities/smoke_detector.svg";
import space_where_guest_can_cook_thier_own_meal from "../../assets/images/viewSvg/Amenities/space_where_guest_can_cook_thier_own_meal.svg";
import stove from "../../assets/images/viewSvg/Amenities/stove.svg";
import tissues from "../../assets/images/viewSvg/Amenities/tissues.svg";
import toilet_paper from "../../assets/images/viewSvg/Amenities/toilet_paper.svg";
import toothbrush_and_toothpaste from "../../assets/images/viewSvg/Amenities/toothbrush_and_toothpaste.svg";
import tv from "../../assets/images/viewSvg/Amenities/tv.svg";
import washing_machine from "../../assets/images/viewSvg/Amenities/washing_machine.svg";
import Wifi from "../../assets/images/viewSvg/Amenities/wifi.svg";
import Laptop from "../../assets/images/viewSvg/Amenities/laptop_friendly_workspace.svg";

const ammenitiesIcon = (x) => {
  //Amenities
  let imgSrc = "";
  if (x === "Wifi") {
    imgSrc = Wifi;
  }
  if (x === "Pots and pans, oil, salt and pepper") {
    imgSrc = pots_pans_salt_pepper;
  }
  if (x === "Jacuzzi") {
    imgSrc = jacuzzi;
  }
  if (x === "Oven") {
    imgSrc = oven;
  }
  if (x === "Stove") {
    imgSrc = stove;
  }
  if (x === "Dryer") {
    imgSrc = dryer;
  }
  if (x === "TV") {
    imgSrc = tv;
  }
  if (x === "Indoor Fireplace") {
    imgSrc = fireplace;
  }
  if (x === "Pool private") {
    imgSrc = private_pool;
  }
  if (x === "Air Conditioning") {
    imgSrc = AirCondition;
  }
  if (x === "Laptop-friendly workspace") {
    imgSrc = Laptop;
  }
  if (x === "Cable TV") {
    imgSrc = CableTv;
  }
  if (x === "Essentials") {
    imgSrc = essentials;
  }
  if (x === " soap and toilet paper") {
    imgSrc = toilet_paper;
  }
  if (x === " bed sheets") {
    imgSrc = BedLinen;
  }
  if (x === "Towels") {
    imgSrc = toilet_paper;
  }
  if (x === "Hot water") {
    imgSrc = hot_tub;
  }
  if (x === "Heater") {
    imgSrc = room_heater;
  }
  if (x === "Kitchen") {
    imgSrc = kitchen;
  }
  if (x === "Pool Shared") {
    imgSrc = shared_pool;
  }
  if (x === "Refrigerator") {
    imgSrc = Refrigerator;
  }
  if (x === "Microwave") {
    imgSrc = microwave;
  }
  if (x === "Washing machine") {
    imgSrc = washing_machine;
  }
  if (x === "Cooking basics") {
    imgSrc = cooking_basics;
  }
  if (x === "Coffee maker") {
    imgSrc = coffeeMachine;
  }
  if (x === "Dishes and silverware") {
    imgSrc = dishes_and_silverware;
  }
  if (x === "Cleaning before checkout") {
    imgSrc = cleaningBeforeCheckout;
  }
  if (x === "Shampoo") {
    imgSrc = shampoo;
  }
  if (x === "Hangers") {
    imgSrc = hangers;
  }
  if (x === "Bed linen") {
    imgSrc = BedLinen;
  }
  if (x === "Extra pillows and blankets") {
    imgSrc = extra_pillows_and_blankets;
  }
  if (x === "Fire Extinguisher") {
    imgSrc = fire_extinguisher;
  }
  if (x === "First Aid Kit") {
    imgSrc = first_aid_kit;
  }
  if (x === "Toothbrush and toothpaste") {
    imgSrc = toothbrush_and_toothpaste;
  }
  if (x === "Tissues") {
    imgSrc = tissues;
  }
  if (x === "Hair dryer") {
    imgSrc = hair_dryer;
  }
  if (x === "Iron") {
    imgSrc = iron;
  }
  if (x === "BBQ grill") {
    imgSrc = BarbequeGrill;
  }
  if (x === "Shower cap") {
    imgSrc = shower_cap;
  }
  if (x === "Bathroom Slippers") {
    imgSrc = BathroomSlippers;
  }
  if (x === "Geyser") {
    imgSrc = geyser;
  }
  if (x === "Laundry bag") {
    imgSrc = laundry_bag;
  }
  if (x === "Sanitary bag") {
    imgSrc = sanitory_bag;
  }
  if (x === "Shaving kit") {
    imgSrc = shaving_kit;
  }
  if (x === "Sewing kit") {
    imgSrc = sewing_kit;
  }
  if (x === "Balcony") {
    imgSrc = Balcony;
  }
  if (x === "Barbecue Area") {
    imgSrc = space_where_guest_can_cook_thier_own_meal;
  }
  if (x === "Dishes and Silverware") {
    imgSrc = dishes_and_silverware;
  }
  if (x === "Kitchenette") {
    imgSrc = kitchen;
  }
  if (x === "Laptop friendly work space") {
    imgSrc = Laptop;
  }
  if (x === "Swimming Pool") {
    imgSrc = private_pool;
  }
  if (x === "TV Cable") {
    imgSrc = CableTv;
  }
  if (x === "Washing Machine") {
    imgSrc = washing_machine;
  }
  if (x === "Wi-Fi") {
    imgSrc = Wifi;
  }
  if (x === "Hot Tub") {
    imgSrc = hot_tub;
  }
  if (x === "Air conditioning") {
    imgSrc = AirCondition;
  }
  if (x === "Towels, bed sheets, soap and toilet paper") {
    imgSrc = toilet_paper;
  }
  if (x === "Space where guests can cook their own meals") {
    imgSrc = space_where_guest_can_cook_thier_own_meal;
  }
  if (x === "Pots and pans") {
    imgSrc = pots_pans_salt_pepper;
  }
  if (x === " oil") {
    imgSrc = kitchen;
  }
  if (x === " salt and pepper") {
    imgSrc = pots_pans_salt_pepper;
  }
  if (x === "Hot waterShampoo") {
    imgSrc = shampoo;
  }

  //Facilities

  if (x === "Host greets you") {
    imgSrc = host_greets_you;
  }
  if (x === "Free parking on premises") {
    imgSrc = free_parking_on_premises;
  }
  if (x === "Free on-street parking") {
    imgSrc = parking;
  }
  if (x === "Private entrance") {
    imgSrc = private_entrance;
  }
  if (x === "Separate street or building entrance") {
    imgSrc = separate_building_entry;
  }
  if (x === "Luggage drop-off allowed") {
    imgSrc = luggage_dropoff;
  }
  if (x === "Long-term stays allowed") {
    imgSrc = long_term_stay;
  }
  if (x === "Garden or backyard") {
    imgSrc = garden_backyard;
  }
  if (x === "Patio or balcony") {
    imgSrc = Balcony;
  }
  if (x === "Free Parking") {
    imgSrc = free_parking_on_premises;
  }
  if (x === "Patio") {
    imgSrc = Balcony;
  }
  if (x === "Security") {
    imgSrc = private_pool;
  }
  if (x === "Garden") {
    imgSrc = garden_backyard;
  }

  return imgSrc;
};
export default ammenitiesIcon;
