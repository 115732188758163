import React, { useState, useEffect } from "react";
import { Card, Button, message } from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ProfileHeader from "../Booking/booking_header";

function Favourite({ history }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [myfav, setMyFav] = useState([]);

  const token = sessionStorage.getItem("token");
  const [click, setClick] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchFavourite();
    // eslint-disable-next-line 
  }, []);

  const fetchFavourite = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/favourites_get/all`,
        config
      );
      const data = await res;
      if (data.status === 200) {
        setMyFav(data.data);
      }
      if (data.status === 400) {
        message.error("OOPS! ninna dubakuru ");
      }
    } catch (err) {
     
      message.error("OOPS! Something went wrong.");
    }
  };

  const handleFavorite = async (id) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          token,
        },
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
          { property_id: id },
          config
        );

        const data = await res.data;
      
        if (data.status === 200) {
          message.success(data.remarks);
          fetchFavourite();
        }
      } catch (error) {
     
      }
    } else {
      message.info("Please Login to add this property to Favourites");
    }
  };

  const handleRoute = (id) => {
    if (!click) {
      history.push(`/property-details/${id}`);
    }
  };
 
  return (
    <div>
      <ProfileHeader />
      <div className="container">
        <div className="selections-card-wrapper">
          {myfav.length > 0 ? (
            myfav.map((x, i) => (
              // <Fade bottom delay={500}>
              // <Link key={i} to={`/property-details/${x.listing_id}`}>
              <Card
                key={i}
                className="selection-card"
                onClick={() => handleRoute(x.listing_id)}
                style={{
                  boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                }}
                cover={
                  <img
                    src={
                      x.featured_img[0].img_960[0]
                        ? x.featured_img[0].img_960[0].url
                        : ""
                    }
                    alt="selection"
                  />
                }
              >
                <div style={{ marginTop: "2rem" }}>
                  <h3>{x.property_title}</h3>
                </div>
                <Button
                  type="primary"
                  danger
                  shape="round"
                  onMouseEnter={() => setClick(true)}
                  onMouseLeave={() => setClick(false)}
                  onClick={() => handleFavorite(x.listing_id)}
                >
                  Remove
                </Button>
              </Card>
              // </Link>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <h1>No Favourites!</h1>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Favourite);
