import React from "react";

function Indemnity() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        Neither Owner nor Roamhome assumes any liability for loss, theft, damage
        or injury to Guest, his/her guests or other occupants in the Home. The
        Guest, for himself/herself, his/her heirs, assignors, executors and
        administrators, fully releases and discharges Owner and Roamhome from
        any and all claims, demands and causes of action by reason of any injury
        or whatever nature which has or have occurred, or may occur to the
        Guest, his/her guests or other occupants of the Home as a result of, or
        in connection with the occupancy of the Home and agrees to hold Owner
        and Roamhome free and harmless of any claim or suit arising therefrom.
      </p>
    </div>
  );
}

export default Indemnity;
