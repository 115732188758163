import { ForkOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import React, {useEffect, useState} from 'react'
import Axios from 'axios'

function Nearby({data}) {
    const [nearby, setNearby] = useState(null)
    useEffect(() => {
        if(data !== null) {
            Axios.get(`https://api.roamhome.in/api/v2/public/misc/nearby/${data.url}`)
                .then((response) => {
                    console.log(response.data)
                    setNearby(response.data)
                })
                .catch(() => {
                    setNearby(null)
                })
        }
    }, [])
  return (
    <div className='amenitiesWrapper'>
    <div className='amenitiesContentWrap'>
        <div className='amenitiesLegend'>
           Places Nearby
        </div>
        <div className='locationContent'>
            <div className='topResturantsLabel'>
               Top Resturants
            </div>
            <div className='topResturantsList'>
                {nearby && nearby.resturants.slice(0,3).map((item, idx) => (
                    <div key={idx} className='resturantItem'>
                        <div className='resturantName'>
                            {item.name}
                        </div>
                        <div className='resturantDesc'>
                            {item.distance} kms&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;Rated {item.rating}/5 by {item.user_ratings_total} people
                        </div>
                    </div>
                ))}
            </div>
            
            <div style={{marginTop: "20px"}} className='topResturantsLabel'>
               Attractions
            </div>
            <div className='topResturantsList'>
                {nearby && nearby.attractions.slice(0,3).map((item, idx) => (
                    <div key={idx} className='resturantItem'>
                        <div className='resturantName'>
                            {item.name}
                        </div>
                        <div className='resturantDesc'>
                            {item.distance} kms&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;Rated {item.rating}/5 by {item.user_ratings_total} people
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
    </div>
  )
}

export default Nearby