import React, { useState, useEffect } from "react";
import HeroSection from "./heroSection";
import WhyChooseUs from "./whyChooseUs";
import Banner from "./banner";
import PopularDestinations from "./popularDestinations";
import Selections from "./selections";
import Testimonials from "./testimonials";
import OurStory from "./ourStory";
import { withRouter } from "react-router-dom";
import NoNetwork from "./noNetwork";
import Personalised from "./personalised";
import {Hidden} from "@material-ui/core"
import HomeMobile from "./HomeMobile/Index";


import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import axios from "axios";
import { Skeleton } from "antd";
import PopularCities from "./popularCities";

const Home = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [state, setstate] = useState(true);
  const [home, setHome] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchComment();
    if (sessionStorage.getItem("pagination") !== null) {
        sessionStorage.removeItem("pagination");
    }
  }, []);

  async function fetchComment() {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/home`,
        config
      );
      const data = await res.data;
      setHome(data);
    } catch (error) {
      
      setstate(false);
    }
    setLoading(false);
  }
 

  return (
    <div className="main-container">
      <Helmet defer={false} script={[{
        type: 'application/ld+json',
        innerHTML: `{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Roamhome",
          "url": "https://www.roamhome.in/",
          "logo": "https://www.roamhome.in/static/media/black_logo.5ab7241d.svg",
          "sameAs": [
            "https://www.facebook.com/roamhomeswithus/",
            "https://www.instagram.com/roamhomeswithus/?hl=en",
            "https://twitter.com/roamhomeswithus",
            "https://www.linkedin.com/company/roamhome/"
          ]
        }`
      }]}>
        <title>
          {" "}
          Roamhome Vacation Rentals &#8211; Airbnb Property Management Goa, Rent
          villas in Goa, hotels in Himachal &amp; Uttarakhand, villas in
          Rajasthan, villas in Kerala, hotels in Panchgani
        </title>
        <meta name="title" content={"Goa Villa Rentals | Homestays in Chikmagalur, Coorg & More | Roamhome"} />
        <meta
          name="description"
          content="Roamhome offers you the best prices to book villas, cottages & homestays in Coorg, Chikmagalur, Goa, Leh, Mussoorie, Nainital, Mukteshwar, Pakyong & more."
        />
        <meta name="keywords" content="villas in goa, homestay in chikmagalur, homestay in leh, villas in mussoorie, villas in nainital, homestay in mukteshwar, homestay in pakyong, homestay in coorg"/>
      </Helmet>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: 
      `{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Roamhome",
        "url": "https://www.roamhome.in/",
        "logo": "https://www.roamhome.in/static/media/black_logo.5ab7241d.svg",
        "sameAs": [
          "https://www.facebook.com/roamhomeswithus/",
          "https://www.instagram.com/roamhomeswithus/?hl=en",
          "https://twitter.com/roamhomeswithus",
          "https://www.linkedin.com/company/roamhome/"
        ]
      }`
    }}>
    </script>
      {state ? (
        <div className="main-container">
          {!loading ? (
            <div>
             
              {/* Create converteraible element for mobile website separately */}
              <Hidden smUp>
                <div className="mobile-home-segment">
                  <HomeMobile data={home ? home : null}/>
                </div>
              </Hidden>
              
              <Hidden xsDown>
              <Fade bottom={500}>
                <HeroSection data={home.slide ? home.slide : {}} />
              </Fade>
              
                <WhyChooseUs data={home.section_1 ? home.section_1 : {}} />
             
              <Fade bottom={500}>
                <Selections data={home.section_2 ? home.section_2 : {}} />
              </Fade>

              <Fade bottom={500}>
                <PopularDestinations
                  data={home.section_3 ? home.section_3 : {}}
                />
              </Fade>

              <Fade bottom={500}>
                <Banner data={home.section_4 ? home.section_4 : {}} />
              </Fade>

              <Fade bottom={500}>
                <PopularCities
                  data={home.section_3 ? home.section_3 : {}}
                />
              </Fade>

              <Fade bottom={500}>
                <Personalised data={home.section_5 ? home.section_5 : {}} />
              </Fade>

              <Fade bottom={500}>
                <Testimonials data={home.section_6 ? home.section_6 : {}} />
              </Fade>
              <Fade bottom={500}>
                <OurStory data={home.section_7 ? home.section_7 : {}} />
              </Fade>
              </Hidden>
              
            </div>
          ) : (
            <div className="container">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          )}
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
};
export default withRouter(Home);
