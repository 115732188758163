import React, { useState, useRef } from "react";
import { Spin, Form, Button, Card, Input, message as Message } from "antd";
import axios from "axios";
const { TextArea } = Input;

function Index(props) {
    const {url_id} = props.match.params
    const formRef = useRef();
    const [loading, setLoading] = useState(false)
    const handleSubmit = (val) =>  {
        setLoading(true);
        if(val.phone === undefined || val.message === undefined) {
            Message.info("Please Input everything and Submit!");
            return 
        }
        
    
        var config = {
        method: 'post',
        url: `https://${url_id}.ngrok.io/send`,
        data : val
        };
        
        axios(config)
        .then(function (response) {
                setLoading(false)
                Message.success(response.data.status);
                formRef.current.resetFields();
               
        })
        .catch(function (error) {
        
        });
    }


    return (
        <div style={{
            display: "flex",
            "justify-content": "center",
            marginTop: "30px",
            textAlign: "center"
        }}>
            {loading && (
            <Card
                style={{
                width: 350,
                boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                background: "#fff",
                padding: "1rem",
                }}
            >
                <Spin/>
            </Card>
            )}
            {loading === false && (
                <Card
                style={{
                  width: 350,
                  boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                  background: "#fff",
                  padding: "1rem",
                }}
              >
                <Form ref={formRef} onFinish={handleSubmit}>
                   <p>Your Message will be sent from 8013880939</p>
                  <Form.Item name="phone" required="true">
                    <Input placeholder="Mobile Number" />
                  </Form.Item>
                  <Form.Item name="message" required="true">
                    <TextArea rows={6} placeholder="Write a message" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      size="large"
                      className="btn_btn_search"
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            )}
            
        </div>
    )
}

export default Index
