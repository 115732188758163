import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'

const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);
}

function SimilarHomes({ data, history }) {
    const [similarHomes, setSimilarHomes] = useState(null)
    useEffect(() => {
        if (data !== null) {
            Axios.get(`https://api.roamhome.in/api/v1/public/listing/suggestions/location/${data.listing_id}`)
                .then((response) => {
                    console.log("d", response.data)
                    setSimilarHomes(response.data)
                })
                .catch(() => {
                    setSimilarHomes(null)
                })
        }
    }, [])

    const [click, setClick] = useState(false);
    const handleRoute = (id) => {
        if (!click) {
         window.location = `/desc/${id}`
         // history.push(`/desc/${id}`);
        }
    };

    return (
        <div className='amenitiesWrapper'>
            <div className='amenitiesContentWrap'>
                <div className='amenitiesLegend'>
                    Similar Homes
                </div>
            </div>
            <div className='similarHomes'>
            <div className='wrapBestSelections'>
                <div className='selectionContent'>
                    <div className='emptyBox'></div>
                    {data !== null && similarHomes !== null && (
                        similarHomes.map((item, idx) => (
                            <Link key={idx} rel="noopener noreferrer" to={`/desc/${item.url}`} onClick={(e) => { e.preventDefault(); handleRoute(item.url); }}>
                                <div className='contentContainer'>
                                    <div className='imageBox'>
                                        <img src={item.featured_img[0].img_480[0].url} />
                                    </div>
                                    <div className='locationBox'>
                                        {item.city}, {item.state}
                                    </div>
                                    <div className='propertyName'>
                                        {item.property_title}
                                    </div>
                                    <div className='priceNight'>
                                        {numberFormat(item.price_night)} / night
                                    </div>
                                </div>
                            </Link>
                        ))

                    )}
                    <div className='emptyBox'></div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default withRouter(SimilarHomes)