import React from "react";
import { Row, Col } from "antd";

const WhyUS = ({ data }) => {

  return (
    <div className="whyUs container">
      {/* <Fade left> */}
      <h1>{data.title}</h1>
      {/* </Fade> */}
      <Row>
        <Col className="whyUs-grid-wrapper" lg={24} xs={24}>
          <Row className="row-one">
            {data.reasons
              ? data.reasons.map((x) => (
                  <Col className="col-card" xs={12} lg={8}>
                    <div className="img-wrapper">
                      <img src={x.icon} alt="why-us" height="35px" />
                    </div>
                    <div className="title">
                      <h3>{x.title}</h3>
                    </div>
                    <div className="card-content">{x.text}</div>
                  </Col>
                ))
              : ""}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default WhyUS;
