import React, { useState, useEffect } from "react";
import { Row, Col, Avatar, Button, Input, message } from "antd";
import {
  // MessageOutlined,
  // HeartOutlined,
  // BookOutlined,
  // PhoneOutlined,
  MailOutlined,
  UserOutlined,
  // HomeOutlined,
  // LayoutOutlined,
} from "@ant-design/icons";
import axios from "axios";
// import ProfileSvg from "../../assets/images/host/profileSvg.svg";
// import LogoutSvg from "../../assets/images/host/logout.svg";
import ProfileHeader from "../Booking/booking_header";

const loginEmail = sessionStorage.getItem("email");

const emailAdd = loginEmail ? loginEmail : "";
const user_name = emailAdd.substring(0, emailAdd.lastIndexOf("@"));

function EditProfile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token,
    },
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/info`,
        config
      );
      const data = await res;
      if (data.status === 200) {
        setData(data.data);
      }
      if (data.status === 400) {
        message.error("OOPS! ninna dubakuru ");
      }
    } catch (err) {
    
      message.error("OOPS! Something went wrong.");
    }
  };
  const handldeSubmit = async () => {
    try {
      const res = axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/name_change`,
        { name },
        config
      );
      const data = res.data;
  
      message.success(data.remarks);
      // window.location.reload();
    } catch (error) {
    
    }
  };
 
  return (
    <div>
      <ProfileHeader />
      <div className="container">
        <Row className="left_row">
          <Col xs={24} lg={11} className="margin_auto">
            <div className="left_col">
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                }}
                className="avatar_image"
                size={100}
              >
                {" "}
                <span style={{ textTransform: "capitalize", fontSize: "24px" }}>
                  {" "}
                  {user_name}
                </span>
              </Avatar>
              {/* <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  verticalAlign: "middle",
                }}
                size="large"
                className="avatar_image"
              >
                <span style={{ textTransform: "capitalize", fontSize: "14px" }}>
                  {" "}
                  {user_name}
                </span>
              </Avatar> */}
              <h3 className="edit_profile_name"> {data.name}</h3>
            </div>
          </Col>
          <Col xs={24} lg={11} className="margin_auto">
            <div className="input_host">
              <Input
                size="large"
                placeholder="Email"
                value={data.email}
                disabled
                prefix={<MailOutlined />}
              />
            </div>
            <div className="input_host">
              <Input
                size="large"
                placeholder="Name"
                value={name === "" ? data.name : name}
                prefix={<UserOutlined />}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <Button
              disabled={name !== "" ? false : true}
              type="danger"
              onClick={() => handldeSubmit()}
              style={{ background: "#EB5148" }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
      <div style={{ textAlign: "center" }}></div>
      <Row>
        <Col xs={24} lg={22} offset={1}>
          <div className="social_icon_footer">
            <li className="social_icon_margin_footer">
              <span className="fa fa-facebook"></span>
            </li>
            <li className="social_icon_margin_footer">
              <span className="fa fa-linkedin"></span>
            </li>
            <li className="social_icon_margin_footer">
              <span className="fa fa-instagram"></span>
            </li>
            <li className="social_icon_margin_footer">
              <span className="fa fa-twitter"></span>
            </li>
          </div>
          <p className="footer_profile">RoamHome © 2020. All Rights Reserved</p>
        </Col>
      </Row>
    </div>
  );
}
export default EditProfile;
