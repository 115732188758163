import { ArrowRightOutlined } from '@ant-design/icons'
import React, {useState} from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment'

const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);
}

function PopularDestinations({data, history}) {
    const today = moment().toISOString()
    const tommorrow = moment().add(1, "days").toISOString()

    const [click, setClick] = useState(false);
    const handleRoute = (id) => {
        if (!click) {
         
          history.push(`/desc/${id}`);
        }
      };

    return (
        <div className='wrapPopularDestinations'>
            
            <div className='titleMobileWrap'>
                <div className='captionWrap'>
                    Search By State
                </div>
                <div className='actionBar'>
                    <Link to={`/search?limit=10`}>
                        <button>
                            <ArrowRightOutlined />
                        </button>
                    </Link>
               
            </div>
            </div>
            <div className='selectionContent'>
                <div className='emptyBox'></div>
                {data !== null && (
                    data.section_3.destinations.map((item, idx) => (
                        <Link key={idx} to={`/search?area=${item.destination}&secondary=India&type=default&limit=10&check_in=${today}&check_out=${tommorrow}`}>
                        <div className='contentContainer'>
                            <div className='imageBox'>
                                <img src={item.img} />
                                <div className='placeName'>{item.destination_id}</div>
                            </div>
                            
                        </div>
                        </Link>
                    ))

                )}
                <div className='emptyBox'></div>
            </div>
        </div>
    )
}

export default withRouter(PopularDestinations)