import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Skeleton,
  Rate,
  message,
  Input,
  Divider,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { Tooltip } from "antd";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import moment from "moment";
import axios from "axios";
import Logo from "../../assets/images/viewSvg/mapMarker.svg";
// import { SRLWrapper } from "simple-react-lightbox";
// import SimpleReactLightbox from "simple-react-lightbox";
import Slider from "react-slick";
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/BlackborderHeart.svg";
import Infant from "../../assets/images/viewSvg/infant_friendly.svg";
import NotInfant from "../../assets/images/viewSvg/not_infant_friendly.svg";
import Smoking from "../../assets/images/viewSvg/Smoking.svg";
import NoSmoking from "../../assets/images/viewSvg/no_smoking.svg";
import Pets from "../../assets/images/viewSvg/Pets.svg";
import NoPets from "../../assets/images/viewSvg/no_pets.svg";
import NoParty from "../../assets/images/viewSvg/no_party.svg";
import Party from "../../assets/images/viewSvg/Party.svg";
import Bed from "../../assets/images/bed.svg";
import Guest from "../../assets/images/Guests.svg";
import Place from "../../assets/images/place.svg";
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import NoNetwork from "../Home/noNetwork";
import AmenitiesIcon from "./amenitiesIcon";
import parse from "html-react-parser";
import {
  StarFilled,
  PlusOutlined,
  MinusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

function PropertyView(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [network, setNetwork] = useState(true);
  const [show, setShow] = useState(false);

  const [home, setHome] = useState({});
  const [reviews, setReviews] = useState({});
  const [price, setPrice] = useState();
  const [disable, setDisable] = useState(true);
  const [similarProprties, setSimilarProperties] = useState({});
  const [favourites, setFavourites] = useState();

  const [visible, setVisible] = useState(false);
  const [coupon, setCoupon] = useState("");

  const [open, setOpen] = useState(false);

  const [block, setBlock] = useState([]);

  const {
    state,
    check_in,
    check_out,
    property_id,
  } = props.match.params;

  const [guests, setGuests] = useState({
    adults:
      props.match.params.adults === "0"
        ? 1
        : props.match.params.adults
        ? parseInt(props.match.params.adults)
        : 1,
    children: props.match.params.children
      ? parseInt(props.match.params.children)
      : 0,
  });

  const [checkin, setCheckin] = useState(
    props.match.params.check_in ? props.match.params.check_in : ""
  );

  const [checkout, setCheckout] = useState(
    props.match.params.check_out ? props.match.params.check_out : ""
  );


  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    window.scrollTo(0, 0);

    if (token) {
      fetchFavourites();
    }

    fetchView();
    fetchReviews();
    fetchPrice();
    FetchSimilarProperties();
    fetchBlockDates();
    //eslint-disable-next-line
  }, [property_id]);

  async function fetchView() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/view_property/${property_id}`,
        config
      );
      const data = await res.data;
      setHome(data);
      
    } catch (error) {
     
      setNetwork(false);
    }
    
  }

  async function fetchReviews() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/reviews/all/${property_id}`,
        config
      );
      const data = await res.data;
      setReviews(data);
      
    } catch (error) {
     
    }
  }

  async function fetchBlockDates() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/get_blocks/${property_id}/08/2020`,
        config
      );
      const data = await res.data;
      // setReviews(data);
      setBlock(data);
     
    } catch (error) {
     
    }
  }

  async function FetchSimilarProperties() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/suggestions/location/${property_id}`,
        config
      );
      const data = await res.data;
      setSimilarProperties(data);
      
    } catch (error) {
     
    }
  }

  async function fetchPrice() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const postData = {
      properties: [
        {
          property_id,
          start_date: moment(checkin).format("YYYY-MM-DD"),
          end_date: moment(checkout).format("YYYY-MM-DD"),
          coupon_code: coupon,
          guests: guests.adults,
          children: "",
        },
      ],
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
        postData,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        setPrice(data.bifurcated[0]);
        setDisable(false);
      }
      setPrice(data.bifurcated[0]);
      setDisable(false);
    
    } catch (error) {
      
      if (error.response) {
        message.info(error.response.data.remarks);
      }
    }
  }

  async function fetchFavourites() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/favourites/${property_id}`,
        config
      );

      const data = await res.data;
      if (res.status === 200) {
        setFavourites(data.favourite);
      }
      
    } catch (error) {
      
    }
  }

  const handleFavorite = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
        { property_id },
        config
      );

      const data = await res.data;
     
      if (data.statues === 200) {
        message.success(data.remarks);
      }
      fetchFavourites();
    } catch (error) {
     
      message.info("Please Login to add this property to favourites!");
    }
  };

  const handleAdult = (e, val) => {
    if (e === 0) {
      setGuests({ ...guests, adults: val - 1 });
    }
    if (e === 1) {
      setGuests({ ...guests, adults: val + 1 });
    }
  };

  const click = () => {
    props.history.push(
      `/property-details/${property_id}/${state}/${guests.adults}/${
        guests.children
      }/${moment(checkin).format()}/${moment(checkout).format()}/payments`
    );
  };

  const clickPropertySimilar = (id) => {
    props.history.push(`/property-details/${id}`);
   
  };

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  function onChange(value, dateString) {
    setPrice();
    setCheckin(dateString[0]);
    setCheckout(dateString[1]);
  }

  const time = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ];

  const settings = {
    className: "center",
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const disabledDate = (current) => {
    // return block.map((x) => x.ota_date_from);

    const result = block.map((x) => {
      let start = x.ota_date_from;
      let end = x.ota_date_to;
      if (current < moment(start)) {
        return false;
      } else if (current > moment(end)) {
        return false;
      } else {
        return true;
      }
    });

    return result[0];
    
  };

  const parsedText = parse(home.property_desc ? home.property_desc : "");

  return (
    <div>
      <Helmet>
        <title>{home.seo_title}</title>
        <meta
          name="description"
          content="Roamhome is a rental portal that provides curated homes with professional service. Our properties are spread across budgets without compromising our service."
        />
      </Helmet>
      {network ? (
        <div className="view-listing">
          <Row>
            <Col xs={24} lg={24} className="property_view">
              <div className="property-slider">
               
                    {!home.imgs ? (
                      <Skeleton active />
                    ) : (
                      <Carousel centered infinite arrows slidesPerPage={2}>
                        {home.imgs[0].img_960.map((x) => (
                          <div>
                            <img src={x.url} alt={x.alt} />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  
              </div>

              <div className="slider small-carousal">
                <Slider {...settings}>
                  {home.imgs ? (
                    home.imgs[0].img_960.map((x) => (
                      <div className="slide">
                        <img src={x.url} alt={x.alt} />
                      </div>
                    ))
                  ) : (
                    <Skeleton />
                  )}
                </Slider>
              </div>

              <div className="property_link">
                <p className="_link">
                  <Link to="/">Home</Link>{" "}
                </p>
                <span>
                  <RightOutlined />
                </span>
                <p className="_link">
                  <Link to="/search">Search</Link>{" "}
                </p>
                <span>
                  {" "}
                  <RightOutlined />{" "}
                </span>
                <p className="_link property_name">{home.state}</p>
              </div>
            </Col>
          </Row>
          <div className="containr_view">
            <Row justify="space-between">
              <Col xs={24} lg={14}>
                <Tooltip title={home.property_title}>
                  <h1 className="prop-title">{home.property_title}</h1>
                </Tooltip>

                <p className="location">{home.address}</p>

                <Row className="icons">
                  <Col span={6} className="list_icons">
                    <img src={Place} alt="place" />
                    <p className="icon-title">{home.type_of_listing}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={Bed} alt="bed" />
                    <p className="icon-title">{home.num_of_bedroom} {(home.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={BathTab} alt="bed" />
                    <p className="icon-title">{home.num_of_bath} {(home.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={Guest} alt="bed" />
                    <p className="icon-title">{home.num_of_guests} {(home.num_of_guests > 1) ? "Guests" : "Guest"}</p>
                  </Col>
                </Row>

                <div>
                  <h2>About The Listing</h2>
                  <div className={!show ? "prop-desc" : ""}>{parsedText}</div>
                  <span onClick={() => setShow(!show)} className="read-more">
                    {show ? "read Less" : "read more..."}
                  </span>
                </div>

                <div>
                  <h2>Amenities</h2>
                  <Row className="amenities">
                    <Col lg={24} xs={24}>
                      <Row>
                        {home.amenities
                          ? home.amenities.map((x) => (
                              <Col lg={8} xs={8}>
                                <div className="amenities_display">
                                  <img
                                    src={AmenitiesIcon(x)}
                                    alt="AmenitiesIcon"
                                  />
                                  <Tooltip title={x}>
                                    <p className="amenities_name">{x}</p>
                                  </Tooltip>
                                </div>
                              </Col>
                            ))
                          : ""}
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div>
                  <h2>Facilities</h2>
                  <Row className="amenities">
                    <Col span={24}>
                      <Row>
                        {home.facilities
                          ? home.facilities.map((x) => (
                              <Col span={8}>
                                {x ? (
                                  <div className="amenities_display">
                                    <img
                                      src={AmenitiesIcon(x)}
                                      alt="Facilities"
                                    />
                                    <Tooltip title={x}>
                                      <p className="amenities_name">{x}</p>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            ))
                          : ""}
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className="location">
                  <h2>Other Details</h2>
                  <p>
                    Check in After <b></b>:
                    <span style={{ marginLeft: "5px" }}>
                      {home.check_in > 11
                        ? `${time[home.check_in]} pm`
                        : `${time[home.check_in]} am`}
                    </span>
                  </p>
                  <p>
                    Check Out Before :{" "}
                    <span style={{ marginLeft: "5px" }}>
                      {home.check_out > 11
                        ? `${time[home.check_out]} pm`
                        : `${time[home.check_out]} am`}{" "}
                    </span>
                  </p>
                </div>

                <h2>Rules & Policies</h2>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.smoking ? (
                      <img src={Smoking} alt="smoking" />
                    ) : (
                      <img src={NoSmoking} alt="smoking no" />
                    )}
                  </p>
                  <p>
                    {home.smoking ? "Smoking Allowed" : "Smoking Not Allowed"}
                  </p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.pets ? (
                      <img src={Pets} alt="pets" />
                    ) : (
                      <img src={NoPets} alt="no pets" />
                    )}
                  </p>
                  <p>{home.pets ? "Pets Allowed" : "Pets Not Allowed"}</p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.party ? (
                      <img src={Party} alt="party" />
                    ) : (
                      <img src={NoParty} alt="no party" />
                    )}
                  </p>
                  <p>
                    {home.party ? "Drinking Allowed" : "Drinking Not Allowed"}
                  </p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.children ? (
                      <img src={Infant} alt="infant" />
                    ) : (
                      <img src={NotInfant} alt="no infant" />
                    )}
                  </p>
                  <p>
                    {home.children
                      ? "Children Allowed"
                      : "Children Not Allowed"}
                  </p>
                </div>

                <div>
                  <h2>Additional Rules</h2>
                  <p>{home.additional_rules}</p>
                </div>

                <>
                  <h2>Location</h2>
                  <Map
                    className="map-wrapper"
                    google={props.google}
                    zoom={11}
                    center={{
                      lat: home.latitude,
                      lng: home.longitude,
                    }}
                    onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                  >
                    <Marker
                      position={{ lat: home.latitude, lng: home.longitude }}
                      name={"Current location"}
                      icon={Logo}
                    />
                  </Map>
                </>

                <div style={{ marginTop: "24rem" }}>
                  <h2>Customer Review</h2>

                  <div>
                    {reviews.reviews && reviews.reviews.length > 0
                      ? reviews.reviews.map((x) => (
                          <div className="card_space">
                            <Card className="card_body">
                              <div className="display_star_avatar">
                                <div className="Avatar">
                                  <div>
                                    <h4 className="avatar_name">
                                      {x.user_name}
                                    </h4>
                                    <p className="avatar_date">
                                      {moment(x.timestamp).format("LL")}
                                    </p>
                                  </div>
                                </div>

                                <div className="review_star">
                                  <Rate defaultValue={x.rating} />
                                </div>
                              </div>

                              <p className="body">{x.review_description}</p>
                            </Card>
                          </div>
                        ))
                      : "No reviews for this property! be the first one to write review"}
                  </div>
                </div>
              </Col>

              <Col xs={0} lg={8}>
                <Card className="stickys">
                  <div>
                    <div className="icon_heart">
                      <h4>
                        <span style={{ fontSize: "23px" }} className="rupee">
                          {" "}
                          ₹{" "}
                        </span>{" "}
                        {home.price_night} <b className="fonts"> /night</b>
                      </h4>
                      {favourites === "true" ? (
                        <div className="heart_icons">
                          <img
                            src={HeartFilled}
                            onClick={() => handleFavorite()}
                            alt="haert-filled"
                          />
                        </div>
                      ) : (
                        <div className="heart_icons">
                          <img
                            src={HeartBorder}
                            onClick={() => handleFavorite()}
                            alt="heart"
                          />
                        </div>
                      )}
                    </div>
                    <div className="rating_icons">
                      <p className="stars">
                        {reviews.review_rating ? reviews.review_rating : "NA"}
                      </p>
                      <p className="stars">
                        {" "}
                        <StarFilled className="icon_star" />
                      </p>

                      <p className="stars">
                        {" "}
                        (
                        {reviews.reviews
                          ? reviews.reviews.length
                          : reviews.review_count}{" "}
                        Reviews)
                      </p>
                    </div>
                    <p>Check-in - Check-Out</p>
                    <div className="date_picker">
                      <RangePicker
                        style={{ width: "100%" }}
                        className="date-picker input-field"
                        size="large"
                        disabledDate={disabledDate}
                        defaultValue={
                          check_in
                            ? [
                                moment(check_in, dateFormat),
                                moment(check_out, dateFormat),
                              ]
                            : ""
                        }
                        onChange={onChange}
                        format={dateFormat}
                      />
                    </div>
                    <div className="guest-dropdown">
                      <div className="adult-count">
                        {" "}
                        <p>Guests</p>
                        <div className="guests-count">
                          <span className="counnt">
                            <Button
                              disabled={guests.adults > 0 ? false : true}
                              shape="circle-outline"
                              icon={<MinusOutlined />}
                              size="small"
                              onClick={() => {
                                handleAdult(0, guests.adults);
                                setPrice();
                              }}
                            ></Button>
                          </span>
                          <span className="counnt">
                            <Button size="small" shape="circle-outline">
                              {guests.adults}
                            </Button>
                          </span>
                          <span className="counnt">
                            <Button
                              shape="circle-outline"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                handleAdult(1, guests.adults);
                                setPrice();
                              }}
                              size="small"
                            ></Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {price ? (
                    <div>
                      <div className="price">
                        <Row className="pricing_total">
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.per_night_average}x {price.days_to_booked}{" "}
                            nights
                          </p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.base_price}.00
                          </p>
                        </Row>

                        <Row className="pricing_total">
                          <p>Total Taxes</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.taxes_total}
                          </p>
                        </Row>
                        <Row className="pricing_total total-bg">
                          <p>Total</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.grand_total}
                          </p>
                        </Row>

                        {visible ? (
                          <Row
                            justify="space-around"
                            style={{ marginTop: "1rem" }}
                          >
                            <Input
                              size="large"
                              style={{ width: "200px" }}
                              placeholder="Coupon Code"
                              onChange={(e) => setCoupon(e.target.value)}
                            />

                            <Button
                              size="middle"
                              className="btn-primary"
                              style={{
                                width: "80px",
                                height: "40px",
                              }}
                              onClick={() => fetchPrice("val")}
                            >
                              Apply
                            </Button>
                          </Row>
                        ) : (
                          <Row justify="center" style={{ marginTop: "1rem" }}>
                            <div onClick={() => setVisible(true)}>
                              Have Promo Code ?
                            </div>
                          </Row>
                        )}
                      </div>
                      <div>
                        <div className="btn_search">
                          <Button
                            size="large"
                            className="btn-primary btn-large"
                            onClick={() => click()}
                            disabled={disable}
                          >
                            {home.instant_book ? "Book Now" : "Request to book"}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="btn_search">
                      <Button
                        size="large"
                        className="btn-primary btn-large"
                        onClick={() => fetchPrice("val")}
                      >
                        Check Availability
                      </Button>
                    </div>
                  )}

                  <div className="footer_card">
                    <p>
                      You won’t be charged yet. <br></br>Certain reservations
                      may also require a security deposit.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <h2>Similar Property</h2>
              <div className="selections-card-wrapper">
                {similarProprties.length > 0
                  ? similarProprties.slice(0, 3).map((x, i) => (
                      // <Link to={`/property-details/${x.listing_id}`}>
                      <Card
                        onClick={() => clickPropertySimilar(x.listing_id)}
                        key={i}
                        className="selection-card"
                        style={{
                          boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                        }}
                        cover={
                          <img
                            src={
                              x.featured_img[0].img_960[0]
                                ? x.featured_img[0].img_960[0].url
                                : ""
                            }
                            alt="selection"
                          />
                        }
                      >
                        <div className="name_rating">
                          <Tooltip title={x.property_title}>
                            <h3>{x.property_title}</h3>
                          </Tooltip>

                          <p>
                            {x.rating}
                            <StarFilled className="star_icon" />
                          </p>
                        </div>
                        <p className="place_name">
                          {x.state} , {x.country}
                        </p>
                        <Row style={{ marginBottom: "5px" }}>
                          {" "}
                          <Col span={8}>
                            {" "}
                            <div>
                              <img src={Place} alt="bed" height="17px" />
                            </div>
                            <p className="name_icon">{x.type_of_listing}</p>
                          </Col>
                          <Col span={8}>
                            <div>
                              <img
                                src={BedRooms}
                                alt="Bedrooms"
                                height="17px"
                              />
                            </div>
                            <p className="name_icon">
                              {x.num_of_beds} Bedrooms
                            </p>
                          </Col>
                          <Col span={8}>
                            <div>
                              <img src={BathTab} alt="bathTub" height="17px" />
                            </div>
                            <p className="name_icon">
                              {x.num_of_bath} Bathrooms
                            </p>
                          </Col>
                        </Row>
                        <div className="d-flex">
                          <h4>
                            {" "}
                            <span className="rupee"> ₹ </span> {x.price_night}{" "}
                          </h4>
                          <b className="fonts"> /night</b>
                        </div>
                      </Card>
                      // </Link>
                    ))
                  : ""}
              </div>
            </Row>
          </div>
          <div className="bottom-sticky">
            <div className="top">
              <div onClick={() => setOpen(!open)}>
                <h2>
                  <span style={{ fontSize: "23px" }} className="rupee">
                    {" "}
                    ₹{" "}
                  </span>{" "}
                  {home.price_night} <b className="fonts"> /night</b>
                </h2>
                <div className="rating_icons">
                  <p className="stars">
                    {reviews.review_rating ? reviews.review_rating : "NA"}
                  </p>
                  <p className="stars">
                    {" "}
                    <StarFilled className="icon_star" />
                  </p>

                  <p className="stars">
                    {" "}
                    (
                    {reviews.reviews
                      ? reviews.reviews.length
                      : reviews.review_count}{" "}
                    Reviews)
                  </p>
                </div>
              </div>
              <div>
                <div className="btn_search">
                  <Button
                    size="large"
                    className="btn-primary btn-large"
                    onClick={() => click()}
                    disabled={disable}
                  >
                    {home.instant_book ? "Book Now" : "Request to book"}
                  </Button>
                </div>
              </div>
            </div>
            {open ? (
              <Row>
                <Divider />

                <Col xs={24}>
                  <div>
                    <p>Check-in - Check-Out</p>
                    <div className="date_picker">
                      <RangePicker
                        className="date-picker input-field"
                        style={{ width: "100%" }}
                        size="large"
                        defaultValue={
                          check_in
                            ? [
                                moment(check_in, dateFormat),
                                moment(check_out, dateFormat),
                              ]
                            : ""
                        }
                        onChange={onChange}
                        format={dateFormat}
                      />
                    </div>
                    <div className="guest-dropdown">
                      <div className="adult-count">
                        {" "}
                        <p>Guests</p>
                        <div className="guests-count">
                          <span className="counnt">
                            <Button
                              disabled={guests.adults > 0 ? false : true}
                              shape="circle-outline"
                              icon={<MinusOutlined />}
                              size="small"
                              onClick={() => {
                                handleAdult(0, guests.adults);
                                setPrice();
                              }}
                            ></Button>
                          </span>
                          <span className="counnt">
                            <Button size="small" shape="circle-outline">
                              {guests.adults}
                            </Button>
                          </span>
                          <span className="counnt">
                            <Button
                              shape="circle-outline"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                handleAdult(1, guests.adults);
                                setPrice();
                              }}
                              size="small"
                            ></Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {price ? (
                    <div>
                      <div className="price">
                        <Row className="pricing_total">
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.per_night_average}x {price.days_to_booked}{" "}
                            nights
                          </p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.base_price}.00
                          </p>
                        </Row>

                        <Row className="pricing_total">
                          <p>Total Taxes</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.taxes_total}
                          </p>
                        </Row>
                        <Row className="pricing_total total-bg">
                          <p>Total</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.grand_total}
                          </p>
                        </Row>

                        {visible ? (
                          <Row
                            justify="space-around"
                            style={{ marginTop: "1rem" }}
                          >
                            <Input
                              size="large"
                              style={{ width: "200px" }}
                              placeholder="Coupon Code"
                              onChange={(e) => setCoupon(e.target.value)}
                            />

                            <Button
                              size="middle"
                              className="btn-primary"
                              style={{
                                width: "80px",
                                height: "40px",
                              }}
                              onClick={() => fetchPrice("val")}
                            >
                              Apply
                            </Button>
                          </Row>
                        ) : (
                          <Row justify="center" style={{ marginTop: "1rem" }}>
                            <div onClick={() => setVisible(true)}>
                              Have Promo Code ?
                            </div>
                          </Row>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="btn_search">
                      <Button
                        size="large"
                        style={{ width: "auto" }}
                        className="btn-primary"
                        onClick={() => fetchPrice("val")}
                      >
                        Check Availability
                      </Button>
                    </div>
                  )}

                  <div className="footer_card">
                    <p>
                      You won’t be charged yet. <br></br>Certain reservations
                      may also require a security deposit.
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}

const View = withRouter(PropertyView);

const LoadingContainer = (props) => (
  <div className="container">
    <Skeleton active />
    <Skeleton active />
  </div>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyAmNHGAndEeMbw0uJMQEaAnmTd7IeTvNl0",
  LoadingContainer: LoadingContainer,
})(View);

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];
