import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates';
import ReactGA from "react-ga4";
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom'

const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(value);
}

function BookingBox({ history, location, data, checkin, checkout, guests, disabledDates }) {
    const [edit, setEdit] = useState(false)
    const [disableBooking, setDisableBooking] = useState(true)
    const [price, setPrice] = useState(null)
    const [checkInOG, checkInOGSet] = useState(null)
    const [checkOutOG, checkOutOGSet] = useState(null)
    const [guestsOG, setGuestsOG] = useState(0)
    const [focusedInput, setFocusedInput] = useState(null);
    const [logError, setLogError] = useState(null);
    useEffect(() => {
        checkin !== null && checkin !== "" && checkInOGSet(checkin)
        checkout !== null && checkout !== "" && checkOutOGSet(checkout)
        guests && setGuestsOG(guests.adults)
    }, [checkin, checkout, guests])

    useEffect(() => {
        setLogError(null)
        if (checkInOG !== null && checkOutOG !== null) {
            fetchPrice()
        }
    }, [checkInOG, checkOutOG, guestsOG])


    const fetchPrice = async () => {
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
        }

        if (checkInOG !== null && checkOutOG !== null) {
            const postData = {
                properties: [
                    {
                        property_id: data.listing_id,
                        start_date: moment(checkInOG).format("YYYY-MM-DD"),
                        end_date: moment(checkOutOG).format("YYYY-MM-DD"),
                        coupon_code: "",
                        guests: guestsOG,
                        children: "",
                    },
                ],
            }


            try {
                const res = await Axios.post(
                    `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
                    postData,
                    config
                );
                const data = await res.data;
                setPrice(data.bifurcated[0])
                setDisableBooking(false)

            } catch (error) {
                if (error.response) {
                    setDisableBooking(true)
                    // setDisable(true)
                    // message.info(error.response.data.remarks);
                    setLogError(error.response.data.remarks)
                    console.log(error.response.data)
                }
            }

        }
    }

    const proceedBooking = () => {
        const format = "DD.MM.YYYY HH:mm";
        const yesterday = moment().subtract(1, "days").utcOffset(330).format(format)
        const last_check_in = moment().add(365, "days").utcOffset(330).format(format)

        if (!moment(checkInOG).isValid() || !moment(checkOutOG).isValid() || new Date(checkInOG) < moment(yesterday, format).toDate() || new Date(checkOutOG) < Date.now() || new Date(checkInOG) > moment(last_check_in, format).toDate() || new Date(checkOutOG) > moment(last_check_in, format).toDate()) {
            setLogError("Dates are not valid.")
            return
        }

        ReactGA.event({
            "category": "Trying_to_Book",
            "action": JSON.stringify({ "type": "booking_init", "payload": { "property_id": data.listing_id } }),
        })

        history.push(
            `/property-details/${data.listing_id}/${data.state}/${guestsOG}/0/${moment(checkInOG).format()}/${moment(checkOutOG).format()}/payments${queryString.parse(location.search).referal ? "?referal=" + queryString.parse(location.search).referal : ""}`
        );
    };



    return (
        <div className='bookingBox'>
            <div className='bookingBoxContainer'>
                <div className='priceBox'>
                    <div className='priceCal'>{price ? price.discount === 0 ? numberFormat(price.per_night_average) : (<><span style={{ fontWeight: 200, color: "grey", textDecoration: "line-through", textDecorationThickness: "0.14rem" }}>{numberFormat((price.discount_value + price.base_price) / price.days_to_booked)}</span>&nbsp;{numberFormat(price.per_night_average)}</>) : numberFormat(data.price_night ? data.price_night : 0)}/night
                        {/* <button onClick={() => {
                        setEdit(!edit)
                    }} className='editBtn'><EditOutlined /></button> */}
                    </div>
                    <div className='selectedDate'>{guestsOG} guests
                        {checkInOG !== null && checkOutOG !== null ?
                            <>&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;{moment(checkInOG).format("DD MMM YYYY")}&ndash;{moment(checkOutOG).format("DD MMM YYYY")}<br /><span onClick={() => { setEdit(!edit) }} className='setCol'>Change Dates</span></> : <>
                                &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;<span onClick={() => { setEdit(!edit) }} className='setCol'>Select Dates</span>
                            </>}</div>
                </div>
                <div className='bookBtn'>
                    <button onClick={() => {
                        proceedBooking()
                    }} disabled={disableBooking}>{data.instant_book ? "Book Now" : "Request to Book"}</button>
                </div>
            </div>
            <div style={{ display: edit ? "block" : "none" }} className='pricingDefinition'>
                {logError !== null && (
                    <div className='errorLog'>
                        {logError}
                    </div>
                )}
                <div className='datePickerDefinition'>
                    <DateRangePicker startDatePlaceholderText="Check-in"
                        endDatePlaceholderText="Check-out"
                        startDateId="startDate"
                        endDateId="endDate"
                        startDate={checkInOG && moment(checkInOG)}
                        minDate={moment().subtract(1, "days").format("MM/DD/YYYY")}
                        maxDate={moment().add(365, "days").format("MM/DD/YYYY")}
                        endDate={checkOutOG && moment(checkOutOG)}
                        onDatesChange={({ startDate, endDate }) => {
                            // setPrice();
                            // setCheckin(startDate)
                            // setCheckout(endDate)
                            checkInOGSet(startDate)
                            checkOutOGSet(endDate)
                        }}
                        isDayBlocked={current =>
                            disabledDates.some(date =>
                                current.isBetween(
                                    moment(date["start"]),
                                    moment(date["end"]),
                                    "day"
                                )
                            )}
                        numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                        displayFormat="YYYY-MM-DD"
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                        block />
                </div>
                <div style={{ marginBottom: focusedInput ? "300px" : "20px" }} className='guestPickerDefinition'>
                    <div className='guestDetails'>
                        Guests
                    </div>
                    <div className='guestDetailsAction'>
                        <button disabled={guestsOG > 1 ? false : true} onClick={() => {
                            setGuestsOG(prev => prev - 1)
                        }}><MinusOutlined /></button>
                        <div className='guestCount'>{guestsOG}</div>
                        <button onClick={() => {
                            setGuestsOG(prev => prev + 1)
                        }}><PlusOutlined /></button>
                    </div>
                </div>
                <div className='pricingBreakDown'>
                    {price && (
                        <div className='priceSegment'>
                            {price.discount !== 0 && (
                                <div className='divCal'>
                                    <div style={{color: "green"}} className='perNight'>
                                        Discount applied ({price.discount}%)
                                    </div>
                                    <div style={{color: "green"}} className='basePrice'>
                                    -{numberFormat(price.discount_value)}
                                    </div>
                                </div>
                            )}
                            <div className='divCal'>
                                <div className='perNight'>
                                    {numberFormat(price.per_night_average)}x {price.days_to_booked} nights
                                </div>
                                <div className='basePrice'>
                                    {numberFormat(price.base_price)}
                                </div>
                            </div>
                            <div className='taxCal'>
                                <div>Total Taxes</div>
                                <div>{numberFormat(price.taxes_total)}</div>
                            </div>
                            <div className='totalCal'>
                                <div className='total'>Total</div>
                                <div className='priceTotal'>{numberFormat(price.grand_total)}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withRouter(BookingBox)