import { ArrowLeftOutlined, BackwardOutlined, ShareAltOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { withRouter } from 'react-router-dom'
import { Drawer } from 'antd';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
} from "react-share";


function SliderElement({ data, history }) {

    const shareUrl = window.location.href;
    const [drawer, setDrawer] = useState(false)

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        dotsClass: 'dotsSlider'
    };

    return (
        <div className='sliderWrapper'>
            <div className='sliderBox'>
                <Slider {...settings}>
                    {data.imgs[0].img_960.map((item, idx) => (
                        
                            <div key={idx} className='sliderElement'>
                                <div className='imgSliderBox'>
                                    <img src={`https://d2sg0yxuzrccbw.cloudfront.net/${item.id}`} alt={item.alt} />
                                </div>
                            </div>
                        
                    ))}
                </Slider>
            </div>
            <div className='actionButtons'>
                <div className='backButton'>
                    <button
                        onClick={() => {
                            history.goBack()
                        }}
                    ><ArrowLeftOutlined /></button>
                </div>
                <div className='shareBtn'>
                    <button onClick={() => {
                        setDrawer(!drawer)
                    }}><ShareAltOutlined /></button>
                </div>
            </div>
            <Drawer
                title="Share"
                placement="bottom"
                closable={false}
                onClose={() => {
                    setDrawer(!drawer)
                }}
                visible={drawer}
                key="bottom"
            >
                <div className="share_com">
                    <EmailShareButton url={shareUrl}>
                        <EmailIcon size={48} round />
                    </EmailShareButton>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={48} round />
                    </WhatsappShareButton>
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={48} round />
                    </LinkedinShareButton>
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={48} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={48} round />
                    </TwitterShareButton>
                    <FacebookMessengerShareButton appId="315677186379398" url={shareUrl}>
                        <FacebookMessengerIcon size={48} round />
                    </FacebookMessengerShareButton>
                    <TelegramShareButton url={shareUrl}>
                        <TelegramIcon size={48} round />
                    </TelegramShareButton>
                </div>
            </Drawer>
        </div>
    )
}

export default withRouter(SliderElement)