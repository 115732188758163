import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Rate, message } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";

import RatingImg from "../../assets/images/host/rating.jpg";
import RatingSvg from "../../assets/images/host/rating_svg.svg";

// import Rating from "../../assets/images/host/thank_img.jpg";

function Rating(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { TextArea } = Input;

  const token = sessionStorage.getItem("token");

  const { property_id } = props.match.params;

  const [state, setstate] = useState({
    rating_value: 0,
    rating_location: 0,
    rating_cleanliness: 0,
    rating_ambiance: 0,
    review_description: "state dep",
  });

  const handlePost = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    const send = {
      ...state,
      property_id,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/reviews/add_review`,
        send,
        config
      );

      const data = await res.data;
   
      if (data.status === 200) {
        message.success("Thank you for your review! God Bless you..");
        props.history.push("/profile");
      }
    } catch (err) {
    
    }
  };

 

  return (
    <div className="container">
      <Row>
        <Col xs={24} lg={22} offset={1}>
          <div className="rating_overlay">
            <img src={RatingImg} alt="host" />
            <h1 className="header_text1">
              <p> Thankyou for staying with us.</p>
            </h1>
          </div>
        </Col>
      </Row>
      <Row className="review_stay" style={{ marginTop: "2rem" }}>
        <Col xs={24} lg={11} offset={1} className="review">
          <h2 className="review_head">Review your Stay at </h2>
          <h1 className="review_name">Casa Bonita</h1>
          <p>Gao, India</p>

          <Col lg={24}>
            <Row justify="center">
              <Col>
                <h2>Value</h2>
                <Rate
                  onChange={(e) => setstate({ ...state, rating_value: e })}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <h2>Location</h2>
                <Rate
                  onChange={(e) => setstate({ ...state, rating_location: e })}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <h2>Cleanliness</h2>
                <Rate
                  onChange={(e) =>
                    setstate({ ...state, rating_cleanliness: e })
                  }
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <h2>Ambiance</h2>
                <Rate
                  className="rate"
                  onChange={(e) => setstate({ ...state, rating_ambiance: e })}
                />
              </Col>
            </Row>
          </Col>
          <TextArea
            rows={9}
            style={{ marginTop: "2rem" }}
            placeholder="Write Review"
            onChange={(e) =>
              setstate({ ...state, review_description: e.target.value })
            }
          />

          <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
            <Button type="primary" danger onClick={handlePost}>
              Submit
            </Button>
          </div>
        </Col>
        <Col xs={24} lg={10} offset={1} className="col_right_svg">
          <h3 className="right_col">Your Feedback matters to us.</h3>
          <p>Feel free to write to us.</p>
          <img src={RatingSvg} alt="host" />
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Rating);
