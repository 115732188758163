import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { BookOutlined, HeartOutlined, UserOutlined } from "@ant-design/icons";

//import ProfileSvg from "../../assets/images/host/profileSvg.svg";

function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Row className="box_shadow">
        <Col xs={24} lg={24}>
          <div className="profile_header">
            <Link to="/profile">
              <p className="profile_dashboard">
                {" "}
                <UserOutlined className="icons_pro" />
                Profile
              </p>
            </Link>
            <Link to="/your_bookings">
              <p className="profile_dashboard">
                <BookOutlined className="icons_pro" />
                Bookings
              </p>
            </Link>
            <Link to="/profile/favourite">
              <p className="profile_dashboard">
                <HeartOutlined className="icons_pro" />
                Favourites
              </p>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Profile;
