import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  DatePicker,
  Button,
  Form,
  message,
  Skeleton,
  Select as Select1,
  Menu,
  Dropdown,
} from "antd";
import { Tooltip } from "antd";
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import Place from "../../assets/images/place.svg";
import image from "../../assets/images/hero-image.jpg";
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/HeartBorder.svg";
import {
  SearchOutlined,
  PlusOutlined,
  MinusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import listingData from "./Listing.json";
import axios from "axios";
import moment from "moment";

import { withRouter } from "react-router-dom";
import NoNetwork from "../Home/noNetwork";

import Filter from "./fliter";

function SearchProperty(props) {
  const { history } = props;

  const { Option } = Select1;
  const { RangePicker } = DatePicker;

  const [network, setNetwork] = useState(true);
  const [listing] = useState(listingData);
  const [listProperties, setListProperties] = useState([]);
  const [click, setClick] = useState(false);

  const [country] = useState("India");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(
    props.match.params.state && props.match.params.state !== "null"
      ? props.match.params.state
      : "null"
  );

  const [placeType, setPlaceType] = useState(
    props.match.params.place_type && props.match.params.place_type !== "empty"
      ? props.match.params.place_type
      : "empty"
  );

  const [stateOptions, setStateOptions] = useState([]);

  const [favourites] = useState();

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const date1 = addDays(Date.now(), 1);
  const date2 = addDays(Date.now(), 2);
  const [startDate, setStartDate] = useState(
    props.match.params.check_in ? props.match.params.check_in : date1
  );
  const [endDate, setEndDate] = useState(
    props.match.params.check_out ? props.match.params.check_out : date2
  );

  const [guests, setGuests] = useState({
    adults: props.match.params.adults ? parseInt(props.match.params.adults) : 0,
    children: props.match.params.children
      ? parseInt(props.match.params.children)
      : 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    getStates();
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProperties = async () => {
    setLoading(true);
    const guest_num = parseInt(guests.adults) + parseInt(guests.children);
    const dataTo = {
      country,
      state: state !== "null" ? state : "",
      no_bedrooms: "",
      guests: guests.adults !== 0 ? guest_num : "",
      children: guests.children > 0 ? true : "",
      amenities: [],
      price_range: {
        start_price: "",
        end_price: "",
      },
      property_type: placeType === "empty" ? [] : [placeType],
      room_type: [],
      pets: "",
      check_in: startDate,
      check_out: endDate,
      limit: 10,
      skip: 0,
    };
  
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/search`,
        dataTo,
        config
      );
      const data = await res;
      if (data.status === 200) {
        setListProperties(data.data);
      }
    } catch (err) {
     
      setNetwork(false);
    }
    setLoading(false);
  };

  const onFinish = async () => {
    const adults = guests.adults;
    const child = guests.children;

    const pType = placeType ? placeType : props.match.params.place_type;
    props.history.push(
      `/listings/${state}/${pType}/${adults}/${child}/${moment(
        startDate
      ).format()}/${moment(endDate).format()}`
    );
    fetchProperties();
  };

  const onFinishFailed = (errorInfo) => {
   
  };

  const getStates = async (id) => {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/private/misc/location/states/104de4f0-eb60-4464-b74e-2e25390cd703`,
        config
      );
      const data = await res.data;
      await setStateOptions(data);
    
    } catch (err) {
     
    }
    // setStateLoading(false);
  };

  function onChangeState(value) {
    setState(value);
  }

  function handleDate(date) {
    setStartDate(date[0]);
    setEndDate(date[1]);
  }

  const handleFavorite = async (id) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          token,
        },
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
          { property_id: id },
          config
        );

        const data = await res.data;
      
        if (data.status === 200) {
          message.success(data.remarks);
        }
        // fetchFavourites();
      } catch (error) {
        
      }
    } else {
      message.info("Please Login to add this property to Favourites");
    }
  };

  const dateFormat = "YYYY/MM/DD";

  const handleAdult = (e, val) => {
    if (e === 0) {
      setGuests({ ...guests, adults: val - 1 });
    }
    if (e === 1) {
      setGuests({ ...guests, adults: val + 1 });
    }
  };

  const getFilterData = async (FData) => {
   

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/search`,
        FData,
        config
      );
      const data = await res;
      if (data.status === 200) {
        setListProperties(data.data);
      }
      if (data.status === 400) {
        message.error("OOPS! location already exists ");
      }
    } catch (err) {
    
      message.error("OOPS! Something went wrong.");
    }
  };

  const handleRoute = (id) => {
    if (!click) {
  

      history.push(
        `/property-details/${id}/${
          props.match.params.state && props.match.params.state !== undefined
            ? props.match.params.state
            : "empty"
        }/${
          props.match.params.adults && props.match.params.adults !== undefined
            ? props.match.params.adults
            : "1"
        }/${
          props.match.params.children &&
          props.match.params.children !== undefined
            ? props.match.params.children
            : "0"
        }/${
          props.match.params.check_in &&
          props.match.params.check_in !== undefined
            ? props.match.params.check_in
            : moment(date1).format()
        }/${
          props.match.params.check_out &&
          props.match.params.check_out !== undefined
            ? props.match.params.check_out
            : moment(date2).format()
        }`
      );
    }
  };

  const sortPrices = (key) => {
    setLoading(true);
    if (key === 0) {
      let LowPrice = [...listProperties];
      LowPrice.sort(function (a, b) {
        return a.price_night - b.price_night;
      });
      setListProperties(LowPrice);
    } else {
      let HighPrice = [...listProperties];
      HighPrice.sort(function (a, b) {
        return b.price_night - a.price_night;
      });
      setListProperties(HighPrice);
    }
    setLoading(false);
  };

 
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => sortPrices(0)}>
        <p> Low to High </p>
      </Menu.Item>

      <Menu.Item key="1" onClick={() => sortPrices(1)}>
        <p> High to Low</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {network ? (
        <div
          className="container"
          style={{ position: "relative", zIndex: "0" }}
        >
          <Row>
            <Col xs={24} lg={24}>
              <div className="property_search">
                <img src={image} alt="selection" />
                <div className="text">{listing.place}</div>
                <div className="text1">{listing.title}</div>
                <div className="text2">{listing.sub_title}</div>
              </div>
            </Col>
          </Row>
          <div className="search_property_bar">
            {" "}
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={4} xs={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please input State!`,
                      },
                    ]}
                  >
                    <Select1
                      showSearch
                      suffix={<SearchOutlined />}
                      className="search input-field"
                      placeholder="Where to go State?"
                      onChange={onChangeState}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                      defaultValue={props.match.params.state}
                    >
                      {stateOptions.map((state) => {
                        return (
                          <Option key={state._id} value={state.state}>
                            {state.state}
                          </Option>
                        );
                      })}
                    </Select1>
                  </Form.Item>
                </Col>
                <Col lg={6} xs={6}>
                  <div>
                    <RangePicker
                      defaultValue={
                        props.match.params.check_in
                          ? [
                              moment(props.match.params.check_in, dateFormat),
                              moment(props.match.params.check_out, dateFormat),
                            ]
                          : ""
                      }
                      className="date-picker input-field"
                      size="large"
                      onChange={(value, dateString) => handleDate(dateString)}
                      format={dateFormat}
                    />
                  </div>
                </Col>
                <Col lg={4} xs={6}>
                  <div className="guest-dropdown-listings">
                    <div className="adult-count">
                      {" "}
                      <p>Guests</p>
                      <div className="guests-count">
                        <span className="counnt">
                          <Button
                            disabled={guests.adults > 0 ? false : true}
                            shape="circle-outline"
                            icon={<MinusOutlined />}
                            size="small"
                            onClick={() => handleAdult(0, guests.adults)}
                          ></Button>
                        </span>
                        <span className="counnt">
                          <Button size="small" shape="circle-outline">
                            {guests.adults}
                          </Button>
                        </span>
                        <span className="counnt">
                          <Button
                            shape="circle-outline"
                            icon={<PlusOutlined />}
                            onClick={() => handleAdult(1, guests.adults)}
                            size="small"
                          ></Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <Form.Item name="property_type">
                    <Select1
                      onChange={(e) => setPlaceType(e)}
                      size="large"
                      value={placeType}
                      placeholder="Property Type"
                    >
                      <Select1.Option value="Entire Place">
                        Entire Place
                      </Select1.Option>
                      <Select1.Option value="Shared">Shared</Select1.Option>
                      <Select1.Option value="Private Room">
                        Private Room
                      </Select1.Option>
                    </Select1>
                  </Form.Item>
                </Col>
                <Col lg={3} xs={3}>
                  <div className="hero-search">
                    <Button
                      size="large"
                      htmlType="submit"
                      className="btn-primary"
                    >
                      Search
                    </Button>
                  </div>
                </Col>
                <Col lg={3} xs={3}>
                  <Filter
                    getFilterData={getFilterData}
                    data={props.match.params}
                  />
                </Col>
              </Row>
            </Form>
          </div>
          {/* <Divider /> */}
          <Row
            style={{ marginTop: "2rem" }}
            justify="space-between"
            align="middle"
          >
            <div>
              <h1 style={{ margin: "0 auto" }}>100+ Home stays</h1>
            </div>
            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <p
                  style={{ cursor: "pointer" }}
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Sort By Prices <DownOutlined />
                </p>
              </Dropdown>
            </div>
          </Row>
          <div
            className="selections-card-wrapper"
            style={{ marginTop: "1rem", marginBottom: "6rem" }}
          >
            {loading ? (
              <div className="skeleton-row">
                <Skeleton className="skel" active />
                <Skeleton className="skel" active />
                <Skeleton className="skel" active />
              </div>
            ) : listProperties.length > 0 ? (
              listProperties.map((x, i) => (
                <Card
                  onClick={() => handleRoute(x.listing_id)}
                  key={i}
                  className="selection-card"
                  style={{
                    boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                  }}
                  cover={
                    <div className="fav_img">
                      <img
                        src={
                          x.featured_img[0].img_960[0]
                            ? x.featured_img[0].img_960[0].url
                            : ""
                        }
                        alt="selection"
                      />
                      {favourites === true ? (
                        <div className="heart_icon">
                          <img
                            src={HeartFilled}
                            alt="HeartBorder"
                            onMouseEnter={() => setClick(true)}
                            onMouseLeave={() => setClick(false)}
                            onClick={() => handleFavorite(x.listing_id)}
                          />
                        </div>
                      ) : (
                        <div className="heart_icon">
                          <img
                            src={HeartBorder}
                            alt="HeartBorder"
                            onMouseEnter={() => setClick(true)}
                            onMouseLeave={() => setClick(false)}
                            onClick={() => handleFavorite(x.listing_id)}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  <div className="name_rating">
                    <Tooltip title={x.property_title}>
                      <h3>{x.property_title}</h3>
                    </Tooltip>

                    {/* <p>
                      {x.rating}
                      <StarFilled className="star_icon" />
                    </p> */}
                  </div>
                  <p className="place_name">
                    <span>{x.state},</span>
                    {""}
                    <span> {x.country}</span>
                  </p>
                  <div>
                    <Row>
                      <Col span={8}>
                        {" "}
                        <div className="card_img_icon_svg">
                          <img src={Place} alt="bed" />
                        </div>
                        <p className="name_icon">{x.type_property}</p>
                      </Col>
                      <Col span={8}>
                        <div className="card_img_icon_svg">
                          <img src={BedRooms} alt="Bedrooms" />
                        </div>
                        <p className="name_icon">{x.num_of_bedroom} Bedrooms</p>
                      </Col>
                      <Col span={8}>
                        <div className="card_img_icon_svg">
                          <img src={BathTab} alt="bathTub" />
                        </div>
                        <p className="name_icon">{x.num_of_bath} Bathrooms</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex">
                    <h4>&#8377; {x.price_night}</h4>
                    <b className="fonts"> /night</b>
                  </div>
                  {/* </Link> */}
                </Card>
              ))
            ) : (
              <Col lg={24} className="empty-listings">
                <h1>No Properties found for this search!</h1>
              </Col>
            )}
          </div>
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}
export default withRouter(SearchProperty);
