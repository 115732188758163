import React from "react";

function Juridiction() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        All disputes relating to the Services or the Terms and Conditions
        mentioned above, will be subject to the jurisdiction of the Courts
        located at Delhi. All efforts must be made to secure a solution by means
        of arbitration, mediation, or conciliation before the Courts are
        approached.
      </p>
    </div>
  );
}

export default Juridiction;
