import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Skeleton,
  Carousel as Carousel1
} from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  SearchOutlined,
  PlusOutlined,
  MinusOutlined,
  LeftOutlined,
  RightOutlined,
  CompassOutlined
} from "@ant-design/icons";
//import Bed from "../../assets/images/bed.svg";
//import Place from "../../assets/images/place.svg";
import axios from "axios";
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "../../assets/styles/react-dates/react-dates-override.scss";
import { DateRangePicker } from 'react-dates';
import ReactGA from 'react-ga4';
import {throttle, debounce} from "throttle-debounce"
import "./index.scss";

function HeroSection({ data, history }) {
  const slider_ref = useRef(null);
  const [focusedInput, setFocusedInput] = useState(null);
  
  const defaultSearch = [
    {
        "type": "default",
        "name": "Uttarakhand",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Karnataka",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Himachal Pradesh",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Goa",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Ladakh",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Tamil Nadu",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Kerala",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Rajasthan",
        "secondary_name": "India",
    },
    {
        "type": "default",
        "name": "Sikkim",
        "secondary_name": "India",
    }]


  const searchBoxRef = useRef(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [searchBoxActive, setSearchBoxActive] = useState(false);

  const [form1, setForm1] = useState({
    "area": null,
    "secondary": null,
    "type":  null,
    "place_id": null,
    "location_type":  null,
    "check_in":  null,
    "check_out":  null,
    "guests":  1,
    "limit":  9,
    "skip": 0,
    "sort": 4
});

  const setSelectedArea = (item) => {
    if (item.type === "native") {
        history.push(`/desc/${item.url}`)
    } 
    
    if (item.type === "google" || item.type === "default") {
        setForm1(prev => ({...prev, area: item.name, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type}))
        setSearchText(`${item.name}, ${item.secondary_name}`) 
        form1.check_in === null && setFocusedInput("startDate")
    }

    if(item.type === "unavailable") {
        setForm1(prev => ({...prev, area: searchText, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type}))
        form1.check_in === null && setFocusedInput("startDate")
    }

    setSearchBoxActive(false)
    
}

const searchQuery = () => {


  if (searchText.length === 0) {
      searchBoxRef.current.focus()
      return
  }

  if (form1.check_in === null) {
       setFocusedInput("startDate")
       return
  }

  if (form1.check_out === null) {
      setFocusedInput("endDate")
      return
  }

  let searchQue = `/search?area=${form1.area}&secondary=${form1.secondary ? form1.secondary : null}&type=${form1.type ? form1.type : null}&place_id=${form1.place_id ? form1.place_id : null}&check_in=${form1.check_in ? form1.check_in : null}&check_out=${form1.check_out ? form1.check_out : null}&guests=${form1.guests ? form1.guests : 1}&limit=${form1.limit ? form1.limit : 9}&skip=${form1.skip ? form1.skip : 0}&sort=${form1.sort ? form1.sort : 1}`
  if (form1.location_type !== null && form1.location_type !== undefined) {
       form1.location_type.forEach(elem => {
          searchQue = searchQue.concat(`&location_type=${elem}`)
       })
  } 

  ReactGA.event({
    "category": "Home_Page_Search",
    "action": JSON.stringify({"type":"search", "payload": {"adults":form1.guests,"state":form1.area}}),
  })

  history.push(searchQue)
  
}

const fetchPredictions = (user_input) => {
  const config = {
      method: 'get',
      url: `https://api.roamhome.in/api/v1/public/misc/autocomplete?user_input=${user_input}&num_results=5`,
      headers: { }
    };
    
    axios(config)
    .then(function (response) {
      setPredictions(response.data.predictions);
    })
    .catch(function (error) {
      console.log(error);
    });
  
}


  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(1000, fetchPredictions),[])
  // eslint-disable-next-line
  const throttledSearch = useCallback(throttle(1000, fetchPredictions),[])

  useEffect(() => {
      searchText !== "" && searchText.length < 5 && throttledSearch(searchText)
      searchText !== "" && searchText.length > 5 && debouncedSearch(searchText)
  // eslint-disable-next-line
  },[searchText])


  return (
    <Row className="hero container">
      <Col className="hero-small-screen" xs={24} sm={0} lg={0}>
        <div className="small-screen-bg">
          <div className="hero-title-small-screen">
            <h1>Homes as unique as you.</h1>
          </div>
        </div>
      </Col>
      <Col className="hero-credentials" xs={24} sm={20} lg={9}>
          <div className="hero-title">
            <h2>Homes as unique as you.</h2>
          </div>
          <div className="hero-input-form">
          <div className="search_property_bar" style={{position: "relative", "zIndex":5}}>
                <Row style={{gap: "8px"}}>
                    <Col xs={24} lg={24}>
                        <div onClick={() => searchBoxRef.current && searchBoxRef.current.focus()} className={searchFocused ? "focused-searchbox searchbox" : "searchbox"}>
                            <div className="legend">Location</div>
                            <div className="searchox">
                                <input 
                                    autoFocus 
                                    ref={searchBoxRef} 
                                    onBlur={() => setSearchFocused(false)} 
                                    onFocus={() => setSearchFocused(true)} 
                                    className="location-search" 
                                    style={{"width":"100%"}} 
                                    placeholder="Where are you going?" 
                                    value={searchText} 
                                    onChange={(e) => setSearchText(e.target.value)}
                                    type="text"/>
                            </div>
                        </div>

                        {(searchFocused || searchBoxActive) && (
                            <div onMouseEnter={() => setSearchBoxActive(true)} onMouseLeave={() => setSearchBoxActive(false)} className="search-suggestions">
                                <div className="search-suggestions-header">
                                    PLACES IN INDIA
                                </div>
                                {searchText.length === 0 && (
                                    <>
                                    {defaultSearch.map((item, key) => (
                                        <div onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                            <Row style={{alignItems:"center"}}>
                                            <Col xs={4} lg={4}>
                                                <div className="prediction-ico"><SearchOutlined /></div>
                                            </Col>
                                            <Col xs={20} lg={20}>
                                                <div className="prediction-name">{item.name}</div>
                                                <div className="prediction-secondary">{item.secondary_name}</div>
                                            </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    </>
                                )}
                                {searchText.length !== 0 && predictions.length !== 0 && (
                                     <>
                                     {predictions.map((item, key) => (
                                         <div onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                             <Row style={{alignItems:"center"}}>
                                             <Col xs={4} lg={4}>
                                                 <div className="prediction-ico">
                                                     {item.type === "google" && (
                                                         item.location_type.indexOf("administrative_area_level_1") > -1 ? <SearchOutlined /> : <CompassOutlined />
                                                     )} 

                                                     {item.type === "native" && <img className="prediction-img-ico" alt="featured slt" src={item.featured_img} />}
                                                   
                                                     
                                                </div>
                                             </Col>
                                             <Col xs={20} lg={20}>
                                                 <div className="prediction-name">{item.name}</div>
                                                 <div className="prediction-secondary">{item.secondary_name}</div>
                                             </Col>
                                             </Row>
                                         </div>
                                     ))}
                                     </>
                                )}
                                {searchText.length !== 0 && predictions.length === 0 && (
                                    <>
                                        <div onClick={(e) => setSelectedArea({"type":"unavailable"})} className="default-predictions">
                                            <Row style={{alignItems:"center"}}>
                                            <Col xs={4} lg={4}>
                                                <div className="prediction-ico"><SearchOutlined /></div>
                                            </Col>
                                            <Col xs={20} lg={20}>
                                                <div className="prediction-name">{searchText}</div>
                                            </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}

                    </Col>
                    <Col xs={24} lg={24}>
                        <div className={focusedInput ? "focused-datebox datebox" : "datebox"}>
                        <Col xs={0} lg={0}>
                        <div className="legend" style={{opacity: "0"}}>Check-in - Check-Out</div>
                        </Col>
                            <DateRangePicker
                                    noBorder={true}
                                    startDatePlaceholderText="Check-in"
                                    endDatePlaceholderText="Check-out"
                                    startDateId="startDate1"
                                    endDateId="endDate1"
                                    startDate={form1.check_in && moment(form1.check_in)}
                                    minDate={moment().subtract(1, "days")} 
                                    maxDate={moment().add(365, "days")} 
                                    endDate={form1.check_out && moment(form1.check_out)}
                                    onDatesChange={({ startDate, endDate }) => { 
                                        setForm1(prev => ({...prev, check_in: moment(startDate).toISOString(), check_out: moment(endDate).toISOString()}))
                                    }}
                                    numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                                    displayFormat="DD/MM/YY"
                                    focusedInput={focusedInput}
                                    onFocusChange={(focusedInput) => { setFocusedInput(focusedInput)}}
                                    block
                                />
                        </div>
                    </Col>
                    <Col xs={24} lg={24}>
                    <div className="guests-count-e">
                        <div className="legend">Guests</div>
                        <div className="guests-btns">
                        <span className="counnt">
                            <Button
                            disabled={form1.guests > 0 ? false : true}
                            shape="circle-outline"
                            icon={<MinusOutlined />}
                            size="large"
                            onClick={() => setForm1(prev => ({...prev, guests: parseInt(prev.guests) - 1}))}
                            ></Button>
                        </span>
                        <span className="counnt">
                            <Button size="large" shape="circle-outline">
                            {form1.guests === "" ? 0 : form1.guests}
                            </Button>
                        </span>
                        <span className="counnt">
                            <Button
                            shape="circle-outline"
                            icon={<PlusOutlined />}
                            onClick={() => setForm1(prev => ({...prev, guests: parseInt(prev.guests) + 1}))}
                            size="large"
                            ></Button>
                        </span>
                        </div>
                    </div>
                    </Col>
                    <Col xs={24} lg={24}>
                        <div className="searchbox">
                        <Col xs={24} lg={24}>
                          	<Button onClick={searchQuery} className="search-btn" style={{"width": "100%"}} shape="round" icon={<SearchOutlined/>} size="large">Search</Button>
                        </Col>
                        </div>
                    </Col>
                    
                </Row>
            </div>
          </div>
       
      </Col>
      <Col className="hero-image" xs={24} sm={0} lg={15}>
        {!data ? <Skeleton active /> :
        <>
        <Button shape="circle" icon={<LeftOutlined className="ico-slider"/>} className="slider-btn" onClick={() => slider_ref.current.prev()}/> 
        <Carousel1 ref={slider_ref} dotPosition={'top'} dots={false} autoplay>


        <div class="overtone">
        {/* <Link to="/personalised/Workcation%20Goa"> */}
        <div class="img_overtone">
        <img src={"https://d2sg0yxuzrccbw.cloudfront.net/image-2tk7nfky51k187-original.jpg"} alt="Homestay in India - Roamhome" />
        </div>
        <div class="content_overtone">
          <div class="hd"><p>Workcation with Us</p></div>
           <div class="pd"><p>10% discount for 7+ nights<br/>20% discount for 15+ nights<br/>35% discount for 30+ nights</p></div>
          {/* <div class="vs">Checkout this home</div> */}
          
        
        </div>
        {/* {</Link>} */}
        </div>

        <div class="overtone">
        <Link to="/personalised/Pet%20Friendly">
        <div class="img_overtone">
        <img src={"https://d2sg0yxuzrccbw.cloudfront.net/image-2tk9yul3u28zrs-original.jpg"} alt="Homestay in India 2 - Roamhome" />
        </div>
        <div class="content_overtone">
          <div class="hd"><p>Travel with your<br/>furry friends</p></div>
           {/* <div class="pd"><p>10% discount for 7+ days,<br/>20% discount for 15+ days and 35% discount for 30+ days</p></div> */}
          {/* <div class="vs">Checkout Homes</div> */}
          
        
        </div>
        </Link>
        </div>
        

        <div class="overtone">
        <Link to="/personalised/Tropic">
        <div class="img_overtone">
        <img src={"https://d2sg0yxuzrccbw.cloudfront.net/image-2tk7dtlajcc5tc-original.jpg"} alt="Homestay in India 3 - Roamhome" />
        </div>
        <div class="content_overtone">
          <div class="hd"><p>Tropic like it's hot</p></div>
           {/* <div class="pd"><p>10% discount for 7+ days,<br/>20% discount for 15+ days and 35% discount for 30+ days</p></div> */}
          {/* <div class="vs">Checkout Homes</div> */}
          
        
        </div>
        </Link>
        </div>


        <div class="overtone">
        <Link to="/personalised/No%20Smog">
        <div class="img_overtone">
        <img src={"https://d2sg0yxuzrccbw.cloudfront.net/image-2tk7dtlajcln5e-original.jpg"} alt="Homestay in India 2 - Roamhome" />
        </div>
        <div class="content_overtone">
          <div class="hd"><p>Beat the Smog</p></div>
           {/* <div class="pd"><p>10% discount for 7+ days,<br/>20% discount for 15+ days and 35% discount for 30+ days</p></div> */}
          {/* <div class="vs">Checkout Homes</div> */}
          
        
        </div>
        </Link>
        </div>

       

        <div class="overtone">
        <Link to="/personalised/Nilgiri">
        <div class="img_overtone">
        <img src={"https://d2sg0yxuzrccbw.cloudfront.net/image-2tk7dtlajc0ol9-thumbnail.jpg"} alt="Villas in Coorg 3 - Roamhome" />
        </div>
        <div class="content_overtone">
          <div class="hd"><p>Experience the plantations</p></div>
        { /*<div class="pd"><p>Lorem Ipsum Dolor Sit. Lorem Ipsum Dolor Sit. Lorem Ipsum Dolor Sit. Lorem Ipsum Dolor Sit.</p></div>
          <div class="vs">Checkout this home</div>
          */
        }
        </div>
        </Link>
        </div>

        </Carousel1>
        <Button shape="circle" icon={<RightOutlined className="ico-slider"/>} className="slider-btn right-btn"  onClick={() => slider_ref.current.next()}/>
        </>
        
      }
      </Col>
    </Row>
  );
}

export default withRouter(HeroSection);
