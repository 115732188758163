import React from "react";
import { Row, Col } from "antd";
import Logo from "../../assets/images/white_logo.svg";
import { Button } from "antd";
import Arrow from "../../assets/images/right-arrow.svg";
import House from "../../assets/images/Pngfooter3.png";
import { Link } from "react-router-dom";
import { ScrollTo } from "react-scroll-to";

function Footer() {
  return (
    <div className="footer-wrapper">
      <img className="house-img" src={House} alt="Goa Villa Rentals - Roamhome" />
      <div className="scroll-top-footer">
        {" "}
        <ScrollTo>
          {({ scroll }) => (
            <Button
              className="btn_arrow"
              type="default"
              shape="circle"
              size="large"
              onClick={() => scroll({ y: 0, smooth: true })}
            >
              <img className="arrow" src={Arrow} alt="arrow" />
            </Button>
          )}
        </ScrollTo>
      </div>
      <Row className="footer-container">
        <h3>
          <i className="icon-arrow-down"></i>
        </h3>
        <Col className="col logo" xs={24} lg={3}>
          <img src={Logo} alt="Goa Villa Rentals - Roamhome" className="footer_logo" />
          <h2 className="text-light">Roamhome</h2>
        </Col>
        <Col className="col resources" xs={12} lg={4}>
          <ul className="sub_menu">
            <h3>Resources</h3>
            <Link to="/about-us">
              <li style={{ color: "white" }}>About us</li>
            </Link>
            <Link to="/contact-us">
              <li style={{ color: "white" }}>Contact us</li>
            </Link>
            <Link to="/search">
              <li style={{ color: "white" }}>Book your stay</li>
            </Link>
            <Link to="/host">
              <li style={{ color: "white" }}>Become a Host</li>
            </Link>
          </ul>
        </Col>
        <Col className="col company" xs={12} lg={5}>
          <ul className="sub_menu">
            <h3>Company</h3>
            <Link to="/terms">
              <li style={{ color: "white" }}>Terms of use</li>
            </Link>
            <Link to="/policies">
              <li style={{ color: "white" }}>Privacy Policies</li>
            </Link>
            <Link to="/culture">
              <li style={{ color: "white" }}>Culture</li>
            </Link>
            <Link to="/careers">
              <li style={{ color: "white" }}>Careers</li>
            </Link>
            <Link to="/faqs">
              <li style={{ color: "white" }}>FAQs</li>
            </Link>
          </ul>
        </Col>

        <Col className="col social" xs={24} lg={8}>
          { /* <div className="content">
            <h2>Subscribe To Our Newsletter.</h2>
            <div>
              <p className="text">
                Join our free Newsletter and get to know about
              </p>
              <p className="text">new properties and exciting offers.</p>
            </div>
            <div className="subscribe-form">
              <Input
                className="form-field"
                placeholder="Enter E-mail Address"
                size="large"
              />
              <Button
                shape="circle"
                type="default"
                className="btn_arrow_right"
                size="large"
              >
                <img className="arrow_right" src={Arrow} alt="arrow" />
              </Button>
            </div>
          </div> */ }
          <ul className="sub_menu">
            <h3>Follow Us</h3>
            <div className="social_icons">
              <a rel="noopener noreferrer" href="https://www.facebook.com/roamhomeswithus/" target="_blank">
                <li className="first-social">
                  <span className="fa fa-facebook"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://www.linkedin.com/company/roamhome/" target="_blank">
                <li>
                  <span className="fa fa-linkedin"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://www.instagram.com/roamhomeswithus/?hl=en" target="_blank">
                <li>
                  <span className="fa fa-instagram"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://twitter.com/roamhomeswithus" target="_blank">
                <li>
                  <span className="fa fa-twitter"></span>
                </li>
              </a>
            </div>
          </ul>
        </Col>
      </Row>
      <Row className="small-footer">
        <Col className="logo" xs={24} lg={24}>
          <img src={Logo} alt="Goa Villa Rentals - Roamhome" className="footer_logo" />
          <h2 className="text-light">Roamhome</h2>
        </Col>
        <Col xs={24} style={{ marginTop: "-36px" }}>
          <ul>
            <div className="social_icons">
              <a rel="noopener noreferrer" href="https://www.facebook.com/roamhomeswithus/" target="_blank">
                <li>
                  <span className="fa fa-facebook"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://www.linkedin.com/company/roamhome/" target="_blank">
                <li>
                  <span className="fa fa-linkedin"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://www.instagram.com/roamhomeswithus/?hl=en" target="_blank">
                <li>
                  <span className="fa fa-instagram"></span>
                </li>
              </a>
              <a rel="noopener noreferrer" href="https://twitter.com/roamhomeswithus" target="_blank">
                <li>
                  <span className="fa fa-twitter"></span>
                </li>
              </a>
            </div>
          </ul>
          <p>www.roamhome.in</p>
        </Col>
        <p>www.roamhome.in</p>
      </Row>
    </div>
  );
}
export default Footer;
