import React from 'react'
import Infant from "../../../assets/images/viewSvg/infant_friendly.svg";
import NotInfant from "../../../assets/images/viewSvg/not_infant_friendly.svg";
import Smoking from "../../../assets/images/viewSvg/Smoking.svg";
import NoSmoking from "../../../assets/images/viewSvg/no_smoking.svg";
import Pets from "../../../assets/images/viewSvg/Pets.svg";
import NoPets from "../../../assets/images/viewSvg/no_pets.svg";
import NoParty from "../../../assets/images/viewSvg/no_party.svg";
import Party from "../../../assets/images/viewSvg/Party.svg";

function Rules({ data }) {
    return (
        <div className='amenitiesWrapper'>
            <div className='amenitiesContentWrap'>
                <div className='amenitiesLegend'>
                    Rules and Policies
                </div>
                <div className='rulesList'>
                    <div className='ruleElement'>
                        {data.smoking ?
                            <>
                                <div className='imgRule'>
                                    <img src={Smoking} alt="smoking" />
                                </div>
                                <div>Smoking allowed</div>
                            </> :
                            <>
                                <div className='imgRule'>
                                    <img src={NoSmoking} alt="smoking no" />
                                </div>
                                <div>Smoking not allowed</div>
                            </>}
                    </div>
                    <div className='ruleElement'>
                        {data.pets ?
                            <>
                                <div className='imgRule'>
                                    <img src={Pets} alt="smoking" />
                                </div>
                                <div>Pets allowed</div>
                            </> :
                            <>
                                <div className='imgRule'>
                                    <img src={NoPets} alt="smoking no" />
                                </div>
                                <div>Pets not allowed</div>
                            </>}
                    </div>
                    <div className='ruleElement'>
                        {data.party ?
                            <>
                                <div className='imgRule'>
                                    <img src={Party} alt="smoking" />
                                </div>
                                <div>Party allowed</div>
                            </> :
                            <>
                                <div className='imgRule'>
                                    <img src={NoParty} alt="smoking no" />
                                </div>
                                <div>Party not allowed</div>
                            </>}
                    </div>
                    {/* <div className='ruleElement'>
                        {data.children ?
                            <>
                                <div className='imgRule'>
                                    <img src={Infant} alt="smoking" />
                                </div>
                                <div>Children allowed</div>
                            </> :
                            <>
                                <div className='imgRule'>
                                    <img src={NotInfant} alt="smoking no" />
                                </div>
                                <div>Children not allowed</div>
                            </>}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Rules