import React from "react";
import { Link } from "react-router-dom";
import Flip from "react-reveal/Flip";
import moment from "moment";

function PopularDestinations({ }) {
  const data = {
    "title": "Popular Destinations",
    "destinations": [
      {
        "destination_id": "Goa",
        "destination": "Goa",
        "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tko44l3zg4hx7-original.jpg",
        "url": "/abc/west"
      },
      {
       "destination_id": "Uttarakhand",
       "destination": "Uttarakhand",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tko44l3zidn7q-original.jpg",
       "url": "/abc/west"
     },
      {
       "destination_id": "Himachal Pradesh",
       "destination": "Himachal Pradesh",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tko44l3ziglfs-original.jpg",
       "url": "/abc/west"
     },
     {
       "destination_id": "Karnataka",
       "destination": "Karnataka",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tk893l30myh5s-original.jpg",
       "url": "/abc/west"
     },
     {
       "destination_id": "Sikkim",
       "destination": "Sikkim",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkdel3qxegz9-original.jpg",
       "url": "/abc/west"
     },
     {
       "destination_id": "Ladakh",
       "destination": "Ladakh",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkdel3qxs75f-original.jpg",
       "url": "/abc/west"
     },
    ]
  }

  const today = moment().toISOString()
  const tommorrow = moment().add(1, "days").toISOString()

  
  return (
    <div className="destination-wrapper container">
      <h1>{data.title}</h1>
      <div className="row-wrapper" style={{
        gridTemplateColumns: "repeat(6, 1fr)",
      
      }}>
        {data.destinations
          ? data.destinations.map((x) => (
              <div className="col-place">
                <div className="img-wrapper" style={{position: "relative"}}>
                {/* <Flip left delay={600}> */}
                    <Link to={`/search?area=${x.destination}&secondary=India&type=default&check_in=${today}&check_out=${tommorrow}`}>
                      <img
                        src={x.img}
                        alt={`Villas in ${x.destination}`}
                        style={{
                          width: "230px",
                          objectFit: "cover",
                          height: "300px",
                          borderRadius: "15px"
                        }}
                      />
                      <div className="imgCaption">{x.destination}</div>
                    </Link>
                  {/* </Flip> */}
                </div>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}

export default PopularDestinations;
