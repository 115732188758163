import React from "react";

function GeneralTerms() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        {" "}
        Roamhome Private Limited (hereafter referred to as “Roamhome”, “we”,
        “us”, or “our”) provides services on behalf of holiday homeowners
        (“Owners”) who rent their properties (including the associated fixtures
        and furnishings)(the “Home”) to guests (the “Guest” or “you”) seeking
        accommodations on a short term basis (the “Services”). We are the
        Owner’s property marketing manager and authorised agent, who will
        perform or assist the Services related to your reservation. By using the
        Home and Services, you agree to comply with and be legally bound by the
        terms and conditions of this guest agreement (the “Guest Agreement”).
        This Guest Agreement only applies to short term rentals and not long
        term rentals
      </p>
      <ul>
        <li>
          The primary guest must be at least 18 years of age to be able to check
          into the property.
        </li>
        <li>
          It is mandatory for guests to present valid photo identification at
          the time of check-in. According to government regulations, a valid
          Photo ID has to be carried by every person above the age of 18 staying
          at the property. The identification proofs accepted are Aadhar Card,
          Driving License, Voter ID Card, and Passport. Without Original copy of
          valid ID the guest will not be allowed to check-in.
        </li>
        <li>PAN card is not accepted as a valid ID card.</li>
        <li>
          Guests are expected to behave as they would in any home in which they
          live, with respect for property and neighbours.
        </li>
        <li>
          No music is allowed on the property beyond 10:00 pm as per the Noise
          Pollution (Regulations And Control) Rules, 2000, and guests are
          expected to maintain decorum as per the rules and regulations of
          residential areas.
        </li>
        <li>
          Please use common courtesy at the Home by keeping it clean, reporting
          any problems or damages, washing dishes as used, taking garbage to the
          outside bins when required, leaving towels in the bathrooms and sheets
          in the bedrooms, and not rearranging furniture.
        </li>
        <li>
          Extra mattress can be provided at certain properties subject to their
          availability and at an extra cost.
        </li>
        <li>
          If the guest happens to be travelling with a pet, then prior
          permission needs to be obtained from us. We have a right to refuse the
          entry of pets on any or all of our properties. In case a pet is given
          permission to enter the property, then the Guest shall assume full
          responsibility of ensuring that the pet does not damage, cause
          nuisance or breach the basic hygienic conditions of the property.
        </li>
      </ul>
    </div>
  );
}

export default GeneralTerms;
