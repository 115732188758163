import React, {useState, useEffect} from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import {Dialog} from "@material-ui/core"
import { useSnackbar } from 'notistack';
import moment from "moment";
import io from "socket.io-client";

function Index(props) {
    const payment_order_id = props.match.params.payment_order_id
    const [book, setbook] = useState(null);
    const [loadingOpen, setloadingOpen] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const [socket, setSocket] = useState();

    useEffect(() => {
        const sock = io("https://api.roamhome.in:5555/",  { transports: ['websocket', 'polling', 'flashsocket'] })
        setSocket(sock)
        
        return () => {
        
        sock.close()
        }
    },[])

    useEffect(() => {
        loadBookings()
        //eslint-disable-next-line
    }, [payment_order_id])
    const loadBookings = () => {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/public_payment/${payment_order_id.toLowerCase()}`,
                headers: { 
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json'
                }
            };
          
            axios(config)
            .then(function (response) {
                setbook(response.data);
            })
            .catch(function (error) {
              
            });

    }


    const handleBook = (item) => {
        setloadingOpen(true)
        var data = {"booking_id":item.booking_id}

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/repayment`,
            headers: { 
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
        .then(function (response) {
            const data = response.data
            setloadingOpen(false)
            if(data.status === 400) {
                    enqueueSnackbar(data.remarks);
            }
            
            if (data.status === 200) {        
                if (data.response) {
                  
                  handleRzp(
                    "",
                    data.response.order_id,
                    data.response.amount,
                    data.response.currency,
                    ""
                  );
                } else {
                    enqueueSnackbar(data.remarks);
                }
            } else {
                enqueueSnackbar(data.remarks);
            }
        })
        .catch(function (error) {
           
        });
    }



    const handleRzp = (name, order_id, amount, currency, email) => {
        
        var options = {
          key: "rzp_live_OPRLWprpiyvPTS",
          amount: amount,
          currency: currency,
          name: "Roamhome",
          description: "Homes as unique as you.",
          image: "",
          order_id: order_id,
          handler: async function (response) {
            setloadingOpen(true)
            const rzpdata = await response;
            const config = {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                //token: token,
              },
            };
            try {
              const res = await axios.post(
                `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/check_payment`,
                rzpdata,
                config
              );
              const resdata = await res.data;
            
              if (resdata.status === 200) {
                    setloadingOpen(false)
                    //openNotificationWithIcon("success", "Payment Successfull");
                    if(socket !== null) {
                        socket.emit("newNotification",{"type":"booking",name})
                    }
                        
                    enqueueSnackbar("Payment Successful");
                    loadBookings()
                   // props.history.push("/thank-you");
              }
              
            } catch (error) {
             
            }
          },
          prefill: {
            name: name,
            email: email,
          },
          theme: {
            color: "#F37254",
          },
        };
        setloadingOpen(false)
        var rzp1 = new window.Razorpay(options);
    
        rzp1.open();
    
       
    };

    const numberFormat = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0, 
            minimumFractionDigits: 0, 
        }).format(value);
    }
    

    return (
        <>
        <Dialog open={loadingOpen}>
            <div className="wrap" style={{textAlign:"center",width:"200px",padding:"20px"}}>
                <CircularProgress />
            </div>
        </Dialog>
        <div style={{"textAlign":"center"}}>
            {book !== null && (
                <>
                {book.booking_status === "waiting_payment" && (
                    <>
                    {moment().isBefore(moment(book.time_stamp).add(2,"days")) && moment().isBefore(moment(book.date_to)) && (
                        <>
                            <h1>You are making a payment for a Booking. </h1>
                            <p>Payment order id: {payment_order_id}</p>
                            <p>Payment amount: {numberFormat(book.multi_booking_total_amount)}</p>
                            <br/><br/>
                            <button onClick={() => handleBook(book)}>Make Payment</button>
                        </>
                    )}

                    {moment().isAfter(moment(book.time_stamp).add(2,"days")) && moment().isAfter(moment(book.date_to)) && (
                        <>
                            <br/><br/>
                            <h3>Booking link has expired.</h3>
                        </>
                    )}
                    
                    </>
                )}

                {book.booking_status !== "waiting_payment" && book.booking_status !== "confirmed" && (
                    <>  
                        <br/>
                        <br/>
                        <h3>Booking not available.</h3>
                    </>
                )}

                {book.booking_status === "confirmed" && (
                    <>  
                        <br/>
                        <br/>
                        <h3>Payment is Complete. Booking has been confirmed.</h3>
                    </>
                )}
                
                </>
            )}

            
            
        </div>
        </>
    )
}

export default Index
