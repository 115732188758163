import React, {Suspense, lazy, useEffect, useRef} from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import Home from "./components/Home";
import Listing from "./components/Listings";
import SearchNew from "./components/Search";
/*import HostWithUs from "./components/Host/HostWithUs"; 
import AboutUS from "./components/About";
import ContactUs from "./components/ContactUs"; */
import ThankYou from "./components/ThankYou";
import Rating from "./components/Rating";
import Payment from "./components/Payment";
import View from "./components/View";
import Profile from "./components/Profile";
import EditProfile from "./components/Profile/EditProfile";
import Booking from "./components/Booking";
import ResetPassword from "./components/Header_Footer/resetPassword";
import TermsCondition from "./components/Term&condition";
import MyFavourite from "./components/Profile/my_favourite";
/* import Culture from "./components/Culture"; 
import FAQS from "./components/FAQs"; 
import Privacy from "./components/PrivacyPolicy"; */
import ListingPets from "./components/PetListing";
import property from "./components/PropertyDetails";
import Verification from "./components/Verification";
import Map_ from "./components/PropertyDetails/Map";
import MakePayment from "./components/MakePayment";
import Test from "./components/Test";
import GoogleHotels from "./components/GoogleHotels";
import SearchCity from "./components/SearchCity";
import { Helmet } from "react-helmet";
const HostWithUs = lazy(() => import("./components/Host/HostWithUs"));
const Culture = lazy(() => import("./components/Culture"));
const Careers = lazy(() => import("./components/Careers"));
const FAQS = lazy(() => import("./components/FAQs"));
const Privacy = lazy(() => import("./components/PrivacyPolicy"));
const AboutUS = lazy(() => import("./components/About"));
const ContactUs = lazy(() => import("./components/ContactUs"));
const Covid = lazy(() => import("./components/COVID"));
const BookingNew = lazy(() => import("./components/BookingNew"));
const Search = lazy(() => import("./components/Search2"));
//const Search = lazy(() => import("./components/NewSearch"));
const Personalised = lazy(() => import("./components/Personalised"));
const BDNI = lazy(() => import("./components/Jobs/BDNI"));
const BDSI = lazy(() => import("./components/Jobs/BDSI"));
const BDWI = lazy(() => import("./components/Jobs/BDWI"));
const EEI = lazy(() => import("./components/Jobs/EEI"));
const EI = lazy(() => import("./components/Jobs/EI"));
const KAMSI = lazy(() => import("./components/Jobs/KAMSI"));
const KAMNI = lazy(() => import("./components/Jobs/KAMNI"));
const KAMWI = lazy(() => import("./components/Jobs/KAMWI"));
const FSE = lazy(() => import("./components/Jobs/FSE"));
const FeedBack = lazy(() => import("./components/Feedback"));
const EH = lazy(() => import("./components/Jobs/EH"));
const SBD = lazy(() => import("./components/Jobs/SBD"));

export default function Routes() {
  let location = useLocation()
  const windowProp = useRef(window)
  // React Google Analytics set up, 
  // set's page page by pathname
  
  useEffect(() => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);

    if(location.pathname.includes("/desc")) {
        if(windowProp.current.innerWidth <= 560) {
          setTimeout(() => {  
            if((Object.keys(windowProp.current["Tawk_API"]).length) !== 0) {
               windowProp.current["Tawk_API"].hideWidget() 
            }
          }, 2000);
        }
        
    } else {
      if(windowProp.current.innerWidth <= 560) {
        setTimeout(() => {  
          if((Object.keys(windowProp.current["Tawk_API"]).length) !== 0) {
            windowProp.current["Tawk_API"].showWidget() 
          }
        }, 2000);
      }
    }
  }, [location])
  return (
    <>
    <Helmet>
      <link rel="canonical" href={`https://www.roamhome.in${location.pathname + location.search}`} />
    </Helmet>
    <div>
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Uttarakhand/:Struct" component={SearchCity}/> 
        <Route exact path="/Goa/:Struct" component={SearchCity}/> 
        <Route exact path="/Ladakh/:Struct" component={SearchCity}/> 
        <Route exact path="/Sikkim/:Struct" component={SearchCity}/> 
        <Route exact path="/Karnataka/:Struct" component={SearchCity}/> 
        <Route
          exact
          path="/listings/:state/:place_type/:adults/:children/:check_in/:check_out"
          component={Listing}
        />
        {//<Route exact path="/search/:state/:place_type/:adults/:children/:check_in/:check_out" component={SearchNew} />
        }
        <Route exact path="/host" component={HostWithUs} />
        <Route exact path="/about-us" component={AboutUS} />
        <Route exact path="/covid" component={Covid} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/culture" component={Culture} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/test/:url_id" component={Test} />
        <Route exact path="/careers/business-development-north-india" component={BDNI} />
        <Route exact path="/careers/business-development-south-india" component={BDSI} />
        <Route exact path="/careers/business-development-west-india" component={BDWI} />
        <Route exact path="/careers/interns" component={EEI} />
        <Route exact path="/careers/engineering-intern" component={EI} />
        <Route exact path="/careers/key-accounts-manager-south-india" component={KAMSI} />
        <Route exact path="/careers/key-accounts-manager-north-india" component={KAMNI} />
        <Route exact path="/careers/key-accounts-manager-west-india" component={KAMWI} />
        {/*<Route exact path="/careers/key-accounts-manager-west-india" component={KAMWI} />*/
        }
        <Route exact path="/careers/full-stack-engineer-mern" component={FSE} />
        <Route exact path="/careers/sales-business-development-executive" component={SBD} />
        <Route exact path="/careers/executive-housekeeper" component={EH} />
        <Route exact path="/policies" component={Privacy} />
        <Route exact path="/map" component={Map_} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/rating/:property_id" component={Rating} />
        <Route exact path="/payment/:payment_order_id" component={MakePayment} />
        <Route
          exact
          path="/property-details/:property_id/:state/:adults/:children/:check_in/:check_out/payments"
          component={Payment}
        />
        <Route exact path="/listings" component={Listing} />
        {//<Route exact path="/search" component={SearchNew} />
        }
        {/*
        <Route exact path="/search" component={Search} />
        <Route exact path="/search/:page/:sort" component={Search} />
        <Route exact path="/search/:page/:sort/:state" component={Search} />
        <Route exact path="/search/:page/:sort/:state/:property_type/:guests/:children/:check_in/:check_out" component={Search} />
        <Route exact path="/search/:page/:sort/:state/:property_type/:guests/:children/:check_in/:check_out/:no_bedrooms/:amenities/:start_price/:end_price/:room_type/:pets" component={Search} />
        */}
        {//<Route exact path="/search/:state" component={SearchNew}/>
        }
        <Route exact path="/feedback/:feedback_id" component={FeedBack}/>
        <Route exact path="/verification/:verification" component={Verification} />
        <Route exact path="/property-details/:property_id/" component={View} />
        <Route exact path="/desc/:property_id/" component={property} />
        {/* <Route exact path="/desc/:property_id/" component={property} /> */}
        <Route
          exact
          path="/property-details/:property_id/:state/:adults/:children/:check_in/:check_out"
          component={View}
        />
        <Route
          exact
          path="/desc/:property_id/:state/:adults/:children/:check_in/:check_out"
          component={property}
        />
        <Route exact path="/hotel/google/list/:property_id/"
        component={GoogleHotels}/>
        <Route exact path="/bookings" component={Profile} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/edit" component={EditProfile} />
        <Route exact path="/profile/favourite" component={MyFavourite} />
        <Route exact path="/profile/booking" component={Booking} />
        <Route exact path="/your_bookings" component={BookingNew} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/listings/:state" component={Listing} />
        <Route exact path="/listings/personalised/:personalised" component={Listing} />
        <Route exact path="/search/personalised/:personalised" component={SearchNew} />
        <Route exact path="/personalised/:personalised" component={Personalised} />
        <Route
          exact
          path="/listings/personalised/:personalised"
          component={ListingPets}
        />

        <Route exact path="/terms" component={TermsCondition} />
        <Route exact path="/terms/payment" component={TermsCondition} />
        <Route exact path="/terms/cancel" component={TermsCondition} />
        <Route exact path="/terms/add" component={TermsCondition} />
        <Route exact path="/terms/early" component={TermsCondition} />
        <Route exact path="/terms/double" component={TermsCondition} />
        <Route exact path="/terms/indemnity" component={TermsCondition} />
        <Route exact path="/terms/juridiction" component={TermsCondition} />

        <Route exact path="/faqs" component={FAQS} />
        <Route exact path="/faqs/cancel_refund" component={FAQS} />
        <Route exact path="/faqs/payments" component={FAQS} />
        <Route exact path="/faqs/checkin_checkout" component={FAQS} />
        <Route exact path="/faqs/stay_reservation" component={FAQS} />
        <Route exact path="/faqs/local_experience" component={FAQS} />
        <Route exact path="/faqs/travel_covid" component={FAQS} />
        <Route exact path="/faqs/hosting" component={FAQS} />
      </Switch>
      </Suspense>
    </div>
    </>
  );
}
