import React, { useState, useEffect } from "react";
import { Row, Col, Card, Avatar, Button, message, Rate, Empty } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import ProfileHeader from "../Booking/booking_header";

function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { Meta } = Card;
  const token = sessionStorage.getItem("token");

  const [data, setData] = useState([]);

  const [ReviewInfo, setInfo] = useState([]);

  useEffect(() => {
    fetchReviews();
    fetchInfo();
    // eslint-disable-next-line
  }, []);

  const fetchReviews = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/reviews/user_specific`,
        config
      );
      const data = await res;
      if (data.status === 200) {
       
        setData(data.data);
      }
      if (data.status === 400) {
        message.error("OOPS! ninna dubakuru ");
      }
    } catch (err) {
    
      message.error("OOPS! Something went wrong.");
    }
  };

  const fetchInfo = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/info`,
        config
      );
      const data = await res;
      if (data.status === 200) {
      
        setInfo(data.data);
      }
      if (data.status === 400) {
        message.error("OOPS! reload please");
      }
    } catch (err) {
     
      message.error("OOPS! Something went wrong.");
    }
  };

 

  return (
    <div>
      <ProfileHeader />
      <div className="container">
        <Row>
          <Col xs={0} lg={11} className="profile_card">
            <Card style={{ width: 400 }} className="card_box">
              <Meta
                className="meta_icon"
                avatar={
                  <Avatar
                    src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    className="avatar_img"
                  />
                }
              />
              <h4 className="about_profile">Joined in 2020</h4>
              <div className="profile_edit_box">
                <Link to="/profile/edit">
                  <Button type="link" style={{ marginLeft: "-15px" }}>
                    Edit Profile
                  </Button>
                </Link>
              </div>
              {/*<h3 className="about_me">About Me</h3>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo
              </p>*/}
            </Card>
          </Col>
          <Col xs={24} lg={12} offset={1}>
            {" "}
            <h1 className="title_profile"> Hi {ReviewInfo.name ? ReviewInfo.name.split(" ")[0] : ""}, welcome to Roamhome</h1>
            <br/>
            <h3>Reviewed By Me</h3>
            {data.length > 0 ? (
              data.map((rev, i) => (
                <div className="card_two" key={i}>
                  <div className="profile_place_name_head">
                    <div className="profile_place_name">
                      <h3>Stayed in</h3>
                      <h3 className="profile_place_name_color">
                        Casa Bonita, Goa{" "}
                      </h3>
                    </div>
                    <p>01/01/2020-05/01/2020</p>
                  </div>
                  <Card style={{ width: " 100%" }} className="card_body">
                    <div className="display_star_avatar">
                      <div className="Avatar">
                        <p>
                          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        </p>
                        <div>
                          <h4 className="avatar_name">{rev.user_name}</h4>
                          <p className="avatar_date"> {rev.timestamp}</p>
                        </div>
                      </div>
                      <div>
                        <div className="review_star">
                          <Rate defaultValue={rev.rating} />
                        </div>
                      </div>
                    </div>

                    <p className="body">{rev.review_description}</p>
                  </Card>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <Empty />
                <p style={{ marginTop: "1rem" }}>
                  Go to Bookings and feel free to write reviews.{" "}
                </p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Profile;
