import React from "react";
import Slider from "react-slick";
import { Row, Col, Rate } from "antd";
// import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
/*import feedbackOne from "../../assets/images/hero-image.jpg"; */
import User from "../../../assets/images/user.jpg";
import Ann from "../../../assets/images/,,.svg";
import moment from "moment";

const settings = {
    className: "center",
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
};

function Testimonials({ data }) {

    return (
        <div className='wrapPopularDestinations'>

            <div className='titleMobileWrap'>
                <div className='captionWrap'>
                    Testimonials
                </div>
            </div>
            <div className="sliderCamp">
                <Slider {...settings}>
                    {data.section_6.testimonials.map((item, idx) => (
                        <div className="slideTestimonial" key={idx}>
                            <div className="invertedComma">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" />
                                    </g>
                                </svg>
                            </div>
                            <div className="testimonyDescription">
                                {item.description}
                            </div>
                            <div className="rating">
                                <Rate
                                    disabled
                                    defaultValue={item.rating}
                                    style={{ color: "red" }}
                                />
                            </div>
                            <div className="userTestimonial">
                                <div className="userImg">
                                    <img src={item.user_img ? item.user_img : User} alt="Testimonial user" />
                                </div>
                                <div className="userInfo">
                                    {item.name}, {item.location}<br/>
                                    {item.property_name}<br/>
                                    {moment(item.timestamp).format("ll")}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Testimonials