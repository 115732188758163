import { ClockCircleOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import React, { useState } from 'react'
import Home from '../../Home'
import AmenitiesIcon from "../amenitiesIcon";

const time = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
];

function AmenitiesAndMore({ data }) {
    const [amenitiesBox, setAmenitiesBox] = useState(false);

    return (
        <div className='amenitiesWrapper'>
            <div className='amenitiesContentWrap'>
                <div className='amenitiesLegend'>
                    Amenities we offer you
                </div>
                <div className='amenitiesList'>
                    {data.amenities.slice(0, 6).map((item, idx) => (
                        <div key={idx} className='amenitiesContent'>
                            <div className='amenitiesIco'>
                                <img src={AmenitiesIcon(item)} alt={item} />
                            </div>
                            <div className='amenitiesName'>
                                {item}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='amenitiesShowAll'>
                    <button onClick={() => {
                        setAmenitiesBox(true)
                    }}>Show all amenities</button>
                </div>
            </div>
            <Drawer title={"Amenities"} visible={amenitiesBox} onClose={() => { setAmenitiesBox(false) }} width={"100%"}>
                <div className='listAllAmenities'>
                    <div className='amenitiesList'>
                        {data.amenities.map((item, idx) => (
                            <div key={idx} className='amenitiesContent'>
                                <div className='amenitiesIco'>
                                    <img src={AmenitiesIcon(item)} alt={item} />
                                </div>
                                <div className='amenitiesName'>
                                    {item}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>

            <div className='otherDetailsWrapper'>
                <div className='otherDetailsContentWrap'>
                    <div className='otherDetailsLegend'>
                        Other Details
                    </div>
                    <div className='timingInfo'>
                        <div className='timingStick'>
                            <ClockCircleOutlined /> Check in : {data.check_in > 11 ? `${time[data.check_in]} pm` : `${time[data.check_in]} am`}
                        </div>
                        <div className='timingStick'>
                        <ClockCircleOutlined /> Checkout : {data.check_out > 11 ? `${time[data.check_out]} pm` : `${time[data.check_out]} am`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AmenitiesAndMore