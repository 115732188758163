import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  DatePicker,
  Button,
  Form,
  message,
  Skeleton,
  Select as Select1,
  Menu,
  Dropdown,
  Divider,
  AutoComplete,
} from "antd";
import { Tooltip, Pagination } from "antd";
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import Place from "../../assets/images/place.svg";
/*import image from "../../assets/images/hero-image.jpg";*/
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/HeartBorder.svg";
import {
  SearchOutlined,
  PlusOutlined,
  MinusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { sessionSort } from "../Utils/session";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import NoNetwork from "../Home/noNetwork";

import Goa from "../../assets/images/banners/goa.jpg";
import Delhi from "../../assets/images/banners/delhi.jpg";
import HP from "../../assets/images/banners/hp.jpg";
import Kerala from "../../assets/images/banners/kerala.jpg";
import Mh from "../../assets/images/banners/mh.jpg";
import Filter from "./fliter";

function SearchProperty(props) {
  const { history } = props;

  const { Option } = Select1;
  const { RangePicker } = DatePicker;

  const [network, setNetwork] = useState(true);
  const [listProperties, setListProperties] = useState([]);
  //const [result, setResult] = useState(1);
  const [count, setCount] = useState(0);
  const [click, setClick] = useState(false);

  const [property, setProperty] = useState([]);

  const [PID, setPID] = useState("");

  const sortVal = sessionStorage.getItem("sort");

  const [country] = useState("India");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(
    props.match.params.state && props.match.params.state !== "null"
      ? props.match.params.state
      : "null"
  );

  const [placeType, setPlaceType] = useState(
    props.match.params.place_type && props.match.params.place_type !== "empty"
      ? props.match.params.place_type
      : "empty"
  );

  const [stateOptions, setStateOptions] = useState([]);

  const [favourites] = useState();

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const date1 = addDays(Date.now(), 1);
  const date2 = addDays(Date.now(), 2);
  const [startDate, setStartDate] = useState(
    props.match.params.check_in ? props.match.params.check_in : date1
  );
  const [endDate, setEndDate] = useState(
    props.match.params.check_out ? props.match.params.check_out : date2
  );

  const [guests, setGuests] = useState({
    adults: props.match.params.adults ? parseInt(props.match.params.adults) : 0,
    children: props.match.params.children
      ? parseInt(props.match.params.children)
      : 0,
  });

  const personalised = props.match.params.personalised ? props.match.params.personalised : "empty"
  

  const [pagination, setPagination] = useState({ minValue: 0, maxValue: 9 });

  useEffect(() => {
    window.scrollTo(0, 0);
    getStates();
    if (personalised === "empty") {
      fetchProperties();
    } else {
      fetchPersonalised();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProperties = async (val) => {
    setLoading(true);
    const guest_num = parseInt(guests.adults) + parseInt(guests.children);
    const dataTo = {
      country,
      state: state !== "null" ? state : "",
      no_bedrooms: "",
      guests: guests.adults !== 0 ? guest_num : "",
      children: guests.children > 0 ? true : "",
      amenities: [],
      price_range: {
        start_price: "",
        end_price: "",
      },
      property_type: placeType === "empty" ? [] : placeType,
      room_type: [],
      pets: "",
      check_in: startDate,
      check_out: endDate,
      limit: 9,
      skip: 0,
      sort: { price_night: val ? val : sortVal ? sortVal : "" },
    };
   
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/search`,
        dataTo,
        config
      );
      const data = await res;
      if (data.status === 200) {
      
        if (data.data.result) {
          setListProperties(data.data.result);
        }
        setCount(data.data.results_count);
      }
    } catch (err) {
      
      setNetwork(false);
    }
    setLoading(false);
  };

  const onFinish = async () => {
    const adults = guests.adults;
    const child = guests.children;

    const pType = placeType ? placeType : props.match.params.place_type;
    props.history.push(
      `/listings/${state}/${pType}/${adults}/${child}/${moment(
        startDate
      ).format()}/${moment(endDate).format()}`
    );
    fetchProperties();
    setPagination({
      minValue: 0,
      maxValue: 9,
    });
  };

  const onFinishFailed = (errorInfo) => {
   
  };

  const getStates = async (id) => {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/private/misc/location/states/104de4f0-eb60-4464-b74e-2e25390cd703`,
        config
      );
      const data = await res.data;
      await setStateOptions(data);
    
    } catch (err) {
   
    }
    // setStateLoading(false);
  };

  const fetchPersonalised = async () => {
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/label/properties/${personalised}`,
        config
      );
      const data = await res.data;
      await setListProperties(data);
     
    } catch (err) {
     
    }
    setLoading(false);
  };

  function onChangeState(value) {
    setState(value);
    //setResult(1);
  }

  function handleDate(date) {
    setStartDate(date[0]);
    setEndDate(date[1]);
  }

  const handleFavorite = async (id) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          token,
        },
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
          { property_id: id },
          config
        );

        const data = await res.data;
      
        if (data.status === 200) {
          message.success(data.remarks);
        }
        // fetchFavourites();
      } catch (error) {
       
      }
    } else {
      message.info("Please Login to add this property to Favourites");
    }
  };

  const dateFormat = "YYYY/MM/DD";

  const handleAdult = (e, val) => {
    if (e === 0) {
      setGuests({ ...guests, adults: val - 1 });
    }
    if (e === 1) {
      setGuests({ ...guests, adults: val + 1 });
    }
  };

  const getFilterData = async (FData) => {
  
  };

  const handleRoute = (id) => {
    if (!click) {

      history.push(
        `/property-details/${id}/${
          props.match.params.state && props.match.params.state !== undefined
            ? props.match.params.state
            : "empty"
        }/${
          props.match.params.adults && props.match.params.adults !== undefined
            ? props.match.params.adults
            : "1"
        }/${
          props.match.params.children &&
          props.match.params.children !== undefined
            ? props.match.params.children
            : "0"
        }/${
          props.match.params.check_in &&
          props.match.params.check_in !== undefined
            ? props.match.params.check_in
            : moment(date1).format()
        }/${
          props.match.params.check_out &&
          props.match.params.check_out !== undefined
            ? props.match.params.check_out
            : moment(date2).format()
        }`
      );
    }
  };

  const handleChangePagination = (value) => {
   
    handleNext(value - 1);
    window.scrollTo(0, 0);
    if (value <= 1) {
      setPagination({
        minValue: 0,
        maxValue: 9,
      });
    } else {
      setPagination({
        minValue: pagination.maxValue,
        maxValue: value * 9,
      });
    }
  };

  const handleNext = async (val) => {
    window.scrollTo(0, 0);

    setLoading(true);
    const guest_num = parseInt(guests.adults) + parseInt(guests.children);
    const dataTo = {
      country,
      state: state !== "null" ? state : "",
      no_bedrooms: "",
      guests: guests.adults !== 0 ? guest_num : "",
      children: guests.children > 0 ? true : "",
      amenities: [],
      price_range: {
        start_price: "",
        end_price: "",
      },
      property_type: placeType === "empty" ? [] : placeType,
      room_type: [],
      pets: "",
      check_in: startDate,
      check_out: endDate,
      limit: 9,
      skip: val * 9 <= count ? val * 9 : count,
      sort: { price_night: sortVal },
    };
 
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/misc/search`,
        dataTo,
        config
      );
      const data = await res;
      if (data.status === 200) {
        let copy = listProperties;
        let resData = data.data.result;
        if (resData) {
          copy.push(...resData);
          setListProperties(copy);
        } else {
          setListProperties([]);
        }
      }
    } catch (err) {
     
      setNetwork(false);
    }
    setLoading(false);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          sessionSort(1);
          fetchProperties(1);
          window.location.reload();
        }}
      >
        <p> Low to High </p>
      </Menu.Item>

      <Menu.Item
        key="1"
        onClick={() => {
          sessionSort(-1);
          fetchProperties(-1);
          window.location.reload();
        }}
      >
        <p> High to Low</p>
      </Menu.Item>
    </Menu>
  );

  const getImg = () => {
    let img = "";
    if (props.match.params.state === "Goa") {
      img = Goa;
    } else if (props.match.params.state === "Uttarakhand") {
      img = "https://d2sg0yxuzrccbw.cloudfront.net/Uttarakhand+Banner.jpg";
    } else if (props.match.params.state === "Kerala") {
      img = Kerala;
    } else if (props.match.params.state === "Maharashtra") {
      img = Mh;
    } else if (props.match.params.state === "Delhi") {
      img = Delhi;
    } else if (props.match.params.state === "Rajasthan") {
      img = "https://d2sg0yxuzrccbw.cloudfront.net/Rajasthan_Banner.jpg";
    } else if (props.match.params.state === "Himachal Pradesh") {
      img = HP;
    } else {
      img = Delhi;
    }

    return img;
  };

  const getTitle = () => {
    let title = "";
    if (props.match.params.state === "Goa") {
      title =
        "While the rest of India waits for an occasion to celebrate, every day is a celebration of the good life and the little things in the sunshine state!";
    } else if (props.match.params.state === "Uttarakhand") {
      title = "The hills are calling and you must go!";
    } else if (props.match.params.state === "Kerala") {
      title =
        "Verdant greenery and heavenly landscapes, they don’t call Kerala ‘God’s own Country’ for no reason!";
    } else if (props.match.params.state === "Maharashtra") {
      title =
        "A melting pot of culture, heritage and cuisines, Maharashtra’s hospitality ethos will fascinate you!";
    } else if (props.match.params.state === "Delhi") {
      title =
        "With its swanky malls, majestic forts and sky high structures, Delhi welcomes both business & leisure travelers with open arms!";
    } else if (props.match.params.state === "Rajasthan") {
      title =
        "Steeped in history & grandeur, even the colors that don’t match on a typical color palette seem to be in perfect harmony in Rajasthan!";
    } else if (props.match.params.state === "Himachal Pradesh") {
      title =
        "Away from the din and clutter, find your soul in the land of the snow!";
    } else {
      title =
        "With its swanky malls, majestic forts and sky high structures, Delhi welcomes both business & leisure travelers with open arms!";
    }

    return title;
  };

  const getPropType = (val) => {
    let str = val.split("/");

    return str[0];
  };

  const onChangeProperty = async (name) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/search/${name}`
      );
      const data = await res.data;
      setProperty(data);
    } catch (err) {
  
    }

    const getId = property.filter((x) => x.property_title === name);
    const id = getId.length > 0 ? getId[0].listing_id : "";
    setPID(id);
    // setForm({ property_id: id, property_name: name });
  };

  return (
    <div>
      <Helmet>
        <title>
          {props.match.params.state
            ? `Search Results in ${
                props.match.params.state !== "null"
                  ? props.match.params.state
                  : "India"
              } - Roamhome Vacation Rentals`
            : " Roamhome Vacation Rentals"}
        </title>
        <meta name="description" content="Roamhome villa rentals" />
      </Helmet>
      {network ? (
        <div
          className="container"
          style={{ position: "relative", zIndex: "0" }}
        >
          {props.match.params.state && props.match.params.state !== "null" ? (
            <Row>
              <Col xs={24} lg={24}>
                <div className="property_search">
                  <img src={getImg()} alt="selection" />
                  <div className="text">
                    {props.match.params.state &&
                    props.match.params.state !== "empty"
                      ? props.match.params.state
                      : "India"}
                    {/* {props.match.params.personalised
                    ? props.match.params.personalised
                    : ""} */}
                  </div>
                  <div className="text1">{getTitle()}</div>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <div className="search_property_bar">
            {" "}
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 30 }}>
                <Col lg={4} xs={24} style={{ padding: "8px" }}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: `Please input State!`,
                      },
                    ]}
                  >
                    <Select1
                      showSearch
                      suffix={<SearchOutlined />}
                      className="search input-field"
                      placeholder="Where to go?"
                      onChange={onChangeState}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                      defaultValue={
                        props.match.params.state !== "null"
                          ? props.match.params.state
                          : "Select State"
                      }
                    >
                      {stateOptions.map((state, i) => {
                        return (
                          <Option key={i} value={state.state}>
                            {state.state}
                          </Option>
                        );
                      })}
                    </Select1>
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24} style={{ padding: "8px" }}>
                  <div>
                    <RangePicker
                      style={{ width: "100%" }}
                      defaultValue={
                        props.match.params.check_in
                          ? [
                              moment(props.match.params.check_in, dateFormat),
                              moment(props.match.params.check_out, dateFormat),
                            ]
                          : ""
                      }
                      className="date-picker input-field"
                      size="large"
                      onChange={(value, dateString) => handleDate(dateString)}
                      format={dateFormat}
                    />
                  </div>
                </Col>
                <Col lg={4} xs={24} style={{ padding: "8px" }}>
                  <div className="guest-dropdown-listings">
                    <div className="adult-count">
                      {" "}
                      <p>Guests</p>
                      <div className="guests-count">
                        <span className="counnt">
                          <Button
                            disabled={guests.adults > 0 ? false : true}
                            shape="circle-outline"
                            icon={<MinusOutlined />}
                            size="small"
                            onClick={() => handleAdult(0, guests.adults)}
                          ></Button>
                        </span>
                        <span className="counnt">
                          <Button size="small" shape="circle-outline">
                            {guests.adults}
                          </Button>
                        </span>
                        <span className="counnt">
                          <Button
                            shape="circle-outline"
                            icon={<PlusOutlined />}
                            onClick={() => handleAdult(1, guests.adults)}
                            size="small"
                          ></Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={0} style={{ padding: "8px" }}>
                  <Form.Item name="property_type">
                    <Select1
                      onChange={(e) => setPlaceType(e)}
                      size="large"
                      value={placeType}
                      mode={"multiple"}
                      placeholder="Property Type"
                    >
                      <Select1.Option value="Entire Place">
                        Entire Place
                      </Select1.Option>
                      <Select1.Option value="Shared">Shared</Select1.Option>
                      <Select1.Option value="Private Room">
                        Private Room
                      </Select1.Option>
                    </Select1>
                  </Form.Item>
                </Col>
                <Col lg={3} xs={24} style={{ padding: "8px" }}>
                  <div className="hero-search">
                    <Button
                      size="large"
                      htmlType="submit"
                      className="btn-primary"
                    >
                      Search
                    </Button>
                  </div>
                </Col>
                <Col lg={2} xs={3} style={{ padding: "8px" }}>
                  <Filter
                    getFilterData={getFilterData}
                    data={props.match.params}
                  />
                </Col>
              </Row>
            </Form>
            <Row justify="center">
              <Col lg={12} xs={24}>
                <Divider orientation="center">OR</Divider>
              </Col>
            </Row>
            <Row>
              <Col lg={8} xs={17}>
                <AutoComplete
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Search for a Property"
                  onChange={onChangeProperty}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                >
                  {property.map((x, i) => {
                    return (
                      <Option key={i} value={x.property_title}>
                        {x.property_title}
                      </Option>
                    );
                  })}
                </AutoComplete>
              </Col>
              <Col lg={4} xs={6} style={{ marginLeft: "1rem" }}>
                <div className="hero-search">
                  <Button
                    size="large"
                    // disabled={PID ? false : true}
                    onClick={() => {
                      PID
                        ? props.history.push(`/property-details/${PID}`)
                        : message.info("Type Property name! ");
                    }}
                    className="btn-primary"
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Row
            style={{ marginTop: "2rem" }}
            justify="space-between"
            align="middle"
          >
            <h1 style={{ marginBottom: 0 }}>
              {count ? count : 100}+ Home stays
            </h1>
            <Dropdown overlay={menu} trigger={["click"]}>
              <p
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {sortVal === "1" ? (
                  <span style={{ fontWeight: "bold" }}> Low to High</span>
                ) : sortVal === "-1" ? (
                  <span style={{ fontWeight: "bold" }}> High to Low</span>
                ) : (
                  "Sort By Prices"
                )}{" "}
                <DownOutlined />
              </p>
            </Dropdown>
          </Row>
          <div
            className="selections-card-wrapper"
            style={{ marginTop: "1rem", marginBottom: "6rem" }}
          >
            {loading ? (
              <div className="skeleton-row">
                <Skeleton className="skel" active />
                <Skeleton className="skel" active />
                <Skeleton className="skel" active />
              </div>
            ) : listProperties.length > 0 ? (
              listProperties
                .slice(pagination.minValue, pagination.maxValue)
                .map((x, i) => (
                  <Card
                    onClick={() => handleRoute(x.listing_id)}
                    key={i}
                    className="selection-card"
                    style={{
                      boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                    }}
                    cover={
                      <div className="fav_img">
                        <img
                          src={
                            x.featured_img[0].img_960[0]
                              ? x.featured_img[0].img_960[0].url
                              : ""
                          }
                          alt="selection"
                        />
                        {favourites === true ? (
                          <div className="heart_icon">
                            <img
                              src={HeartFilled}
                              alt="HeartBorder"
                              onMouseEnter={() => setClick(true)}
                              onMouseLeave={() => setClick(false)}
                              onClick={() => handleFavorite(x.listing_id)}
                            />
                          </div>
                        ) : (
                          <div className="heart_icon">
                            <img
                              src={HeartBorder}
                              alt="HeartBorder"
                              onMouseEnter={() => setClick(true)}
                              onMouseLeave={() => setClick(false)}
                              onClick={() => handleFavorite(x.listing_id)}
                            />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <div className="name_rating">
                      <Tooltip title={x.property_title}>
                        <h3>{x.property_title}</h3>
                      </Tooltip>

                      {/* <p>
                      {x.rating}
                      <StarFilled className="star_icon" />
                    </p> */}
                    </div>
                    <p className="place_name">
                      <span>{x.state},</span>
                      {""}
                      <span> {x.country}</span>
                    </p>
                    <Row style={{ marginBottom: "5px" }}>
                      {" "}
                      <Col span={8}>
                        {" "}
                        <div className="card_img_icon_svg">
                          <img src={Place} alt="bed" />
                        </div>
                        <p className="name_icon">
                          {getPropType(x.type_property)}
                        </p>
                      </Col>
                      <Col span={8}>
                        <div className="card_img_icon_svg">
                          <img src={BedRooms} alt="Bedrooms" />
                        </div>
                        <p className="name_icon">{x.num_of_bedroom} {(x.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                      </Col>
                      <Col span={8}>
                        <div className="card_img_icon_svg">
                          <img src={BathTab} alt="bathTub" />
                        </div>
                        <p className="name_icon">{x.num_of_bath} {(x.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                      </Col>
                    </Row>
                    <div className="d-flex">
                      <h4>
                        <span className="rupee"> ₹ </span> {x.price_night}
                      </h4>
                      <b className="fonts"> /night</b>
                    </div>
                    {/* </Link> */}
                  </Card>
                ))
            ) : (
              <Col lg={24} className="empty-listings">
                <h1>No Properties found for this search!</h1>
              </Col>
            )}
          </div>
          <div className="pagination">
            {/* <Button
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleNext(0)}
            >
              Prev
            </Button> */}
            <Pagination
              defaultCurrent={1}
              defaultPageSize={9}
              onChange={handleChangePagination}
              total={count}
            />
            {/* <Button
              style={{ marginLeft: "0.5rem" }}
              onClick={() => handleNext(1)}
              disabled={listProperties.length < 18 ? true : false}
            >
              Next
            </Button> */}
          </div>
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}
export default withRouter(SearchProperty);
