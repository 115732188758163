import React, { useState, useEffect, useRef, useCallback } from 'react'
import moment from "moment"
//import {Row, Col, Button, Spin, Card, Tooltip, Pagination, Select as Select1} from "antd";
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Select as Select1,
  Divider,
  Tooltip,
  Pagination,
  Modal,
  Checkbox,
  Input,
  Typography,
  Spin
} from "antd";
import { DateRangePicker } from 'react-dates';
import {
  SearchOutlined,
  PlusOutlined,
  MinusOutlined,
  CompassOutlined,
  CalendarOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import "./index.scss";
import { throttle, debounce } from "throttle-debounce"
import axios from "axios";
import { useLocation } from 'react-router';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import Place from "../../assets/images/place.svg";
import useDidMountEffect from "./NotFirstTime";
import { Helmet } from "react-helmet";
import { Hidden } from '@material-ui/core';

function Index(props) {
  const { Struct } = props.match.params
  const city = Struct.includes("homestay-in-") ? Struct.replace("homestay-in-", "") : "Goa"

  const location = useLocation();
  const { area,
    type,
    place_id,
    location_type,
    secondary,
    check_in,
    check_out,
    guests,
    num_of_bedroom,
    start_price,
    end_price,
    amenities,
    type_property,
    pets,
    limit,
    skip,
    sort
  } = queryString.parse(location.search);



  const [form1, setForm1] = useState({
    "area": area ? area : null,
    "secondary": secondary ? (secondary === "null" ? null : secondary) : null,
    "type": type ? (type === "null" ? null : type) : null,
    "place_id": place_id ? (place_id === "null" ? null : place_id) : null,
    "location_type": location_type ? (location_type === "null" ? null : location_type) : null,
    "check_in": check_in ? (check_in === "null" ? null : check_in) : null,
    "check_out": check_out ? (check_out === "null" ? null : check_out) : null,
    "guests": guests ? (guests === "null" ? 1 : parseInt(guests)) : 1,
    "num_of_bedroom": num_of_bedroom ? parseInt(num_of_bedroom) : null,
    "start_price": start_price ? parseInt(start_price) : null,
    "end_price": end_price ? parseInt(end_price) : null,
    "amenities": amenities ? amenities.split(",") : null,
    "type_property": type_property ? type_property.split(",") : null,
    "pets": pets ? pets : null,
    "limit": limit ? parseInt(limit) : 9,
    "skip": skip ? parseInt(skip) : 0,
    "sort": sort ? parseInt(sort) : 4
  });


  useDidMountEffect(() => {
    form1.skip !== 0 && setForm1(prev => ({ ...prev, skip: 0 }))
  }, [form1.area, form1.type, form1.check_in, form1.check_out, form1.guests])

  useEffect(() => {
    //fetchSearchResult()
    fetchCityResult()
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    //fetchAmenities()
  }, [])

  const prop_type_vals = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ]

  const defaultSearch = [
    {
      "type": "default",
      "name": "Goa",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Karnataka",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Uttarakhand",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Himachal Pradesh",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Kerala",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Ladakh",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Sikkim",
      "secondary_name": "India",
    },
    {
      "type": "default",
      "name": "Rajasthan",
      "secondary_name": "India",
    }]

  const searchBoxRef = useRef(null);
  const formRef = useRef(null);
  const dateRef = useRef(null);
  const firstElementRef = useRef(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchText, setSearchText] = useState(area ? `${area}`.concat(secondary ? (secondary === "null" ? "" : `, ${secondary}`) : "") : "");
  const [predictions, setPredictions] = useState([]);
  const [searchBoxActive, setSearchBoxActive] = useState(false);
  const [fetchedListings, setFetchedListings] = useState(null);
  const [loadingListings, setLoadingListings] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [amenities_list, setAmenities] = useState([]);

  const { Option } = Select1;
  const { Title, Text } = Typography;

  useEffect(() => {
    console.log(fetchedListings)
  }, [fetchedListings])

  const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  }


  const fetchPredictions = (user_input) => {
    const config = {
      method: 'get',
      url: `https://api.roamhome.in/api/v1/public/misc/autocomplete?user_input=${user_input}&num_results=5`,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        setPredictions(response.data.predictions);
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  const fetchCityResult = () => {
    setLoadingListings(true)
    let searchQue = `https://api.roamhome.in/api/v1/public/misc/search_city?city=${city}&state=${city}`
    const config = {
      method: 'get',
      url: searchQue,
      headers: {}
    }

    axios(config)
      .then(function (response) {
        setFetchedListings({ "result": response.data, "results_count": response.data.length })
        setLoadingListings(false)
        window.scrollTo(0, 0)
      })
      .catch(function (error) {
        setLoadingListings(false)
      });
  }




  const setSelectedArea = (item) => {
    if (item.type === "native") {
      props.history.push(`/desc/${item.url}`)
    }

    if (item.type === "google" || item.type === "default") {
      setForm1(prev => ({ ...prev, area: item.name, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type }))
      setSearchText(`${item.name}, ${item.secondary_name}`)
      form1.check_in === null && setFocusedInput("startDate")
    }

    if (item.type === "unavailable") {
      setForm1(prev => ({ ...prev, area: searchText, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type }))
      form1.check_in === null && setFocusedInput("startDate")
    }

    setSearchBoxActive(false)

  }

  const searchQuery = () => {


    if (searchText.length === 0) {
      searchBoxRef.current.focus()
      return
    }

    if (form1.check_in === null) {
      setFocusedInput("startDate")
      return
    }

    if (form1.check_out === null) {
      setFocusedInput("endDate")
      return
    }

    setForm1(prev => ({
      ...prev,
      "num_of_bedroom": null,
      "start_price": null,
      "end_price": null,
      "amenities": null,
      "type_property": null,
      "pets": null
    }))

    if (formRef.current) {
      formRef.current.resetFields();
    }




    let searchQue = `/search?area=${form1.area}&secondary=${form1.secondary ? form1.secondary : null}&type=${form1.type ? form1.type : null}&place_id=${form1.place_id ? form1.place_id : null}&check_in=${form1.check_in ? form1.check_in : null}&check_out=${form1.check_out ? form1.check_out : null}&guests=${form1.guests ? form1.guests : 1}&limit=${form1.limit ? form1.limit : 9}&skip=${form1.skip ? form1.skip : 0}&sort=${form1.sort ? form1.sort : 1}`
    if (form1.location_type !== null && form1.location_type !== undefined) {
      form1.location_type.forEach(elem => {
        searchQue = searchQue.concat(`&location_type=${elem}`)
      })
    }




    props.history.push(searchQue)

  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(1000, fetchPredictions), [])
  // eslint-disable-next-line
  const throttledSearch = useCallback(throttle(1000, fetchPredictions), [])

  useEffect(() => {
    searchText !== "" && searchText.length < 5 && throttledSearch(searchText)
    searchText !== "" && searchText.length > 5 && debouncedSearch(searchText)
    // eslint-disable-next-line
  }, [searchText])


  const handleChangePagination = (page) => {
    if (searchText.length === 0) {
      searchBoxRef.current.focus()
      return
    }

    if (form1.check_in === null) {

      setFocusedInput("startDate")
      return
    }

    if (form1.check_out === null) {

      setFocusedInput("endDate")
      return
    }


    setForm1(prev => ({ ...prev, skip: parseInt((page - 1) * form1.limit) }))

  }

  const handleChangePaginationMB = (page) => {
    if (searchText.length === 0) {
      searchBoxRef.current.focus()
      return
    }

    if (form1.check_in === null) {
      dateRef.current.scrollIntoView()
      setFocusedInput("startDate")
      return
    }

    if (form1.check_out === null) {
      dateRef.current.scrollIntoView()
      setFocusedInput("endDate")
      return
    }

    firstElementRef.current.scrollIntoView()
    setForm1(prev => ({ ...prev, skip: parseInt((page - 1) * form1.limit) }))

  }

  useDidMountEffect(() => {
    if (form1.num_of_bedroom !== null || form1.start_price !== null || form1.end_price !== null || form1.amenities !== null || form1.type_property !== null || form1.pets !== null) {
      filterSearch({ "start_price": form1.start_price === null ? undefined : form1.start_price, "end_price": form1.end_price === null ? undefined : form1.end_price, "pagination": 1 })
    } else {
      searchQuery()
    }
  }, [form1.skip])

  const sortable = (value) => {
    setForm1(prev => ({ ...prev, sort: value }))
    searchQuery()
  }


  const filterSearch = (values) => {
    let searchQue = `/search?area=${form1.area}&secondary=${form1.secondary ? form1.secondary : null}&type=${form1.type ? form1.type : null}&place_id=${form1.place_id ? form1.place_id : null}&check_in=${form1.check_in ? form1.check_in : null}&check_out=${form1.check_out ? form1.check_out : null}&guests=${form1.guests ? form1.guests : 1}&limit=${form1.limit ? form1.limit : 9}&skip=${values.pagination ? form1.skip ? form1.skip : 0 : 0}&sort=${form1.sort ? form1.sort : 1}`

    if (form1.location_type !== null && form1.location_type !== undefined) {
      form1.location_type.forEach(elem => {
        searchQue = searchQue.concat(`&location_type=${elem}`)
      })
    }

    if (!values.pagination) {
      if (values.start_price && values.end_price) {
        setForm1(prev => ({ ...prev, start_price: values.start_price, end_price: values.end_price }))
      }
      setForm1(prev => ({ ...prev, skip: 0 }))
    }

    searchQue = form1.num_of_bedroom !== null ? searchQue.concat(`&num_of_bedroom=${form1.num_of_bedroom}`) : searchQue
    searchQue = values.start_price ? searchQue.concat(`&start_price=${values.start_price}`) : searchQue
    searchQue = values.end_price ? searchQue.concat(`&end_price=${values.end_price}`) : searchQue
    searchQue = form1.pets !== null ? searchQue.concat(`&pets=${form1.pets}`) : searchQue

    if (form1.amenities !== null) {
      form1.amenities.forEach(elem => {
        searchQue = searchQue.concat(`&amenities=${elem}`)
      })
    }

    if (form1.type_property !== null) {
      form1.type_property.forEach(elem => {
        searchQue = searchQue.concat(`&type_property=${elem}`)
      })
    }


    props.history.push(searchQue)
    setFilterOpen(false)
  }

  const handleRoute = (listing_id) => {
    if (form1.check_in === null && form1.check_out === null) {
      props.history.push(`/desc/${listing_id}`)
    }

    if (form1.check_in !== null && form1.check_out !== null) {
      ///desc/:property_id/:state/:adults/:children/:check_in/:check_out
      props.history.push(`/desc/${listing_id}/${form1.area}/${form1.guests}/0/${form1.check_in !== null ? moment(form1.check_in).toISOString() : moment().add(1, "days").toISOString()}/${form1.check_out !== null ? moment(form1.check_out).toISOString() : moment().add(2, "days").toISOString()}`)
    }
  }




  return (
    <>

      {city === "Goa" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Goa",
              "item": "https://www.roamhome.in/Goa/luxury-villas-in-goa"  
            }]
          }`
        }]}>
          <title>Villas in Goa | Cottages in Goa | Roamhome</title>
          <meta name="title" content={`Villas in Goa | Cottages in Goa | Roamhome`} />
          <meta
            name="description"
            content={`Book your homestay in Goa with Roamhome. We offer a wide range of luxury apartments, cottages, & villas for rent in Goa at the best possible price.`}
          />
          <meta name="keywords" content={`villas in goa, cottages in goa, homestay in goa, lodges in goa, holiday homes in goa, pet friendly villas in goa`} />
        </Helmet>
      )}

      {city === "Leh" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Homestay in Leh",
              "item": "https://www.roamhome.in/Ladakh/homestay-in-Leh"  
            }]
          }`
        }]}>
          <title>Homestay in Leh Ladakh | Budget Hotels in Leh | Roamhome</title>
          <meta name="title" content={`Homestay in Leh Ladakh | Budget Hotels in Leh | Roamhome`} />
          <meta
            name="description"
            content={`Find budget hotels in Leh or book Villas in Leh Ladakh. Roamhome provides affordable and comfortable accommodation in Leh, and homestays in Leh.`}
          />
          <meta name="keywords" content={`villas in leh, cottages in leh, homestay in leh, lodges in leh, holiday homes in leh, pet friendly villas in leh`} />
        </Helmet>
      )}

      {city === "Mussoorie" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Mussoorie",
              "item": "https://www.roamhome.in/Uttarakhand/homestay-in-Mussoorie"  
            }]
          }`
        }]}>
          <title>Villas in Mussoorie Uttarakhand | Cottages in Mussoorie | Roamhome</title>
          <meta name="title" content={`Villas in Mussoorie Uttarakhand | Cottages in Mussoorie | Roamhome`} />
          <meta
            name="description"
            content={`Roamhome is the best place to find & book homestays, apartments & cottages in Mussoorie as well as rental villas in Mussoorie. Plan your next trip.`}
          />
          <meta name="keywords" content={`villas in mussoorie, cottages in mussoorie, homestay in mussoorie, lodges in mussoorie, holiday home in mussoorie, pet friendly villas in mussoorie`} />
        </Helmet>
      )}

      {city === "Nainital" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Nainital",
              "item": "https://www.roamhome.in/Uttarakhand/homestay-in-Nainital"  
            }]
          }`
        }]}>
          <title>Villas in Nainital Uttarakhand | Cottages in Nainital | Roamhome</title>
          <meta name="title" content={`Villas in Nainital Uttarakhand | Cottages in Nainital | Roamhome`} />
          <meta
            name="description"
            content={`Make your vacation in Nainital memorable with Roamhome, the best destination for your vacation & holiday bookings with cottages & villas in Nainital.`}
          />
          <meta name="keywords" content={`villas in nainital, cottages in nainital, homestay in nainital, lodges in nainital, holiday home in nainital, pet friendly villas in nainital`} />
        </Helmet>
      )}

      {city === "Mukteshwar" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Mukteshwar",
              "item": "https://www.roamhome.in/Uttarakhand/homestay-in-Mukteshwar"  
            }]
          }`
        }]}>
          <title>Villas in Mukteshwar Uttarakhand | Cottages in Mukteshwar | Roamhome</title>
          <meta name="title" content={`Villas in Mukteshwar Uttarakhand | Cottages in Mukteshwar | Roamhome`} />
          <meta
            name="description"
            content={`Roamhome is one of the best homestays in Mukteshwar, with a warm & comfortable stay. We offer apartments, cottages & villas in Mukteshwar at the best price.`}
          />
          <meta name="keywords" content={`villas in mukteshwar, cottages in mukteshwar, homestay in mukteshwar, lodges in mukteshwar, holiday home in mukteshwar, pet friendly villas in mukteshwar`} />
        </Helmet>
      )}

      {city === "Pakyong" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Pakyong",
              "item": "https://www.roamhome.in/Sikkim/homestay-in-Pakyong"  
            }]
          }`
        }]}>
          <title>Pakyong Homestay Sikkim | Hotels in Pakyong | Roamhome</title>
          <meta name="title" content={`Pakyong Homestay Sikkim | Hotels in Pakyong | Roamhome`} />
          <meta
            name="description"
            content={`Are you looking for the best homestay in Pakyong? Roamhome is a homestay in Sikkim offering budget accommodation, apartments, cottages & villas in Pakyong.`}
          />
          <meta name="keywords" content={`villas in pakyong, cottages in pakyong, homestay in sikkim, lodges in pakyong, holiday homes in pakyong, pet frienly villas in pakyong`} />
        </Helmet>
      )}

      {city === "Chikmagalur" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Chikmagalur",
              "item": "https://www.roamhome.in/Karnataka/homestay-in-Chikmagalur"  
            }]
          }`
        }]}>
          <title>Villas in Chikmagalur Karnataka | Cottages in Chikmagalur | Roamhome</title>
          <meta name="title" content={`Villas in Chikmagalur Karnataka | Cottages in Chikmagalur | Roamhome`} />
          <meta
            name="description"
            content={`Roamhome is one of the finest & safe bookings for homestays in Chikmagalur. We have a wide range of lodges, apartments, cottages & villas in Chikmagalur.`}
          />
          <meta name="keywords" content={`villas in chikmagalur, cottages in chikmagalur, homestay in chikmagalur, lodges in chikmagalur, holiday homes in chikmagalur, pet friendly villas in chikmagalur`} />
        </Helmet>
      )}

      {city === "Coorg" && (
        <Helmet script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Homepage",
              "item": "https://www.roamhome.in/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Villas in Coorg",
              "item": "https://www.roamhome.in/Karnataka/homestay-in-Coorg"  
            }]
          }`
        }]}>
          <title>Villas in Coorg Karnataka | Cottages in Coorg | Roamhome</title>
          <meta name="title" content={`Villas in Coorg Karnataka | Cottages in Coorg | Roamhome`} />
          <meta
            name="description"
            content={`Choose the wide range of best villas in Coorg at Roamhome. We offer holiday homes, apartments, lodges, & cottages in Coorg at reasonable prices.`}
          />
          <meta name="keywords" content={`villas in coorg, cottages in coorg, homestay in coorg, lodges in coorg, holiday homes in coorg, pet friendly villas in coorg`} />
        </Helmet>
      )}

      {/* filter modal */}

      {/** Mobile Website */}
      <Hidden smUp>
        <div className='mobileSegment'>
          <div className='containerMobile'>
            <div className='searchBox'>
              <div className='boxSearchElements'>
                <div className='element1SearchBox'>
                  <div onClick={() => searchBoxRef.current && searchBoxRef.current.focus()} className={searchFocused ? "focusedMobileSearch searchBoxElement" : "searchBoxElement"}>
                    <div className="searchBoxIcon">
                      <SearchOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                    </div>
                    <div className="searchInputBox">
                      <input
                        //autoFocus
                        ref={searchBoxRef}
                        onBlur={() => setSearchFocused(false)}
                        onFocus={() => setSearchFocused(true)}
                        className="searchInputTypingBox"
                        style={{ "width": "100%" }}
                        placeholder="Where are you going?"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        type="text" />
                    </div>
                    {(searchFocused || searchBoxActive) && (
                      <div onMouseEnter={() => setSearchBoxActive(true)} onMouseLeave={() => setSearchBoxActive(false)} className="search-suggestions">
                        <div className="search-suggestions-header">
                          PLACES IN INDIA
                        </div>
                        {searchText.length === 0 && (
                          <>
                            {defaultSearch.map((item, key) => (
                              <div onMouseDown={(e) => setSelectedArea(item)} onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                <Row style={{ alignItems: "center" }}>
                                  <Col xs={4} lg={4}>
                                    <div className="prediction-ico"><SearchOutlined /></div>
                                  </Col>
                                  <Col xs={20} lg={20}>
                                    <div className="prediction-name">{item.name}</div>
                                    <div className="prediction-secondary">{item.secondary_name}</div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </>
                        )}
                        {searchText.length !== 0 && predictions.length !== 0 && (
                          <>
                            {predictions.map((item, key) => (
                              <div onMouseDown={(e) => setSelectedArea(item)} onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                <Row style={{ alignItems: "center" }}>
                                  <Col xs={4} lg={4}>
                                    <div className="prediction-ico">
                                      {item.type === "google" && (
                                        item.location_type.indexOf("administrative_area_level_1") > -1 ? <SearchOutlined /> : <CompassOutlined />
                                      )}

                                      {item.type === "native" && <img className="prediction-img-ico" alt="featured slt" src={item.featured_img} />}


                                    </div>
                                  </Col>
                                  <Col xs={20} lg={20}>
                                    <div className="prediction-name">{item.name}</div>
                                    <div className="prediction-secondary">{item.secondary_name}</div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </>
                        )}
                        {searchText.length !== 0 && predictions.length === 0 && (
                          <>
                            <div onMouseDown={(e) => setSelectedArea({ "type": "unavailable" })} onClick={(e) => setSelectedArea({ "type": "unavailable" })} className="default-predictions">
                              <Row style={{ alignItems: "center" }}>
                                <Col xs={4} lg={4}>
                                  <div className="prediction-ico"><SearchOutlined /></div>
                                </Col>
                                <Col xs={20} lg={20}>
                                  <div className="prediction-name">{searchText}</div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                </div>
                <div className='element2SearchBox'>
                  <div className={focusedInput ? "focusedMobileSearch dateBoxElement" : "dateBoxElement"}>
                    <div className="searchBoxIcon">
                      <CalendarOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                    </div>
                    <div ref={dateRef} className="searchInputBox">
                      <DateRangePicker

                        noBorder={true}
                        startDatePlaceholderText="Check-in"
                        endDatePlaceholderText="Check-out"
                        startDateId="startDate1"
                        endDateId="endDate1"
                        startDate={form1.check_in && moment(form1.check_in)}
                        minDate={moment().subtract(1, "days")}
                        maxDate={moment().add(365, "days")}
                        endDate={form1.check_out && moment(form1.check_out)}
                        onDatesChange={({ startDate, endDate }) => {
                          setForm1(prev => ({ ...prev, check_in: moment(startDate).toISOString(), check_out: moment(endDate).toISOString() }))
                        }}
                        numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                        displayFormat="DD MMM YY"
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                        block
                      />
                    </div>
                  </div>
                </div>
                <div className="element3SearchBox">
                  <div className="guestBoxElement">
                    <div className="searchBoxIcon">
                      <UsergroupAddOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                    </div>
                    <div className="searchInputBox">
                      <div className="searchInputLabel">
                        Guests
                      </div>
                    </div>
                    <div className="guestBtn">
                      <div className="count">
                        <button
                          disabled={form1.guests > 0 ? false : true}
                          onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) - 1 }))}
                        >
                          <MinusOutlined style={{ color: "#9e9e9e" }} />
                        </button>
                      </div>
                      <div className="count">
                        <div className="guestCount">
                          {form1.guests === "" ? 0 : form1.guests}
                        </div>
                      </div>
                      <div className="count">
                        <button

                          onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) + 1 }))}

                        >
                          <PlusOutlined style={{ color: "#9e9e9e" }} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="element3SearchBox">
                  <div className="searchButtonBoxElement">
                    <button onClick={searchQuery}>Search</button>
                  </div>
                </div>
              </div>
            </div>
            <div ref={firstElementRef} className='loadingContent'>
              {loadingListings && (
                <div className="loading">
                  <Spin size={"large"} />
                </div>
              )}
            </div>
            {!loadingListings && (
              <>
                {fetchedListings !== null && fetchedListings.result.length !== 0 && (
                  <div className='listingsContent'>
                    <div className='listingsLabel'>
                      {fetchedListings.results_count > 10 ? fetchedListings.results_count.toString().slice(0, -1) + "0+ Vacation rentals in " + city : fetchedListings.result.length.toString() + "+ Vacation rentals in " + city}
                    </div>
                    <div className='buttonListings'>
                      {/* <div className='buttonListing'>
                        <Select1
                          className='mobileSelectBtn'
                          placeholder="Sort By"
                          size="large"
                          defaultValue={form1.sort}
                          onChange={sortable}
                        > <Option key={"1"} value={1}>
                            Low to High
                          </Option>
                          <Option key={"2"} value={2}>
                            High to Low
                          </Option>
                          <Option key={"3"} value={3}>
                            Sort By Instant Book
                          </Option>
                          <Option key={"4"} value={4}>
                            Unsorted
                          </Option>

                        </Select1>
                      </div>
                      <div className='buttonListing'>
                        <button className='buttonFilter' onClick={() => setFilterOpen(true)}>Filter</button>
                      </div> */}
                    </div>
                  </div>
                )}
                <div className='listingsCardsWrapper'>
                  {fetchedListings !== null && (
                    <>
                      {fetchedListings.result.length === 0 && (
                        <>
                          <div className='emptyListings'>
                            Oops, your search didn't return any homes!
                          </div>
                          <div className='listingsEmptyCards'>
                            {fetchedListings.suggestions && fetchedListings.suggestions.map((item, idx) => (
                              <div className='listingsCard'>
                                <div className='listingsCardsLabel'>
                                  Explore homes in {item.state}
                                </div>
                              </div>
                            ))}
                            { }
                          </div>
                        </>
                      )}


                      {fetchedListings.result.length !== 0 && (
                        <>
                          <div className='listingsCards'>
                            {fetchedListings.result.map((item, idx) => (
                              <div className='listingCard'>
                                <Link key={idx} rel="noopener noreferrer" to={`/desc/${item.url}`} onClick={(e) => { e.preventDefault(); handleRoute(item.url); }}>
                                  <div className='contentContainer'>
                                    <div className='imageBox'>
                                      <img src={item.featured_img[0].img_480[0].url} />
                                    </div>
                                    <div className='locationBox'>
                                      {item.city}, {item.state}
                                    </div>
                                    <div className='propertyName'>
                                      {item.property_title}
                                    </div>
                                    <div className='priceNight'>
                                      {numberFormat(item.price_night)} / night
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>

                        </>

                      )}

                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Hidden>
      {/** Desktop Website */}
      <Hidden xsDown>
        <div
          className="container"
          style={{ position: "relative" }}
        >
          
          <div className="search_property_bar" style={{ position: "relative", "zIndex": 5 }}>
            <Row style={{ gap: "8px" }}>
              <Col xs={24} lg={8}>
                <div onClick={() => searchBoxRef.current && searchBoxRef.current.focus()} className={searchFocused ? "focused-searchbox searchbox" : "searchbox"}>
                  <div className="legend">Location</div>
                  <div className="searchox">
                    <input
                      ref={searchBoxRef}
                      onBlur={() => setSearchFocused(false)}
                      onFocus={() => setSearchFocused(true)}
                      className="location-search"
                      style={{ "width": "100%" }}
                      placeholder="Where are you going?"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      type="text" />
                  </div>
                </div>

                {(searchFocused || searchBoxActive) && (
                  <div onMouseEnter={() => setSearchBoxActive(true)} onMouseLeave={() => setSearchBoxActive(false)} className="search-suggestions">
                    <div className="search-suggestions-header">
                      PLACES IN INDIA
                    </div>
                    {searchText.length === 0 && (
                      <>
                        {defaultSearch.map((item, key) => (
                          <div onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                            <Row style={{ alignItems: "center" }}>
                              <Col xs={4} lg={4}>
                                <div className="prediction-ico"><SearchOutlined /></div>
                              </Col>
                              <Col xs={20} lg={20}>
                                <div className="prediction-name">{item.name}</div>
                                <div className="prediction-secondary">{item.secondary_name}</div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    )}
                    {searchText.length !== 0 && predictions.length !== 0 && (
                      <>
                        {predictions.map((item, key) => (
                          <div onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                            <Row style={{ alignItems: "center" }}>
                              <Col xs={4} lg={4}>
                                <div className="prediction-ico">
                                  {item.type === "google" && (
                                    item.location_type.indexOf("administrative_area_level_1") > -1 ? <SearchOutlined /> : <CompassOutlined />
                                  )}

                                  {item.type === "native" && <img className="prediction-img-ico" alt="featured slt" src={item.featured_img} />}


                                </div>
                              </Col>
                              <Col xs={20} lg={20}>
                                <div className="prediction-name">{item.name}</div>
                                <div className="prediction-secondary">{item.secondary_name}</div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    )}
                    {searchText.length !== 0 && predictions.length === 0 && (
                      <>
                        <div onClick={(e) => setSelectedArea({ "type": "unavailable" })} className="default-predictions">
                          <Row style={{ alignItems: "center" }}>
                            <Col xs={4} lg={4}>
                              <div className="prediction-ico"><SearchOutlined /></div>
                            </Col>
                            <Col xs={20} lg={20}>
                              <div className="prediction-name">{searchText}</div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </div>
                )}

              </Col>
              <Col xs={24} lg={8}>
                <div className={focusedInput ? "focused-datebox datebox" : "datebox"}>
                  <Col xs={0} lg={24}>
                    <div className="legend" style={{ opacity: "0" }}>Check-in - Check-Out</div>
                  </Col>
                  <DateRangePicker
                    noBorder={true}
                    startDatePlaceholderText="Check-in"
                    endDatePlaceholderText="Check-out"
                    startDateId="startDate1"
                    endDateId="endDate1"
                    startDate={form1.check_in && moment(form1.check_in)}
                    minDate={moment().subtract(1, "days")}
                    maxDate={moment().add(365, "days")}
                    endDate={form1.check_out && moment(form1.check_out)}
                    onDatesChange={({ startDate, endDate }) => {
                      setForm1(prev => ({ ...prev, check_in: moment(startDate).toISOString(), check_out: moment(endDate).toISOString() }))
                    }}
                    numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                    displayFormat="MMMM DD"
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                    block
                  />
                </div>
              </Col>
              <Col xs={24} lg={4}>
                <div className="guests-count">
                  <div className="legend">Guests</div>
                  <div className="guests-btns">
                    <span className="counnt">
                      <Button
                        disabled={form1.guests > 0 ? false : true}
                        shape="circle-outline"
                        icon={<MinusOutlined />}
                        size="large"
                        onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) - 1 }))}
                      ></Button>
                    </span>
                    <span className="counnt">
                      <Button size="large" shape="circle-outline">
                        {form1.guests === "" ? 0 : form1.guests}
                      </Button>
                    </span>
                    <span className="counnt">
                      <Button
                        shape="circle-outline"
                        icon={<PlusOutlined />}
                        onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) + 1 }))}
                        size="large"
                      ></Button>
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={3}>
                <div className="searchbox">
                  <div className="legend" style={{ opacity: "0" }}>Search</div>
                  <Col xs={0} lg={24}>
                    <Button onClick={searchQuery} className="search-btn" style={{ "width": "100%" }} shape="round" icon={<SearchOutlined />} size="large"></Button>
                  </Col>
                  <Col xs={24} lg={0}>
                    <Button onClick={searchQuery} className="search-btn" style={{ "width": "100%" }} shape="round" icon={<SearchOutlined />} size="large">Search</Button>
                  </Col>
                </div>
              </Col>

            </Row>
          </div>
          {loadingListings && (
            <div className="loading">
              <Spin size={"large"} />
            </div>
          )}
          {!loadingListings && (
            <>
              {fetchedListings !== null && fetchedListings.result.length !== 0 && (
                <div className="count-and-tools">
                  <Row style={{
                    "justify-content": "space-between",
                    "align-items": "center"
                  }}>
                    <Col>
                      <h1>{fetchedListings.results_count > 10 ? fetchedListings.results_count.toString().slice(0, -1) + "0+ Vacation rentals in " + city : fetchedListings.result.length.toString() + "+ Vacation rentals in " + city}</h1>
                    </Col>
                    <Col>
                      <Row>
                        {/* <Col>
                          <Button size="large" onClick={() => setFilterOpen(true)}>Filter</Button>
                        </Col> */}
                        {/* <Col>
                          <Select1
                            className="search input-field"
                            placeholder="Sort By"
                            size="large"
                            defaultValue={form1.sort}
                            onChange={sortable}
                          >
                            <Option key={"1"} value={1}>
                              Low to High
                            </Option>
                            <Option key={"2"} value={2}>
                              High to Low
                            </Option>
                            <Option key={"3"} value={3}>
                              Sort By Instant Book
                            </Option>
                            <Option key={"4"} value={4}>
                              Unsorted
                            </Option>
                          </Select1>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              <div
                className="selections-card-wrapper"
                style={{
                  marginTop: "1rem",
                  marginBottom: "6rem",
                  zIndex: 4,
                  position: "relative"
                }}
              >
                {fetchedListings !== null && (
                  <>
                    {fetchedListings.result.length === 0 && (
                      <Col lg={24} className="empty-listings">
                        <p>No homes found for this search!</p>
                        {fetchedListings.suggestions && fetchedListings?.suggestions.map((x, i) => (
                          <Row key={i}>
                            <div className="header-explorer">
                              <h2>Explore homes in {x.state}</h2>
                            </div>
                            <div className="selections-card-wrapper card-fold"
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                zIndex: 4,
                                position: "relative"
                              }}>
                              {x.homes.map((y, i) => (
                                <Link key={y.listing_id} rel="noopener noreferrer" to={`/desc/${y.url}`}>
                                  <Card
                                    key={i}
                                    className="selection-card"
                                    style={{
                                      boxShadow: "#00000029 0px 0.5px 4px 0px;",
                                    }}
                                    cover={
                                      <div className="fav_img">
                                        <img
                                          src={
                                            y.featured_img[0].img_960[0]
                                              ? y.featured_img[0].img_960[0].url
                                              : ""
                                          }
                                          alt="selection"
                                        />

                                      </div>
                                    }
                                  >
                                    <div className="name_rating">
                                      <Tooltip title={y.property_title}>
                                        <h3>{y.property_title}</h3>
                                      </Tooltip>

                                    </div>
                                    <p className="place_name">
                                      {y.city && (
                                        <>
                                          <span>{y.city},</span>
                                          {""}
                                          <span> {y.state}</span>
                                        </>
                                      )}
                                      {!y.city && (
                                        <>
                                          <span>{y.state},</span>
                                          {""}
                                          <span> {y.country}</span>
                                        </>
                                      )}
                                    </p>
                                    <Row style={{ marginBottom: "5px" }}>
                                      {" "}
                                      <Col span={8}>
                                        {" "}
                                        <div className="card_img_icon_svg">
                                          <img src={Place} alt="bed" />
                                        </div>
                                        <p className="name_icon">
                                          {y.type_property}
                                        </p>
                                      </Col>
                                      <Col span={8}>
                                        <div className="card_img_icon_svg">
                                          <img src={BedRooms} alt="Bedrooms" />
                                        </div>
                                        <p className="name_icon">{y.num_of_bedroom} {(y.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                                      </Col>
                                      <Col span={8}>
                                        <div className="card_img_icon_svg">
                                          <img src={BathTab} alt="bathTub" />
                                        </div>
                                        <p className="name_icon">{y.num_of_bath} {(y.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                                      </Col>
                                    </Row>
                                    <div className="d-flex">
                                      <h4>
                                        {numberFormat(y.price_night)}
                                      </h4>
                                      <b className="fonts"> /night</b>
                                    </div>
                                  </Card>
                                </Link>
                              ))}
                            </div>

                          </Row>
                        ))}
                      </Col>
                    )}

                    {fetchedListings.result.length !== 0 && (
                      fetchedListings.result.map((x, i) => (
                        <Link key={x.listing_id} onClick={(e) => {
                          e.preventDefault()
                          handleRoute(x.url)
                        }} rel="noopener noreferrer" to={`/desc/${x.url}`}>
                          <Card
                            key={i}
                            className="selection-card"
                            style={{
                              boxShadow: "#00000029 0px 0.5px 4px 0px;",
                            }}
                            cover={
                              <div className="fav_img">
                                <img
                                  src={
                                    x.featured_img[0].img_960[0]
                                      ? x.featured_img[0].img_960[0].url
                                      : ""
                                  }
                                  alt="selection"
                                />

                              </div>
                            }
                          >
                            <div className="name_rating">
                              <Tooltip title={x.property_title}>
                                <h3>{x.property_title}</h3>
                              </Tooltip>

                            </div>
                            <p className="place_name">
                              {x.city && (
                                <>
                                  <span>{x.city},</span>
                                  {""}
                                  <span> {x.state}</span>
                                </>
                              )}
                              {!x.city && (
                                <>
                                  <span>{x.state},</span>
                                  {""}
                                  <span> {x.country}</span>
                                </>
                              )}
                            </p>
                            <Row style={{ marginBottom: "5px" }}>
                              {" "}
                              <Col span={8}>
                                {" "}
                                <div className="card_img_icon_svg">
                                  <img src={Place} alt="bed" />
                                </div>
                                <p className="name_icon">
                                  {x.type_property}
                                </p>
                              </Col>
                              <Col span={8}>
                                <div className="card_img_icon_svg">
                                  <img src={BedRooms} alt="Bedrooms" />
                                </div>
                                <p className="name_icon">{x.num_of_bedroom} {(x.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                              </Col>
                              <Col span={8}>
                                <div className="card_img_icon_svg">
                                  <img src={BathTab} alt="bathTub" />
                                </div>
                                <p className="name_icon">{x.num_of_bath} {(x.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                              </Col>
                            </Row>
                            <div className="d-flex">
                              <h4>
                                {numberFormat(x.price_night)}
                              </h4>
                              <b className="fonts"> /night</b>
                            </div>
                          </Card>
                        </Link>
                      ))
                    )}
                  </>
                )}
              </div>

            </>
          )}
        </div>
      </Hidden>
    </>
  )
}

export default Index
