import React from "react";

function Double() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        We take the best possible steps to ensure that there isn’t a double
        booking on any of your reserved properties. However, in the case a
        double booking comes through, in spite of our best efforts to avoid the
        same, then in such a case we will either adjust the guest to an
        equivalent property or refund the amount to them, on a case-to-case
        basis.
      </p>
    </div>
  );
}

export default Double;
