import React from 'react'
import {Row, Col, Avatar} from "antd";
import moment from "moment";

function Reviews({reviews}) {
    return (
        <div>
            {reviews.length === 0 && (
                <>
                    No reviews for this home.
                </>
            )}

            {reviews.length !== 0 && (
                reviews.map((item, x) => (
                    <div className="feedback-review">
                    <Row>
                        <Col xs={6}>
                            <div className="avatar-feedback">
                            {item.user_avatar && (
                                            <Avatar
                                            style={{
                                                backgroundColor: "#2d2d2d",
                                                verticalAlign: "middle",
                                                cursor: "pointer"
                                            }}
                                            size="large"
                                            src={item.user_avatar}
                                            >
                                            </Avatar>
                                        )}

                                        {!item.user_avatar && (
                                            <Avatar
                                            style={{
                                                backgroundColor: "#2d2d2d",
                                                verticalAlign: "middle",
                                                cursor: "pointer"
                                            }}
                                            size="large"
                                            >
                                                <span style={{ textTransform: "capitalize" }}> {item.booking_account_name.charAt(0)}</span>
                                            </Avatar>
                                        )}
                            </div>
                            <div className="name-feedback">{item.booking_account_name}</div>
                            <div className="added-on-feedback">{moment(item.timestamp).format("MMMM DD, YYYY")}</div>
                        </Col>
                        <Col xs={18}>
                            <div className="date-booked">Stayed {moment(item.booking.date_from).format("DD MMMM")} &ndash; {moment(item.booking.date_to).format("DD MMMM")}</div>
                            <div className="home-feedback">{item.home_service_feedback}</div>
                        </Col>
                    </Row>
                </div>
                ))
            )}
        </div>
    )
}

export default Reviews
