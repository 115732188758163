import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import Sidebar from "./sideBar";
import DashboardRoutes from "./Routes";
import { Helmet } from "react-helmet";

const { Content } = Layout;

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 /* const [state, setState] = useState({
    collapsed: false,
  });

  */

 /* const toggle = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  */

  return (
    <div>
      <Helmet defer={false}>
        <title> Terms and Conditions &#8211; Roamhome Vacation Rental</title>
        <meta name="title" content={"Terms and Conditions"} />
        <meta
          name="description"
          content="Roamhome is a rental portal that provides curated homes with professional service. Our properties are spread across budgets without compromising our service."
        />
      </Helmet>
      <Row className="container">
        <Col xs={24} lg={24}>
          <div className="terms">
            <h2>Terms and Conditions</h2>
            <p>
              Company has all rights to change the policies. Read below for the
              updated policies
            </p>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" className="container container_terms">
        <Col xs={24} lg={6}>
          <Sidebar />
        </Col>
        <Col xs={24} lg={17}>
          <Content className="contents">
            <DashboardRoutes />
          </Content>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
