import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    Row,
    Col,
    Button,
    Skeleton,
    Carousel as Carousel1
} from "antd";
import { Grid } from '@material-ui/core'
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
    SearchOutlined,
    PlusOutlined,
    MinusOutlined,
    LeftOutlined,
    RightOutlined,
    CompassOutlined,
    CalendarOutlined,
    UsergroupAddOutlined,
    UserAddOutlined
} from "@ant-design/icons";
//import Bed from "../../assets/images/bed.svg";
//import Place from "../../assets/images/place.svg";
import axios from "axios";
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "../../../assets/styles/react-dates/react-dates-override.scss";
import { DateRangePicker } from 'react-dates';
import ReactGA from 'react-ga4';
import { throttle, debounce } from "throttle-debounce"

function HeroSearchBox({ history }) {
    const [focusedInput, setFocusedInput] = useState(null);
    const defaultSearch = [
        {
            "type": "default",
            "name": "Uttarakhand",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Karnataka",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Himachal Pradesh",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Goa",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Ladakh",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Tamil Nadu",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Kerala",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Rajasthan",
            "secondary_name": "India",
        },
        {
            "type": "default",
            "name": "Sikkim",
            "secondary_name": "India",
        }]

    const searchBoxRef = useRef(null);
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [predictions, setPredictions] = useState([]);
    const [searchBoxActive, setSearchBoxActive] = useState(false);

    const [form1, setForm1] = useState({
        "area": null,
        "secondary": null,
        "type": null,
        "place_id": null,
        "location_type": null,
        "check_in": null,
        "check_out": null,
        "guests": 1,
        "limit": 10,
        "skip": 0,
        "sort": 4
    });

    const setSelectedArea = (item) => {


        if (item.type === "native") {
            history.push(`/desc/${item.url}`)
        }

        if (item.type === "google" || item.type === "default") {
            setForm1(prev => ({ ...prev, area: item.name, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type }))
            setSearchText(`${item.name}, ${item.secondary_name}`)
            form1.check_in === null && setFocusedInput("startDate")
        }

        if (item.type === "unavailable") {
            setForm1(prev => ({ ...prev, area: searchText, secondary: item.secondary_name, type: item.type, place_id: item.place_id, location_type: item.location_type }))
            form1.check_in === null && setFocusedInput("startDate")
        }

        setSearchBoxActive(false)

    }


    const searchQuery = () => {


        if (searchText.length === 0) {
            searchBoxRef.current.focus()
            return
        }

        if (form1.check_in === null) {
            setFocusedInput("startDate")
            return
        }

        if (form1.check_out === null) {
            setFocusedInput("endDate")
            return
        }

        let searchQue = `/search?area=${form1.area}&secondary=${form1.secondary ? form1.secondary : null}&type=${form1.type ? form1.type : null}&place_id=${form1.place_id ? form1.place_id : null}&check_in=${form1.check_in ? form1.check_in : null}&check_out=${form1.check_out ? form1.check_out : null}&guests=${form1.guests ? form1.guests : 1}&limit=${form1.limit ? form1.limit : 9}&skip=${form1.skip ? form1.skip : 0}&sort=${form1.sort ? form1.sort : 1}`
        if (form1.location_type !== null && form1.location_type !== undefined) {
            form1.location_type.forEach(elem => {
                searchQue = searchQue.concat(`&location_type=${elem}`)
            })
        }

        ReactGA.event({
            "category": "Home_Page_Search",
            "action": JSON.stringify({ "type": "search", "payload": { "adults": form1.guests, "state": form1.area } }),
        })

        history.push(searchQue)

    }

    const fetchPredictions = (user_input) => {
        const config = {
            method: 'get',
            url: `https://api.roamhome.in/api/v1/public/misc/autocomplete?user_input=${user_input}&num_results=5`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                setPredictions(response.data.predictions);
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    // eslint-disable-next-line
    const debouncedSearch = useCallback(debounce(1000, fetchPredictions), [])
    // eslint-disable-next-line
    const throttledSearch = useCallback(throttle(1000, fetchPredictions), [])

    useEffect(() => {
        searchText !== "" && searchText.length < 5 && throttledSearch(searchText)
        searchText !== "" && searchText.length > 5 && debouncedSearch(searchText)
        // eslint-disable-next-line
    }, [searchText])

    return (
        <div className='searchBox'>
            <div className='boxCaption'>
                <div className='captionContent'>Homes as unique as you</div>
            </div>
            <div className='boxSearchElements'>
                <div className='element1SearchBox'>
                    <div onClick={() => searchBoxRef.current && searchBoxRef.current.focus()} className={searchFocused ? "focusedMobileSearch searchBoxElement" : "searchBoxElement"}>
                        <div className="searchBoxIcon">
                            <SearchOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                        </div>
                        <div className="searchInputBox">
                            <input
                                //autoFocus
                                ref={searchBoxRef}
                                onBlur={() => setSearchFocused(false)}
                                onFocus={() => setSearchFocused(true)}
                                className="searchInputTypingBox"
                                style={{ "width": "100%" }}
                                placeholder="Where are you going?"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                type="text" />
                        </div>
                        {(searchFocused || searchBoxActive) && (
                            <div onMouseEnter={() => setSearchBoxActive(true)} onMouseLeave={() => setSearchBoxActive(false)} className="search-suggestions">
                                <div className="search-suggestions-header">
                                    PLACES IN INDIA
                                </div>
                                {searchText.length === 0 && (
                                    <>
                                        {defaultSearch.map((item, key) => (
                                            <div onMouseDown={(e) => setSelectedArea(item)} onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                                <Row style={{ alignItems: "center" }}>
                                                    <Col xs={4} lg={4}>
                                                        <div className="prediction-ico"><SearchOutlined /></div>
                                                    </Col>
                                                    <Col xs={20} lg={20}>
                                                        <div className="prediction-name">{item.name}</div>
                                                        <div className="prediction-secondary">{item.secondary_name}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </>
                                )}
                                {searchText.length !== 0 && predictions.length !== 0 && (
                                    <>
                                        {predictions.map((item, key) => (
                                            <div onMouseDown={(e) => setSelectedArea(item)} onClick={(e) => setSelectedArea(item)} key={key} className="default-predictions">
                                                <Row style={{ alignItems: "center" }}>
                                                    <Col xs={4} lg={4}>
                                                        <div className="prediction-ico">
                                                            {item.type === "google" && (
                                                                item.location_type.indexOf("administrative_area_level_1") > -1 ? <SearchOutlined /> : <CompassOutlined />
                                                            )}

                                                            {item.type === "native" && <img className="prediction-img-ico" alt="featured slt" src={item.featured_img} />}


                                                        </div>
                                                    </Col>
                                                    <Col xs={20} lg={20}>
                                                        <div className="prediction-name">{item.name}</div>
                                                        <div className="prediction-secondary">{item.secondary_name}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </>
                                )}
                                {searchText.length !== 0 && predictions.length === 0 && (
                                    <>
                                        <div onMouseDown={(e) => setSelectedArea({ "type": "unavailable" })} onClick={(e) => setSelectedArea({ "type": "unavailable" })} className="default-predictions">
                                            <Row style={{ alignItems: "center" }}>
                                                <Col xs={4} lg={4}>
                                                    <div className="prediction-ico"><SearchOutlined /></div>
                                                </Col>
                                                <Col xs={20} lg={20}>
                                                    <div className="prediction-name">{searchText}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>

                </div>
                <div className='element2SearchBox'>
                    <div className={focusedInput ? "focusedMobileSearch dateBoxElement" : "dateBoxElement"}>
                        <div className="searchBoxIcon">
                            <CalendarOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                        </div>
                        <div className="searchInputBox">
                            <DateRangePicker

                                noBorder={true}
                                startDatePlaceholderText="Check-in"
                                endDatePlaceholderText="Check-out"
                                startDateId="startDate1"
                                endDateId="endDate1"
                                startDate={form1.check_in && moment(form1.check_in)}
                                minDate={moment().subtract(1, "days")}
                                maxDate={moment().add(365, "days")}
                                endDate={form1.check_out && moment(form1.check_out)}
                                onDatesChange={({ startDate, endDate }) => {
                                    setForm1(prev => ({ ...prev, check_in: moment(startDate).toISOString(), check_out: moment(endDate).toISOString() }))
                                }}
                                numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                                displayFormat="DD MMM YY"
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                                block
                            />
                        </div>
                    </div>
                </div>
                <div className="element3SearchBox">
                    <div className="guestBoxElement">
                        <div className="searchBoxIcon">
                            <UsergroupAddOutlined style={{ fontSize: "1.4rem", color: searchFocused ? "#9e9e9e" : "#dedcdc" }} />
                        </div>
                        <div className="searchInputBox">
                            <div className="searchInputLabel">
                                Guests
                            </div>
                        </div>
                        <div className="guestBtn">
                            <div className="count">
                                <button
                                    disabled={form1.guests > 0 ? false : true}
                                    onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) - 1 }))}
                                >
                                    <MinusOutlined style={{ color: "#9e9e9e" }} />
                                </button>
                            </div>
                            <div className="count">
                                <div className="guestCount">
                                    {form1.guests === "" ? 0 : form1.guests}
                                </div>
                            </div>
                            <div className="count">
                                <button

                                    onClick={() => setForm1(prev => ({ ...prev, guests: parseInt(prev.guests) + 1 }))}

                                >
                                    <PlusOutlined style={{ color: "#9e9e9e" }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="element3SearchBox">
                    <div className="searchButtonBoxElement">
                        <button onClick={searchQuery}>Search</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(HeroSearchBox);