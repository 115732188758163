import React from "react";
import { Link } from "react-router-dom";
import Flip from "react-reveal/Flip";

function PopularCities({ }) {
  const data = {
    "title": "Popular Cities",
    "destinations": [
      {
        "destination_id": "Goa",
        "destination": "Goa",
        "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tk353lahv63fy-original.jpg",
        "url": "/Goa/luxury-villas-in-goa"
      },
      {
       "destination_id": "Ladakh",
       "destination": "Leh",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4axkl4c-original.jpg",
       "url": "/Ladakh/homestay-in-Leh"
     },
      {
       "destination_id": "Uttarakhand",
       "destination": "Mussoorie",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tk267l1hy7lny3-thumbnail.jpg",
       "url": "/Uttarakhand/homestay-in-Mussoorie"
     },
     {
       "destination_id": "Uttarakhand",
       "destination": "Nainital",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4axxyg2-original.jpg",
       "url": "/Uttarakhand/homestay-in-Nainital"
     },
     {
       "destination_id": "Uttarakhand",
       "destination": "Mukteshwar",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4ay880i-original.jpg",
       "url": "/Uttarakhand/homestay-in-Mukteshwar"
     },
     {
       "destination_id": "Sikkim",
       "destination": "Pakyong",
       "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4ayez6e-original.jpg",
       "url": "/Sikkim/homestay-in-Pakyong"
     },
     {
      "destination_id": "Karnataka",
      "destination": "Chikmagalur",
      "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4ayijhp-original.jpg",
      "url": "/Karnataka/homestay-in-Chikmagalur"
    },
    {
      "destination_id": "Karnataka",
      "destination": "Coorg",
      "img": "https://d2sg0yxuzrccbw.cloudfront.net/image-2tkkpwl4aytc99-original.jpg",
      "url": "/Karnataka/homestay-in-Coorg"
    },
    ]
  }

  
  return (
    <div className="destination-wrapper container">
      <h1>{data.title}</h1>
      <div className="row-wrapper" style={{
        gridTemplateColumns: "repeat(8, 1fr)",
      
      }}>
        {data.destinations
          ? data.destinations.map((x) => (
              <div className="col-place">
                <div className="img-wrapper" style={{position: "relative"}}>
                {/* <Flip left delay={600}> */}
                    <Link to={x.url}>
                      <img
                        src={x.img}
                        alt={`Homestay in ${x.destination}`}
                        style={{
                          width: "230px",
                          objectFit: "cover",
                          height: "300px",
                          borderRadius: "15px"
                        }}
                      />
                      <div className="imgCaption">{x.destination}</div>
                    </Link>
                  {/* </Flip> */}
                </div>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}

export default PopularCities;
