import React, { Component } from "react";
import { message } from "antd";
import axios from "axios";
import Google from "../../assets/images/google.jpg";
import { setSession } from "../Utils/session";

class App extends Component {
  constructor(props) {
    //eslint-disable-next-line
    super();
  }

  componentDidMount() {
    this.googleSDK();
    
  }

  prepareLoginButton = () => {

    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      async (googleUser) => {
        const access_token = googleUser.getAuthResponse().id_token;
        const d = googleUser.getAuthResponse(true).access_token;

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/login/social/google`,

            { access_token }
          );
          const token = await res.headers.token;
          const data = await res.data;
          const user_level = data.user_level;
          const sessionData = {
            token,
            email: data.email,
            user_level,
            google_token: d,
            facebook_token: "",
          };

          if (data.status) {
            setSession(sessionData);
            window.location.reload();
            message.success("Login successful");

          } else {
            message.error("Sorry Please try again");
          }
        } catch (error) {
          
        }
      },
      (error) => {
        
      }
    );
  };

  googleSDK = () => {
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.init({
          client_id:
            "264651056567-fbgfcvjaom8bslf06sfdv5pgsufn8geo.apps.googleusercontent.com",//"525580403877-22l2joeq1i940mbvcmffnp0fs7dl621k.apps.googleusercontent.com",
          project_id: "roamhome-tech",//"roamhome-1575264015419",
          auth_uri: "https://accounts.google.com/o/oauth2/auth",
          token_uri: "https://oauth2.googleapis.com/token",
          auth_provider_x509_cert_url:
            "https://www.googleapis.com/oauth2/v1/certs",
          client_secret: "hYVt-qXjFQa6uFHnnlovXTW6",//"VJuLj3VYy7UBtuucgrz6F7Qr",
          javascript_origins: ["http://localhost:8080","http://beta.roamhome.in","https://beta.roamhome.in"],
        });
        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  };

  render() {
    return (
      <div>
        <button
          style={{
            background: "White",
            color: "black",
            width: "100%",
            borderRadius: "5px",
            height: "35px",
          }}
          className="loginBtn loginBtn--google"
          ref="googleLoginBtn"
        >
          <img
            src={Google}
            style={{
              height: "25px",
              marginRight: "10px",
            }}
            alt="logo"
          />
          Continue with Google
        </button>
      </div>
    );
  }
}

export default App;