import React from 'react'
import Testimonials from './Testimonials'
import BestSelections from './BestSelections'
import HeroSearchBox from './HeroSearchBox'
import Personalised from './Personalised'
import PopularDestinations from './PopularDestinations'
import WhyChooseUs from './WhyChooseUs'
import OurStory from './OurStory'

function Index({data}) {
  return (
    <>
    <HeroSearchBox/>
    <BestSelections data={data}/>
    <PopularDestinations data={data}/>
    <Personalised data={data}/>
    <Testimonials data={data}/>
    <WhyChooseUs data={data}/>
    <OurStory data={data}/>
    </>
  )
}

export default Index