import React from 'react'
import Logo from "../../assets/images/viewSvg/mapMarker.svg";
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';

const App = (props) => {

    let lat = parseFloat(props.lat)
    let long = parseFloat(props.long)
    
    const Map = ReactMapboxGl({
        accessToken:
          'pk.eyJ1IjoicnVkcmFwc2luaGEiLCJhIjoiY2tmbGczYXdqMGQxNzJ5cGo2c3IzemlkZSJ9.9HUPVv8ejmZEmoPs3Q5jdg'
      });
    
    const load = (map) => {
        const worldview ="IN"
        map.setFilter('admin-0-boundary-disputed', [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', worldview], true, false]
        ]);
        // The "admin-0-boundary" layer shows all boundaries at
        // this level that are not disputed.
        map.setFilter('admin-0-boundary', [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'disputed'], 'false'],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', worldview], true, false]
        ]);
        // The "admin-0-boundary-bg" layer helps features in both
        // "admin-0-boundary" and "admin-0-boundary-disputed" stand
        // out visually.
        map.setFilter('admin-0-boundary-bg', [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', worldview], true, false]
        ]);
    }

    return (
        <div id="map" className="map_leaf">
        <Map
        onStyleLoad={load}
        center={[long,lat]}
        zoom={[11]}
        // eslint-disable-next-line
        style="mapbox://styles/rudrapsinha/ckflg66rx71ds1anrc9k7dn6i"
        containerStyle={{
            height: '400px',
            width: '100%'
        }}
        >
        <ZoomControl/>
        <Marker
        coordinates={[long,lat]}
        anchor="bottom">
        <img src={Logo} alt="logo of marker"/>
        </Marker>
        
        </Map>
           {/* <Map center={[45.4,-75.7]} zoom={12}>
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                style={this.obj}
                //url="https://api.maptiler.com/maps/57e3cad3-eab8-4282-be4a-a90a42332cc4/?key=rrT0LJ00Gl0oDWqEyRhd#7/23.272/86.809"
                //style='https://api.maptiler.com/maps/57e3cad3-eab8-4282-be4a-a90a42332cc4/style.json?key=rrT0LJ00Gl0oDWqEyRhd'
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                
            </Map>
        */}
        </div>
    )
    
}

export default App
