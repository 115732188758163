import { ArrowRightOutlined, CaretRightFilled, CaretRightOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom';

const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);
}

function BestSelections({ data, history }) {
    const [click, setClick] = useState(false);
    const handleRoute = (id) => {
        if (!click) {
         
          history.push(`/desc/${id}`);
        }
      };

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className='wrapBestSelections'>
            <div className='titleMobileWrap'>
                <div className='captionWrap'>
                    Best of our Selection
                </div>
                <div className='actionBar'>
                    <Link to={`/search?limit=10`}>
                    <button>
                        <ArrowRightOutlined />
                    </button>
                    </Link>
                </div>
            </div>
            <div className='selectionContent'>
                <div className='emptyBox'></div>
                {data !== null && (
                    data.section_2.properties.map((item, idx) => (
                        <Link key={idx} rel="noopener noreferrer" to={`/desc/${item.url}`} onClick={(e) =>  {e.preventDefault(); handleRoute(item.url);}}>
                        <div className='contentContainer'>
                            <div className='imageBox'>
                                <img src={item.featured_img[0].img_480[0].url} />
                            </div>
                            <div className='locationBox'>
                                {item.city}, {item.state}
                            </div>
                            <div className='propertyName'>
                                {item.property_title}
                            </div>
                            <div className='priceNight'>
                                {numberFormat(item.price_night)} / night
                            </div>
                        </div>
                        </Link>
                    ))

                )}
                <div className='emptyBox'></div>
            </div>
        </div>
    )

}

export default withRouter(BestSelections)