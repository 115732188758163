/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  notification,
  Skeleton,
  message,
  Input,
  Divider,
  Drawer
} from "antd";
import {
  ShareAltOutlined
} from '@ant-design/icons';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "../../assets/styles/react-dates/react-dates-override.scss";
import { DateRangePicker } from 'react-dates';
import { Link, withRouter } from "react-router-dom";
import { Tooltip } from "antd";
import { GoogleApiWrapper } from "google-maps-react";
import "@brainhubeu/react-carousel/lib/style.css";
import moment from "moment";
import axios from "axios";
// import SimpleReactLightbox from "simple-react-lightbox";
import Slider from "react-slick";
import SliderOR from "./slider.js";
import MapboxMap from "./Map.js";
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/BlackborderHeart.svg";
import Infant from "../../assets/images/viewSvg/infant_friendly.svg";
import NotInfant from "../../assets/images/viewSvg/not_infant_friendly.svg";
import Smoking from "../../assets/images/viewSvg/Smoking.svg";
import NoSmoking from "../../assets/images/viewSvg/no_smoking.svg";
import Pets from "../../assets/images/viewSvg/Pets.svg";
import NoPets from "../../assets/images/viewSvg/no_pets.svg";
import NoParty from "../../assets/images/viewSvg/no_party.svg";
import Party from "../../assets/images/viewSvg/Party.svg";
import Bed from "../../assets/images/bed.svg";
import Guest from "../../assets/images/Guests.svg";
import Place from "../../assets/images/place.svg";
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import NoNetwork from "../Home/noNetwork";
import AmenitiesIcon from "./amenitiesIcon";
import parse from "html-react-parser";
import {
  StarFilled,
  PlusOutlined,
  MinusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Reviews from "./Reviews"
import Nearby from "./Nearby";
import queryString from 'query-string';
import Main from "./Main";
import { Hidden } from "@material-ui/core";
import MobilePropertyDetails from "./MobilePropertyDetails/Index";

function PropertyView(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    //window.Tawk_API.hideWidget()

  }, []);



  const [network, setNetwork] = useState(true);
  const [show, setShow] = useState(false);
  const [amentities_show, setamentities_show] = useState(false);
  const [home, setHome] = useState({});
  const [reviews, setReviews] = useState(null);
  const [price, setPrice] = useState();
  const [disable, setDisable] = useState(true);
  const [similarProprties, setSimilarProperties] = useState({});
  const [favourites, setFavourites] = useState();
  const [visible, setVisible] = useState(false);
  const [coupon, setCoupon] = useState("");

  const [open, setOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedInput2, setFocusedInput2] = useState(null);
  

  const {
    state,
    property_id,
  } = props.match.params;
  const [listing_id, setListingId] = useState(null);
  const [guests, setGuests] = useState({
    adults:
      props.match.params.adults === "0"
        ? 1
        : props.match.params.adults
          ? parseInt(props.match.params.adults)
          : 1,
    children: props.match.params.children
      ? parseInt(props.match.params.children)
      : 0,
  });

  const [checkin, setCheckin] = useState(
    props.match.params.check_in ? props.match.params.check_in : ""
  );

  const [checkout, setCheckout] = useState(
    props.match.params.check_out ? props.match.params.check_out : ""
  );

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };



  /* let d = [
     {
         start: moment("1.01.1900 00:00", format),
         end: yesterday
       }
   ];
   */

  let d = [];

  const [disabledDates, setdisabledDates] = useState(d);

  useEffect(() => {

  }, [disabledDates])





  useEffect(() => {
    let paramsFromGoogle = Object.fromEntries(new URLSearchParams(props.location.search))
    if (Object.keys(paramsFromGoogle).length >= 3) {
    
      if (paramsFromGoogle.df && paramsFromGoogle.dt && paramsFromGoogle.noa && paramsFromGoogle.nok) {
        let df = moment(paramsFromGoogle.df, "DD/MM/YYYY").format("YYYY-MM-DD")
        let dt = moment(paramsFromGoogle.dt, "DD/MM/YYYY").format("YYYY-MM-DD")
        let noa = parseInt(paramsFromGoogle.noa)
        let nok = parseInt(paramsFromGoogle.nok)
        console.log(df, dt, noa, nok)
        setCheckin(df)
        setCheckout(dt)
        setGuests({
          adults: noa,
          children: nok
        })
      }
    }
    const token = sessionStorage.getItem("token");

    window.scrollTo(0, 0);

    if (token) {
      fetchFavourites();
    }

    fetchView();
    //console.log(queryString.parse(props.location.search).referal,"params")
    /* fetchReviews();
     fetchPrice();
     FetchSimilarProperties();
     fetchBlockDates();
     get_blocked_dates(); */

    //eslint-disable-next-line
  }, [property_id]);

  useEffect(() => {
    if (listing_id !== null) {
      fetchReviews();
      fetchPrice();
      FetchSimilarProperties();
      get_blocked_dates();
    }
    //eslint-disable-next-line
  }, [listing_id])



  const get_blocked_dates = async () => {
    let cur = moment().format("MM,YYYY")
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {


      for (let i = 1; i < 7; i++) {
        let m = cur.split(',');

        const res1 = await axios.get(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/get_blocks/${listing_id}/${m[0]}/${m[1]}`,
          config
        );

        const data1 = await res1.data;
        const new_ = await data1.map(e => (
          {
            start: moment(e.ota_date_from),
            end: moment(e.ota_date_to)
          }
        ))

        d.push(...new_)
        setdisabledDates(prev => [...prev, ...new_])
        cur = moment().add(i, "months").format("MM,YYYY")


      }


      // setReviews(data);
      //d.push(...new_)

    } catch (error) {

    }
  }

  async function fetchView() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/view_property/${property_id}`,
        config
      );
      const data = await res.data;
      setHome(data);
      setListingId(data.listing_id);

    } catch (error) {

      setNetwork(false);
    }

  }

  async function fetchReviews() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/reviews/all/${listing_id}`,
        config
      );
      const data = await res.data;
      setReviews(data);

    } catch (error) {

    }
  }



  async function FetchSimilarProperties() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/suggestions/location/${listing_id}`,
        config
      );
      const data = await res.data;
      setSimilarProperties(data);

    } catch (error) {

    }
  }

  async function fetchPrice() {

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }

    if (checkin !== "" && checkout !== "") {
      const postData = {
        properties: [
          {
            property_id: listing_id,
            start_date: moment(checkin).format("YYYY-MM-DD"),
            end_date: moment(checkout).format("YYYY-MM-DD"),
            coupon_code: coupon,
            guests: guests.adults,
            children: "",
          },
        ],
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
          postData,
          config
        );
        const data = await res.data;
        if (data.status === 200) {

          setPrice(data.bifurcated[0]);
          setDisable(false);
        }
        setPrice(data.bifurcated[0]);
        setDisable(false);

      } catch (error) {
        if (error.response) {
          setDisable(true)
          message.info(error.response.data.remarks);
        }
      }

    }

  }


  useEffect(() => {
    if (price !== undefined) {
      setHome({ ...home, price_night: price.per_night_average })
    }
    //eslint-disable-next-line
  }, [price])
  const shareurl = window.location.href;
  async function fetchFavourites() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/favourites/${listing_id}`,
        config
      );

      const data = await res.data;
      if (res.status === 200) {
        setFavourites(data.favourite);
      }

    } catch (error) {

    }
  }

  const handleFavorite = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
        { listing_id },
        config
      );

      const data = await res.data;

      if (data.statues === 200) {
        message.success(data.remarks);
      }
      fetchFavourites();
    } catch (error) {

      message.info("Please Login to add this property to favourites!");
    }
  };

  const handleAdult = (e, val) => {
    if (e === 0) {
      setGuests({ ...guests, adults: val - 1 });
    }
    if (e === 1) {
      setGuests({ ...guests, adults: val + 1 });
    }
  };

  const click = () => {
    const format = "DD.MM.YYYY HH:mm";
    const yesterday = moment().subtract(1, "days").utcOffset(330).format(format)
    const last_check_in = moment().add(365, "days").utcOffset(330).format(format)

    if (!moment(checkin).isValid() || !moment(checkout).isValid() || new Date(checkin) < moment(yesterday, format).toDate() || new Date(checkout) < Date.now() || new Date(checkin) > moment(last_check_in, format).toDate() || new Date(checkout) > moment(last_check_in, format).toDate()) {
      openNotificationWithIcon("error", "Dates are not valid.")
      return
    }

    ReactGA.event({
      "category": "Trying_to_Book",
      "action": JSON.stringify({ "type": "booking_init", "payload": { "property_id": listing_id } }),
    })

    props.history.push(
      `/property-details/${listing_id}/${state}/${guests.adults}/${guests.children
      }/${moment(checkin).format()}/${moment(checkout).format()}/payments${queryString.parse(props.location.search).referal ? "?referal=" + queryString.parse(props.location.search).referal : ""}`
    );
  };

  const clickPropertySimilar = (id) => {
    props.history.push(`/desc/${id}`);

  };

  useEffect(() => {
    if (listing_id !== null) {
      if (checkin !== null && checkout !== null) {
        fetchPrice()
      }
    }
    //eslint-disable-next-line
  }, [checkin, checkout, guests])

  const time = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ];

  const settings = {
    className: "center",
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };


  const parsedText = parse(home.property_desc ? home.property_desc : "");

  const [visible_drawer, setVisibile_drawer] = useState(false);

  const onCloseDrawer = () => {
    setVisibile_drawer(!visible_drawer)
  }

  const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  }


  return (
    <div>
      <Helmet>
        <title>{home.seo_title}</title>
        <meta name="title" content={home.seo_title} />
        <meta
          name="description"
          content="Roamhome is a rental portal that provides curated homes with professional service. Our properties are spread across budgets without compromising our service."
        />
      </Helmet>
      {network ? (
        <>
          {/** Smaller devices */}
          <Hidden smUp>
            <div className="mobileSegment">
              {home.seo_title &&
              
              <MobilePropertyDetails data={home} checkin={checkin} checkout={checkout} guests={guests} disabledDates={disabledDates}/>
            }
            </div>
          </Hidden>
          {/** Hide for smaller devices */}
          <Hidden xsDown>
            <Main />
          </Hidden>
        </>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}

const PropertyDetails = withRouter(PropertyView);

const LoadingContainer = (props) => (
  <div className="container">
    <Skeleton active />
    <Skeleton active />
  </div>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyBXZkEWMQhUxa6GXd-t5ZF-N_axqOdrjAE",
  LoadingContainer: LoadingContainer,
})(PropertyDetails);
