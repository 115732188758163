import React, { useEffect } from "react";
import Layout from "./HOC/layout";
import Routes from "./routes";
import "./assets/styles/style.scss";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
// setup trackingID of Google Analytics
const trackingID = "UA-192915721-1"
const measurementId = "G-TZ1VCZ8SRK"
ReactGA.initialize(measurementId);


function App() {
  
  return (
   
    <div>
      <Router>
        <Layout>
          <Routes />{" "}
        </Layout>
      </Router>
    </div>
    
  );
}

export default App;
