import React from 'react'
import Slider from './Slider'
import {withRouter} from 'react-router-dom'
import Description from './Description'
import AmenitiesAndMore from './AmenitiesAndMore'
import Rules from './Rules'
import AdditionalRules from './AdditionalRules'
import Location from './Location'
import Nearby from './Nearby'
import SimilarHomes from './SimilarHomes'
import BookingBox from './BookingBox'

function Index({data, checkin, checkout, guests, disabledDates}) {
  return (
    <>
    <div className='containerMobile'>
        <Slider data={data}/>
        <Description data={data}/>
        <AmenitiesAndMore data={data}/>
        <Rules data={data}/>
        <AdditionalRules data={data}/>
        <Location data={data}/>
        <Nearby data={data}/>
        <SimilarHomes data={data}/>
    </div>
    <BookingBox data={data} checkin={checkin} checkout={checkout} guests={guests} disabledDates={disabledDates}/>
    </>
  )
}

export default withRouter(Index)