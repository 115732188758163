import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  DatePicker,
  Button,
  Select,
  Input,
  Checkbox,
  notification,
  Skeleton,
  Modal,
} from "antd";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import BedRooms from "../../assets/images/bed.svg";
import BathRooms from "../../assets/images/place.svg";
import BathTab from "../../assets/images/bathTap.svg";
import Logo from "../../assets/images/white_logo.svg";
import { setPaymentSession } from "../Utils/session";
import { bookingStatus } from "../Utils/session";
import io from "socket.io-client";
import queryString from 'query-string'
import Group54 from '../../assets/images/Group54.svg'
import Tree1 from '../../assets/images/tree1.svg'
import Frame47 from '../../assets/images/Frame47.svg'
import { CloseOutlined } from "@ant-design/icons";

function Payments(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [tokenApi, setTokenApi] = useState(null);
  const [price, setPrice] = useState();
  const [climateTax, setClimateTax] = useState(0);
  const [knowMoreClimate, setKnowMoreClimate] = useState(false)
  const [home, setHome] = useState({});
  const [loading, setloading] = useState(false);
  const [amount, setAmount] = useState("");
  const email = sessionStorage.getItem("email");
  const [name, setName] = useState();
  const [email_, setEmail] = useState(email);
  const [socket, setSocket] = useState();
  useEffect(() => {
    const sock = io("https://api.roamhome.in:5555/", { transports: ['websocket', 'polling', 'flashsocket'] })
    setSocket(sock)
    return () => {
      sock.close()
    }
  }, [])

  const {
    adults,
    children,
    check_in,
    check_out,
    property_id,
  } = props.match.params;

  const [check, setCheck] = useState(false);
  const [contributeClimate, setContributeClimate] = useState(false);
  const [gst, setGst] = useState(false);
  const [data, setData] = useState({
    billed_to: "",
    billed_to_email: "",
    phone: "",
    need_invoice: gst ? true : false,
    travelling_for_work: false,
    message_to_host: "",
    gst_number_invoice: "",
    gst_company_name: "",
    gst_company_address: "",
    gst_company_pin: "",
    gst_company_state: "",
    pay_for_climate: false,
    coupon_applied: "",
    properties: [
      {
        date_from: moment(check_in).format("YYYY-MM-DD"),
        date_to: moment(check_out).format("YYYY-MM-DD"),
        num_guests: adults,
        children: children,
        property_id: property_id,
      },
    ],
  });

  async function fetchView() {
    setloading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/view_property/${property_id}`,
        config
      );
      const data = await res.data;
      setHome(data);

    } catch (error) {

    }
    setloading(false);
  }

  const fetchUserData = async () => {
    if (sessionStorage.getItem("token")) {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "token": sessionStorage.getItem("token")
        },
      };
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/user/info`,
          config
        );
        const respo = await res.data;
        setName(respo.name);
        setData({ ...data, billed_to: respo.name, billed_to_email: email_ })
      } catch (error) {

      }
    }
  }

  const checkTrueCost = async () => {
    const data = {
      "checkIn": moment(check_in).format("YYYY-MM-DD"),
      "checkOut": moment(check_out).format("YYYY-MM-DD"),
      "propertyId": property_id,
      "numGuests": adults
    }

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_ORIGIN}api/v1/public/thirdparty/getTrueCost`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios(config);
      if (response.data.status === 200) {
        setClimateTax(response.data.response.truecost)
      }
    } catch (e) {
      console.log(e.message)
    }

  }


  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if(token) {
      setTokenApi(token)
    }
    fetchUserData();
    fetchPrice();
    fetchView();
    checkTrueCost()
    // queryString.parse(props.location.search).referal && setData({ ...data, message_to_host: queryString.parse(props.location.search).referal })
    //eslint-disable-next-line  
  }, []);

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  async function fetchPrice(val) {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        ...(token) && {"token": token},
      },
    };
    if (!val) {
      const postData = {
        properties: [
          {
            property_id,
            start_date: check_in ? moment(check_in).format("YYYY-MM-DD") : moment(addDays(Date.now(), 1)).format("YYYY-MM-DD"),
            end_date: check_out ? moment(check_out).format("YYYY-MM-DD") : moment(addDays(Date.now(), 2)).format("YYYY-MM-DD"),
            coupon_code: "",
            guests: guest.adults,
            children: props.match.params.children ? parseInt(children) : "",
          },
        ],
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
          postData,
          config
        );
        const data = await res.data;
        setPrice(data.bifurcated[0]);
        setAmount(data.grand_total);
      } catch (error) {
      }
    } else {
      const postData = {
        properties: [
          {
            property_id,
            start_date: moment(checkin).format("YYYY-MM-DD"),
            end_date: moment(checkout).format("YYYY-MM-DD"),
            coupon_code: data.coupon_applied,
            guests: guest.adults,
            children: guest.children,
          },
        ],
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
          postData,
          config
        );
        const data = await res.data;
        setPrice(data.bifurcated[0]);

      } catch (error) {

      }

    }
  }


  const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  }


  const [checkin, setCheckin] = useState(
    props.match.params.check_in ? props.match.params.check_in : ""
  );

  const [checkout, setCheckout] = useState(
    props.match.params.check_out ? props.match.params.check_out : ""
  );

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  const { Option } = Select;
  //eslint-disable-next-line
  const [guest, setGuests] = useState({
    adults: props.match.params.adults ? parseInt(props.match.params.adults) : 1,
    children: props.match.params.children
      ? parseInt(props.match.params.children)
      : 0,
  });

  const handleBooking = async () => {
    setloading(true);

    const token = sessionStorage.getItem("token");
    let checker = true;




    if (data.billed_to === "") {
      if (name === "") {
        checker = false
        openNotificationWithIcon("error", "Name field cannot be empty.");
      } else {
        setData({ ...data, billed_to: name })
      }
    }

    if (data.billed_to_email === "") {
      if (email_ === "") {
        checker = false
        openNotificationWithIcon("error", "Email field cannot be empty.");
      } else {
        setData({ ...data, billed_to_email: email_ })
      }
    }

    if (data.phone === "") {
      checker = false
      openNotificationWithIcon("error", "Phone field cannot be empty.");
    } else {
      var regx = /^[1-9]\d{9}$/;
      if (!regx.test(data.phone)) {
        checker = false
        openNotificationWithIcon("error", "Phone number is invalid.");
      }
    }


    const deVal = queryString.parse(props.location.search).referal ? { ...data, message_to_host: queryString.parse(props.location.search).referal } : null
    const dataObj = deVal === null ? { ...data, pay_for_climate: contributeClimate } : { ...deVal, pay_for_climate: contributeClimate }




    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token,
      },
    };
    if (token && check && checker) {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/init`,
          dataObj,
          config
        );
        const resdata = await res.data;

        if (resdata.status === 200) {
          let razorPay = "";
          if (resdata.response) {
            razorPay = {
              amount: resdata.response.amount,
              order_id: resdata.response.order_id,
              name: resdata.author,
              email: resdata.email,
              currency: resdata.response.currency,
            };
            handleRzp(razorPay);
          } else {

            if (socket !== null) {
              socket.emit("newNotification", { "type": "booking_request", data })
            }


            const paymentDetailsForSession = {
              adults,
              check_in,
              check_out,
              property_id,
              amount: amount * 100,
            };
            setPaymentSession(paymentDetailsForSession);
            bookingStatus(false);
            openNotificationWithIcon("success", resdata.remarks);
            props.history.push("/thank-you");
          }
        } else {
          openNotificationWithIcon("error", resdata.remarks);
        }
      } catch (error) {

      }
    } else {
      openNotificationWithIcon(
        "info",
        `${!token ? "Please Login to proceed Next!" : ""} ${!check ? "Please agree to our Terms and Conditions!" : ""
        } ${!checker ? "Some required fields are empty." : ""
        }`
      );
    }

    setloading(false);
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const handleRzp = (rzp) => {
    const paymentDetailsForSession = {
      adults,
      check_in,
      check_out,
      property_id,
      amount: rzp.amount,
    };
    setPaymentSession(paymentDetailsForSession);
    bookingStatus(true);
    var options = {
      key: "rzp_live_OPRLWprpiyvPTS",
      amount: rzp.amount,
      currency: rzp.currency,
      name: "RoamHome",
      description: "Homes as unique as you.",
      image: <Logo />,
      order_id: rzp.order_id,
      handler: async function (response) {
        const rzpdata = await response;
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        };
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/check_payment`,
            rzpdata,
            config
          );
          const resdata = await res.data;
          if (resdata.status === 200) {
            if (socket !== null) {
              socket.emit("newNotification", { "type": "booking", data })
            }
            openNotificationWithIcon("success", "Payment Successfull");
            props.history.push("/thank-you");
          }

        } catch (error) {

        }
      },
      prefill: {
        name: rzp.name,
        email: rzp.email,
      },
      theme: {
        color: "#F37254",
      },
    };

    var rzp1 = new window.Razorpay(options);

    rzp1.open();

  };

  function onChangeGst(e) {
    setGst(e.target.checked);
  }
  const onChangeCheck = (e) => {
    setCheck(e.target.checked);
  };

  function onChange(value, dateString) {
    setCheckin(dateString[0]);
    setCheckout(dateString[1]);
  }

  return (
    <div className="container">
      {knowMoreClimate && (
        <div className="modalClimes">
          <div className="modalOverlay" onClick={() => {
            setKnowMoreClimate(false)
          }}>

          </div>
          <div className="modalContent">
            <div className="modalHeadClimes">
              <div className="closeBtnMdl" onClick={() => {
                setKnowMoreClimate(false)
              }}>
                <CloseOutlined />
              </div>
              <div className="modalHeadBold">
                You opted for a Climate conscious stay!
              </div>
              <div className="modalCol">
                <Row>
                  <Col xs={3}>
                    <div className="modalIcon">
                      <img src={Group54} alt="carbon" />
                    </div>
                  </Col>
                  <Col xs={21}>
                    <div className="modalLineContent">
                      This small change can help neutralize <strong>8,000-10,000 kgs</strong> of carbon emissions in just one month.
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modalCol">
                <Row justify="end">

                  <Col xs={19}>
                    <div className="modalLineContent">
                      Which is equivalent to <strong>150 seedlings</strong> grown for the next 10 years!
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="modalIcon">
                      <img src={Tree1} alt="carbon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modalExp">
              <div className="amreExp">
                <Row style={{ gap: "15px", alignItems: "center" }}>
                  <Col xs={8}>
                    <div className="modalIcon">
                      <img src={Frame47} alt="carbon" />
                    </div>
                  </Col>
                  <Col xs={14}>
                    <div className="amreExpContent">
                      <p>Carbon emissions are calculated as per your location, size of the property, power & water consumption, etc. </p>
                      <p className="highlightSmall">*By opting in you allow Roamhome to share your order details with Climes and allow Climes to contact you with updates on your purchase.</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="contribution">
                <p>
                  Your contribution {contributeClimate ? `of ${numberFormat(climateTax)}` : ""} will<br />neutralise the carbon emissions of your stay
                </p>
              </div>
            </div>
            <div className="howItWorksExp">
              <div className="howCap">
                How it works
              </div>
              <div className="howCap">
                You will collect climes (credits) to transparently:
              </div>
              <Row>
                <Col>
                  <div className="howCap">
                    Choose
                  </div>
                  <div className="defineCap">
                    Choose a project you love from our portfolio of avoided emissions and removed emission projects.

                  </div>


                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <div className="howCap">
                    Allocate
                  </div>
                  <div className="defineCap">
                    Your Climes will be added to your wallet - and you can allocate them to a project via the Climes platform
                  </div>


                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <div className="howCap">
                    Get rewarded
                  </div>
                  <div className="defineCap">
                    Get access to exclusive vouchers and rewards for being Climate concious.
                  </div>


                </Col>
                <Col></Col>
              </Row>
            </div>
          </div>
        </div>
      )}
      <Row>
        <Col xs={24} lg={15}>
          <div className="payment_detail">
            <Row>
              <Col lg={24} xs={24}>
                <h1 className="details_head">Enter Contact Details</h1>
              </Col>
            </Row>
            <Row>
              <Col lg={15} xs={22}>
                <div className="pay_detail_input">
                  <label>Full Name <span class="required">*</span></label>
                  <Input
                    size="large"
                    key={name ? 'notLoadedYet' : 'loaded'}
                    defaultValue={name ? name : ''}
                    placeholder="Name"
                    onChange={(e) => {
                      setName(e.target.value)
                      setData({ ...data, billed_to: e.target.value })
                    }
                    }
                  />
                </div>
              </Col>
              <Col lg={15} xs={22}>
                <div className="pay_detail_input">
                  <label>Email <span class="required">*</span></label>
                  <Input
                    size="large"
                    defaultValue={email}
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setData({ ...data, billed_to_email: e.target.value })
                    }
                    }
                  />
                </div>
              </Col>
              <Col lg={15} xs={22}>
                <div className="pay_detail_input">
                  <label>Mobile Number <span class="required">*</span></label>
                  <Input
                    size="large"
                    placeholder="Mobile No."
                    onChange={(e) => {
                      const { value } = e.target;
                      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
                      if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                        setData({ ...data, phone: value })
                      }

                    }
                    }
                  />
                </div>
              </Col>
              <Col lg={15} xs={22}>
                <div className="pay_detail_input">
                  <label>Message</label>
                  {queryString.parse(props.location.search).referal ? (
                    <Input.TextArea
                      size="large"
                      placeholder="Message To Host"
                      disabled="true"
                      defaultValue={queryString.parse(props.location.search).referal}
                    />
                  ) : (

                    <Input.TextArea
                      size="large"
                      placeholder="Message To Host"
                      onChange={(e) =>
                        setData({ ...data, message_to_host: e.target.value })
                      }
                    />
                  )}
                </div>
              </Col>
              <Col lg={15} xs={22} style={{ marginBottom: "1rem" }}>
                <Checkbox onChange={onChangeGst}>Have GST Number </Checkbox>
              </Col>
              {gst ? (
                <div style={{ width: "100%" }}>
                  <Col lg={15} xs={22}>
                    <div className="pay_detail_input">
                      <label>Company Name</label>
                      <Input
                        placeholder="Company Name"
                        onChange={(e) =>
                          setData({
                            ...data,
                            gst_company_name: e.target.value,
                          })
                        }
                        size="large"
                      />
                    </div>
                  </Col>

                  <Col lg={15} xs={22}>
                    <div className="pay_detail_input">
                      <label>Address</label>
                      <Input.TextArea
                        placeholder="Address"
                        onChange={(e) =>
                          setData({
                            ...data,
                            gst_company_address: e.target.value,
                          })
                        }
                        size="large"
                      />{" "}
                    </div>
                  </Col>

                  <Col lg={15} xs={22}>
                    <div className="pay_detail_input">
                      <label>GSTIN</label>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="GSTIN"
                        size="large"
                        onChange={(e) =>
                          setData({
                            ...data,
                            gst_number_invoice: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={15} xs={22}>
                    <label>State</label>
                    <div className="pay_detail_input">
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="State"
                        onChange={(e) =>
                          setData({ ...data, gst_company_state: e })
                        }
                      >
                        <Option value="Goa">Goa</Option>
                        <Option value="Maharashtra">Maharashta</Option>
                        <Option value="Delhi">Delhi</Option>
                        <Option value="Rajasthan">Rajasthan</Option>
                        <Option value="Kerala">Kerala</Option>
                      </Select>
                    </div>
                  </Col>

                  <Col lg={15} xs={22}>
                    <div className="pay_detail_input">
                      <label>Pincode</label>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Pincode"
                        size="large"
                        onChange={(e) =>
                          setData({ ...data, gst_company_pin: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                </div>
              ) : (
                ""
              )}

              <Col lg={15} xs={22}>
                <div className="pay_detail_input">
                  <Checkbox
                    onChange={(e) =>
                      setData({
                        ...data,
                        travelling_for_work: e.target.checked,
                      })
                    }
                  >
                    Travelling For Work
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <Card
            style={{
              boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
            }}
            className="img_card"
            cover={
              !home.featured_img ? (
                <Skeleton active />
              ) : (
                <div>
                  {home.featured_img[0].img_960.map((x) => (
                    <div>
                      <img src={x.url} alt="feature" />
                    </div>
                  ))}
                </div>
              )
            }
          >
            <div className="payment-card">
              <div className="icons_payment">
                <Row justify="space-between">
                  <Col>
                    {" "}
                    <div className="card_img_icon">
                      <img src={BathRooms} alt="bed" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.type_of_listing}
                    </p>
                  </Col>
                  <Col>
                    <div className="card_img_icon">
                      <img src={BedRooms} alt="Bedrooms" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.num_of_bedroom} Bedrooms
                    </p>
                  </Col>
                  <Col>
                    <div className="card_img_icon">
                      <img src={BathTab} alt="bathTub" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.num_of_bath} Bathrooms
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="checkin-details">
                <Col lg={24}>
                  <div className="date_payment">
                    <label style={{ display: "flex", marginTop: "5px" }}>
                      Check-In - Check-Out
                    </label>
                    <RangePicker
                      disabled
                      defaultValue={
                        check_in
                          ? [
                            moment(check_in, dateFormat),
                            moment(check_out, dateFormat),
                          ]
                          : ""
                      }
                      format={dateFormat}
                      size="large"
                      onChange={onChange}
                    />
                  </div>
                </Col>
              </div>

              <div>
                <Col lg={24}>
                  <div className="guest-count">
                    <p> {guest.adults} Guests </p>
                  </div>
                </Col>
              </div>

              <div className="total">
                {contributeClimate && (

                  <Row justify="space-between">
                    <div className="contributeBloc">
                      Added {numberFormat(climateTax)} to contribute for carbon neutralisation.
                    </div>
                  </Row>
                )}
                <Row justify="space-between">
                  <div>
                    <h4>Total</h4>
                  </div>
                  <div className="d-flex">
                    <h4>
                      {!price ? "" : numberFormat(price.grand_total + (contributeClimate ? climateTax : 0))}
                    </h4>
                  </div>
                </Row>
                <Col>
                  <div>
                    <p style={{ fontSize: "12px" }}>Includes taxes and fee</p>
                  </div>
                </Col>
              </div>


              <div class="climate_con rule_check">

                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <Col><Checkbox checked={contributeClimate} onChange={() => {
                    setContributeClimate(!contributeClimate)
                  }} /></Col>
                  <Col xs={20}><p style={{ textAlign: "left", marginLeft: "0" }} className="rule_condition">Carbon neutralise this stay for {numberFormat(climateTax)}. <span style={{ color: "#8940c9", fontSize: "10px" }}>(Optional)</span></p>
                    <p style={{ textAlign: "left", marginLeft: "0", fontSize: "12px" }}>Neutralise carbon emissions for your booking with <span style={{ color: "#8940c9", fontWeight: "700" }}>CLIMES</span></p>
                    <p onClick={() => {
                      window.scrollTo(0, 0)
                      setKnowMoreClimate(true)
                    }} style={{ fontSize: "10px", textAlign: "left", color: "#8940c9", cursor: "pointer" }}>FIND OUT HOW IT WORKS</p>
                  </Col>
                </Row>

              </div>

              <div className="rule_check">
                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <Col><Checkbox onChange={onChangeCheck} /></Col>
                  <Col xs={20}><p style={{ textAlign: "left", marginLeft: "0" }} className="rule_condition">I have read and agreed to the <Link to="/terms">Terms and Conditions</Link>.</p></Col>
                </Row>
              </div>

              <div>
                {home.instant_book ? (
                  <div className="pay-wrapper">
                    <Button
                      loading={loading}
                      className="btn-primary payment-btn"
                      onClick={() => handleBooking()}
                    >
                      {loading ? "Loading..." : "Confirm Booking"}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="pay-wrapper">
                      <Button
                        className="btn-primary payment-btn"
                        onClick={() => handleBooking()}
                        loading={loading}
                      >
                        {loading ? "Loading..." : "Request to Book"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={8}></Col>
      </Row>
    </div>
  );
}
export default withRouter(Payments);
