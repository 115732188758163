import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Typography,
  Button,
  Checkbox,
  Divider,
  Modal,
  AutoComplete,
  message
} from "antd";
import { Select as Select1 } from "antd";
import FilterIcon from "../../assets/images/filter.svg";
import { useHistory } from "react-router-dom";

const { Option } = Select1;

const { Title, Text } = Typography;

export default function Filter(props) {
  const history = useHistory();
  const [property, setProperty] = useState([]);

  const [PID, setPID] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modal, setModal] = useState({
    registerVisible: false,
  });

  //Modal State
  const showRegisterModal = () => {
    setModal({
      registerVisible: true,
    });
  };

  const handleRegisterCancel = (e) => {
   
    setModal({
      registerVisible: false,
    });
  };

  const [amenities, setAmenities] = useState([]);
  const [new_amenities, set_new_amenities] = useState([]);
  const [new_roomtype, set_new_roomtype] = useState([]);

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token,
      },
    };
    try {
      const resAmmen = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/private/listing/amenities`,
        config
      );

      const dataAmmen = await resAmmen.data;

      setAmenities(dataAmmen.amenities);
    } catch (err) {
      
    }
  };

  const onChangeProperty = async (name) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/search/${name}`
      );
      const data = await res.data;
     
      setProperty(data);
    } catch (err) {
     
    }

    const getId = property.filter((x) => x.property_title === name);
    const id = getId.length > 0 ? getId[0].listing_id : "";
    setPID(id);
    // setForm({ property_id: id, property_name: name });
  };

  const onFinishTest = async (values) => {
    const { state, adults, children, check_in, check_out } = props.data;

    const dataTo = {
      country: "India",
      state: state ? state : "",
      no_bedrooms: values.no_bedrooms ? values.no_bedrooms : "",
      guests: adults || children ? parseInt(adults) + parseInt(children) : 0,
      children: "",
      amenities: new_amenities ? new_amenities[0] : "",
      price_range: {
        start_price: values.start_price ? values.start_price : "",
        end_price: values.end_price ? values.end_price : "",
      },
      property_type: values.property_type ? values.property_type : "",
      room_type: new_roomtype ? new_roomtype[0] : "",
      pets: values.pets ? values.pets : false,
      check_in: check_in ? check_in : moment(Date.now()).format(),
      check_out: check_out ? check_out : moment(Date.now()).format(),
      limit: 20,
      skip: 0,
    };

    props.getFilterData(dataTo);
    setModal({ visible: false });
  }

  const onChangeAmenities = async (values) => {
  
    set_new_amenities([values]);
  }
  
  const onChangeRoomType = async (values) => {
  
    set_new_roomtype([values]);
  }


  const onFinishFailed = (errorInfo) => {
   
  };

  const prop_type_vals = [
    "Apartment",
    "Bed & Breakfast",
    "Boutique Room",
    "Cabin",
    "Castle / Fortress / Palace",
    "Cottage",
    "Farmhouse",
    "Hotel",
    "House",
    "Island",
    "Lodge",
    "Studio",
    "Tent",
    "Villa",
    "Tree House",
  ];


  const routeChange = () =>{ 
    let path = `/desc/${PID}`; 
    history.push(path);
  }

  return (
    <div>
      <Button
        className="filter_btn"
        size="large"
        type="default"
        icon={
          <img
            height="20px"
            style={{ marginRight: "2px" }}
            src={FilterIcon}
            alt="filter"
          />
        }
        onClick={showRegisterModal}
      >
        Filter
      </Button>
      <Modal
        visible={modal.registerVisible}
        className="filter_modal"
        style={{ top: 10 }}
        footer={null}
        onCancel={handleRegisterCancel}
      >
        <Card
          className="modal-body"
          title={
            <Title level={2} style={{ textAlign: "center" }}>
              <Text strong>Filter</Text>
            </Title>
          }
        >
          <Form onFinish={onFinishTest} onFinishFailed={onFinishFailed}>
            <Row className="filter-section">
              <Col xs={24} lg={6} className="left ">
                <h3>Price</h3>

                <div className="price-diff">
                  <Form.Item name="start_price">
                    <Input placeholder="Start" />
                  </Form.Item>
                  <b> - </b>
                  <Form.Item name="end_price">
                    <Input placeholder="End" />
                  </Form.Item>
                </div>

                <h3>Bedrooms</h3>
                <Form.Item name="no_bedrooms">
                  <Select1
                    style={{ width: "100%" }}
                    placeholder="Number of bedrooms"
                  >
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                    <Option value={6}>6</Option>
                    <Option value={7}>7</Option>
                    <Option value={8}>8</Option>
                    <Option value={9}>9</Option>
                    <Option value={10}>10</Option>
                  </Select1>
                </Form.Item>
                <Form.Item name="pets" valuePropName="checked">
                  <Checkbox>Pets</Checkbox>
                </Form.Item>
                <hr></hr>
                <h3>Search by Property Name</h3>
                <Row>
                    <Col style={{width: "100%",
                        "margin-bottom": "11px"}}>
                        <AutoComplete
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Search for a Property"
                        onChange={onChangeProperty}
                        filterOption={(inputValue, option) =>
                            option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        >
                        {property.map((x, i) => {
                            return (
                            <Option key={i} value={x.property_title}>
                                {x.property_title}
                            </Option>
                            );
                        })}
                        </AutoComplete>
                    </Col>
                    <br/>
                    <Col>
                        <div className="hero-search">
                        <Button
                            size="large"
                            // disabled={PID ? false : true}
                            onClick={() => {
                            PID
                                ? routeChange()
                                : message.info("Type Property name! ");
                            }}
                            className="btn-primary"
                        >
                            Search
                        </Button>
                        </div>
                    </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12} className="middle">
                <h3>Amenities</h3>
                <div className="amenities-section">
                <Checkbox.Group onChange={onChangeAmenities}>
                  <div className="left-col">
                    {amenities.slice(0, amenities.length / 2).map((am, i) => (
                      <Form.Item key={i} name={am.name} valuePropName="checked">
                        <Checkbox value={am.name}>
                          <p className="name"> {am.name} </p>
                        </Checkbox>
                      </Form.Item>
                    ))}
                  </div>
                  <div className="right-col">
                    {amenities
                      .slice(amenities.length / 2, amenities.length)
                      .map((am, i) => (
                        <Form.Item
                          key={i}
                          name={am.name}
                          valuePropName="checked"
                        >
                          <Checkbox value={am.name}>
                            <p className="name"> {am.name} </p>
                          </Checkbox>
                        </Form.Item>
                      ))}
                  </div>
                  </Checkbox.Group>
                </div>
              </Col>
              <Col xs={24} lg={6} className="right">
                <h3>Room Type</h3>
                <div className="roomType-section">
                  <div className="left-col">
                  <Checkbox.Group onChange={onChangeRoomType}>
                    {prop_type_vals.map((am, i) => (
                      <Form.Item key={i} name={am} valuePropName="checked">
                        <Checkbox value={am}>
                          <span className="name"> {am} </span>
                        </Checkbox>
                      </Form.Item>
                    ))}
                    </Checkbox.Group>
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row justify="end">
            <Form.Item>
              <Button style={{ margin: " 0px 10px" }} className="btn-primary" type="primary" htmlType="submit">
                Apply
              </Button>
            </Form.Item>
            </Row>
          </Form>
         {/* <Row justify="end">
            <Button
              onClick={() => handleRegisterCancel()}
              style={{ margin: " 0px 10px" }}
            >
              Cancel
            </Button>
            <Button
              className="btn-primary"
              onClick={onFinishFilter}
              style={{ margin: " 0px 10px" }}
            >
              Apply
            </Button>
                    </Row> */}
          {/* <Row>
              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="no_bedrooms">
                  <Select1
                    style={{ width: "100%" }}
                    placeholder="Number of bedrooms"
                  >
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                    <Option value={6}>6</Option>
                    <Option value={7}>7</Option>
                    <Option value={8}>8</Option>
                  </Select1>
                </Form.Item>
              </Col>

              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="amenities">
                  <Select1
                    placeholder="Ammenities"
                    style={{ width: "100%" }}
                    mode="multiple"
                  >
                    {amenities.map((am) => (
                      <Option value={am.name}>{am.name}</Option>
                    ))}
                  </Select1>
                </Form.Item>
              </Col>

              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="start_price">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Start Price"
                  />
                </Form.Item>
              </Col>
              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="end_price">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="End Price"
                  />
                </Form.Item>
              </Col>
              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="room_type">
                  <Select1 mode="multiple" placeholder="Room Type">
                    <Select1.Option value="Apartment">Apartment</Select1.Option>
                    <Select1.Option value="Bed & Breakfast">
                      Bed & Breakfast
                    </Select1.Option>
                    <Select1.Option value="Boutique Room">
                      Boutique Room
                    </Select1.Option>
                    <Select1.Option value="Cabin">Cabin</Select1.Option>
                    <Select1.Option value="Castle / Fortress / Palace">
                      Castle / Fortress / Palace
                    </Select1.Option>
                    <Select1.Option value="Cottage">Cottage</Select1.Option>
                    <Select1.Option value="Farmhouse">Farmhouse</Select1.Option>
                    <Select1.Option value="Hotel">Hotel</Select1.Option>
                    <Select1.Option value="House">House</Select1.Option>
                    <Select1.Option value="Island">Island</Select1.Option>
                    <Select1.Option value="Lodge">Lodge</Select1.Option>
                    <Select1.Option value="Studio">Studio</Select1.Option>
                    <Select1.Option value="Tent">Tent</Select1.Option>
                    <Select1.Option value="Villa">Villa</Select1.Option>
                    <Select1.Option value="Tree House">
                      Tree House
                    </Select1.Option>
                  </Select1>
                </Form.Item>
              </Col>
              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="property_type">
                  <Select1 mode="multiple" placeholder="Type of Property">
                    <Select1.Option value="Entire Place">
                      Entire Place
                    </Select1.Option>
                    <Select1.Option value="Shared">Shared</Select1.Option>
                    <Select1.Option value="Private Room">
                      Private Room
                    </Select1.Option>
                  </Select1>
                </Form.Item>
              </Col>

              <Col lg={24}>
                <Row>
                  <Col lg={4} style={{ padding: "8px" }}>
                    <Form.Item name="pets" valuePropName="checked">
                      <Checkbox>Pets</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col lg={8} style={{ padding: "8px" }}>
                <Form.Item name="property_type">
                  <Button type="primary" htmlType="submit">
                    Apply Filter
                  </Button>
                </Form.Item>
              </Col>
            </Row> */}
        </Card>
      </Modal>
    </div>
  );
}
