import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Input,
  Button as Button1,
  Modal,
  Form,
  Checkbox,
  Menu,
  Alert,
  message,
  Typography,
  Avatar,
  Select as Select1,
  Divider,
  Dropdown
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { useLocation } from "react-router";

import {
  PoweroffOutlined,
} from "@ant-design/icons";

import axios from "axios";
import { setSession } from "../Utils/session";
import { removeSession } from "../Utils/session";

import GoogleBtn from "./google";
import Facebookbtn from "./facebook";
import Logins from "../../assets/images/log.png";
import RegisterImg from "../../assets/images/regis.png";

const { Text, Title } = Typography;


function Header(props) {
  const [isAuth, setIsAuth] = useState(null);
  const [email, setEmail] = useState("");
  const [valuEmail, setValuEmail] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [modal, setModal] = useState({
    visible: false,
  });

  const location = useLocation();

  


  const [state, setState] = useState({
    loginVisible: false,
    registerVisible: false,
    sideBar: false,
  });

  const loginEmail = sessionStorage.getItem("email");
  const user_level = sessionStorage.getItem("user_level");

  const emailAdd = loginEmail ? loginEmail : "";
  const user_name = emailAdd.substring(0, emailAdd.lastIndexOf("@"));

  const onChange = (e) => {
  
  };

  const showLoginModal = () => {
    setState({
      loginVisible: true,
    });
  };

  useEffect(() => {
    if(location.pathname.includes("/payments")) {
      if(!user_level) {
       showLoginModal()
      } 
      
    }
    // eslint-disable-next-line
   }, [location])

  const handleLoginOk = (e) => {
    
    setState({
      loginVisible: false,
    });
  };

  const handleLoginCancel = (e) => {
    
    setState({
      loginVisible: false,
    });
  };

  const handleRegisterOk = (e) => {
    
    setState({
      registerVisible: false,
    });
  };

  const handleRegisterCancel = (e) => {

    setState({
      registerVisible: false,
    });
  };

  const forgetPassword = () => {
    setState({
      loginVisible: false,
    });
  };

  const handleCancel = (e) => {
   
    setModal({
      visible: false,
    });
  };

  const onFinishLoginWithOTP = async (values) => {
    if(valuEmail.length === 0 || valuEmail === "") {
      return message.error("Enter an Email id.");
    }

    if(!values.otp) {
      return message.error("Enter the OTP sent to your email.")
    }

    const obj = {
      "email": valuEmail,
      "otp": parseInt(values.otp)
    }

    try {

      const res = await axios.post(
       `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/register/direct/roamhome/login`,
        obj
     );
 
     const data = await res.data;
     //alert(JSON.stringify(data))
     if(data.status === 400) {
      setIsAuth(false);
      return message.error(data.remarks)
     } else {
      const token = await res.headers.token;
      const user_level = data.user_level;
      const sessionData = {
        token,
        email: data.email,
        user_level,
        google_token: "",
        facebook_token: "",
      };
      
      setIsAuth(true);
      setSession(sessionData);
      message.success(`Welcome ${data.email}!`);
      setState({
        loginVisible: false,
      });
      window.location.reload();
     }
    } catch (error) {
      setIsAuth(false);
      message.error(error.response.data.remarks);
    }
  }

  const onFinishLogin = async (values) => {
    const res = await axios.post(
      `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/login/social/roamhome`,
      values
    );
    const token = await res.headers.token;
    const data = await res.data;
    const user_level = data.user_level;
    const sessionData = {
      token,
      email: data.email,
      user_level,
      google_token: "",
      facebook_token: "",
    };

    if (data.status) {
      setIsAuth(true);
      setSession(sessionData);
      message.success(`Welcome ${data.email}!`);
      setState({
        loginVisible: false,
      });
      window.location.reload();
    } else {
      setIsAuth(false);
      message.info(data.remarks);
    }
  };

  const onFinishRegister = async (values) => {
    setEmail(values.email);
    const dataTo = {
      ...values,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
      `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/register/social/roamhome`,
        dataTo,
        config
      );
      const data = await res.data;
      const token = await res.headers.token;
      setOtpToken(token);
      if (data.status) {
        setState({
          registerVisible: false,
        });
        setModal({
          visible: true,
        });
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.remarks);
      } else {
        message.error("OOPS! Something went wrong with our server.");
      }
    }
  
  };


  const sendOTP =  async () => {
    const obj = {
      "email": valuEmail
    }

    if(valuEmail.length === 0 || valuEmail === "") {
      return message.error("Enter an Email id.");
    }

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
      `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/register/direct/roamhome/otp`,
        obj,
        config
      );
      const data = await res.data;
      if(data.status === 200) {
        message.info(data.remarks);
      } else {
        message.error(data.remarks);
      }
      // alert(JSON.stringify(data))
      // const token = await res.headers.token;
      // setOtpToken(token);
      // if (data.status) {
      //   setState({
      //     registerVisible: false,
      //   });
      //   setModal({
      //     visible: true,
      //   });
      // }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.remarks);
      } else {
        message.error("OOPS! Something went wrong with our server.");
      }
    }

    //alert(JSON.stringify(obj))
  }

  const verifyOTP = async (values) => {
    const dataTo = {
      email,
      otp: parseInt(values.otp),
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: otpToken,
      },
    };

    try {
      const res = await axios.post(
      `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/verify/social/roamhome`,
        dataTo,
        config
      );
      const data = await res.data;
      if (data.status === 200) {
        message.success(data.remarks);
        setModal({
          visible: false,
        });
      } else {
        message.info(data.remarks);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.remarks);
      } else {
        message.error("OOPS! Something went wrong with our server.");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo)
  };

  const loginSignup = () => {
    setState({
      loginVisible: false,
      registerVisible: true,
    });
  };

  const RegisterLogin = () => {
    setState({
      loginVisible: true,
      registerVisible: false,
    });
  };

  const LogOut = async () => {
    if (sessionStorage.getItem("google_token") !== "") {
      let to = sessionStorage.getItem("google_token")
      const config = {
        headers: {
          "Content-Type": "'application/x-www-form-urlencoded",
        },
      };

      try {
        const res = await axios.get(
          `https://accounts.google.com/o/oauth2/revoke?token=${to}`,
          config
        );
        const data = await res.data;
        if (data.status === 200) {
       
        } else {
          
        }
      } catch (error) {
        if (error.response) {
          message.error(error.response.data.remarks);
        } else {
          message.error("OOPS! Something went wrong with our server.");
        }
      }
  }

  if (sessionStorage.getItem("facebook_token") !== "") {
    
  }

    
    props.history.push("/"); 
    removeSession();
  };

  const MenuFun = (e) => {
    if (e === "profile") {
      props.history.push("/profile")
    }


    if (e === "favourites") {
      props.history.push("/profile/favourite")
    }
    
  }

  const menu_ = (
    <Menu>
      <Menu.Item>
        <div onClick={() => MenuFun("profile")}>
          Profile
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => MenuFun("favourites")}>
          Favourites
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-wrapper">
      {!user_level ? (
        <div>
          <Button1
            className="login btn-primary"
            type="link"
            onClick={showLoginModal}
          >
            Login
          </Button1>
          <Modal
            visible={state.loginVisible}
            onOk={handleLoginOk}
            onCancel={handleLoginCancel}
            className="login_modal"
            footer={null}
          >
            <Row>
              <Col className="Login_img" xs={0} lg={15}>
                <img rel="preload" src={Logins} alt="hero" />
              </Col>
              <Col xs={24} lg={9}>
                <div className="modal-inside">
                  {isAuth === false ? (
                    <Alert
                      message="Authentication Failed!"
                      type="error"
                      showIcon
                    />
                  ) : (
                    ""
                  )}
                  <Form
                    initialValues={{ remember: true }}
                    onFinish={onFinishLoginWithOTP}
                    onFinishFailed={onFinishFailed}
                  >
                    <div>
                      <div>
                        <h2>Sign-up with OTP</h2>
                      </div>
                      {/* <p>
                        New to Roamhome?
                        <Button1
                          type="link"
                          onClick={() => loginSignup()}
                          style={{ marginLeft: "-7px", color: "#oc6170" }}
                        >
                          {" "}
                          Sign up
                        </Button1>
                      </p> */}
                    </div>
                    <div style={{display:"flex"}}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" value={valuEmail} onChange={(e) => {setValuEmail(e.target.value)}} />
                    </Form.Item>
                    <Button1 onClick={() => {
                      sendOTP()
                    }}>Send OTP</Button1>
                    </div>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          // required: true,
                          message: "Please input the OTP sent!",
                        },
                      ]}
                    >
                      <Input placeholder="OTP" />
                    </Form.Item>
                    {/* <Form.Item
                      name="password"
                      rules={[
                        {
                          // required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="password" />
                    </Form.Item> */}

                    {/* <div className="login-help">
                      <Checkbox onChange={onChange}>Remember me</Checkbox>
                      <Link to="/reset-password">
                        <p
                          style={{ fontSize: "13px", color: "#oc6170" }}
                          onClick={() => forgetPassword()}
                        >
                          Forgot password?
                        </p>
                      </Link>
                    </div> */}

                    <Form.Item>
                      <Button1
                        htmlType="submit"
                        className="btn-primary"
                        style={{
                          width: "100%",
                        }}
                      >
                        Log In
                      </Button1>
                      <Divider orientation="center">or</Divider>
                      <div
                        onClick={() =>
                          setState({
                            loginVisible: false,
                          })
                        }
                      >
                      </div>
                      {/* <div>
                            <Button1
                              style={{
                                background: "#4267B2",
                                color: "White",
                                width: "265px",
                                borderRadius: "5px",
                                marginTop: "1rem",
                                height: "35px",
                              }}
                              onClick={() => authLogin()}
                            >
                              <FacebookFilled />
                              Continue with Facebook
                            </Button1>
                          </div> */}
                      <p style={{ fontSize: "12px", marginTop: "5px" }}>
                      By creating an account, I agree to Roamhome's <Link target="_blank" to="/terms">Terms of Use</Link> and <Link target="_blank" to="/policies">Privacy Policy</Link>.
                      </p>
                    </Form.Item>
                  </Form>
                  <GoogleBtn />
                  {/* <Facebookbtn /> */}
                </div>{" "}
              </Col>
            </Row>
          </Modal>
          <Modal
            visible={state.registerVisible}
            onOk={handleRegisterOk}
            onCancel={handleRegisterCancel}
            className="login_modal"
            footer={null}
          >
            <Row>
              <Col className="Login_img" xs={15} lg={15}>
                <img rel="preload" src={RegisterImg} alt="hero" />
              </Col>
              <Col xs={24} lg={9}>
                <div className="modal-inside">
                  <Form
                    initialValues={{ remember: true }}
                    onFinish={onFinishRegister}
                    onFinishFailed={onFinishFailed}
                  >
                    <div>
                      <div>
                        <h2 style={{ marginBottom: "0px" }}>Sign Up</h2>
                      </div>
                      <p>
                        Already have an account?
                        <Button1
                          type="link"
                          onClick={() => RegisterLogin()}
                          style={{ marginLeft: "-7px" }}
                        >
                          Login
                        </Button1>
                      </p>
                    </div>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Name!",
                        },
                      ]}
                    >
                      <Input className="register-input" placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input className="register-input" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Gender!",
                        },
                      ]}
                      labelAlign="left"
                    >
                      <Select1 placeholder="Gender">
                        <Select1.Option value="Male">Male</Select1.Option>
                        <Select1.Option value="Female">Female</Select1.Option>
                        <Select1.Option value="others">Other</Select1.Option>
                      </Select1>
                    </Form.Item>
                    <Form.Item
                      name="pwd"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className="register-input"
                        placeholder="Password"
                      />
                    </Form.Item>

                    {/* <div className="login-help">
                          <Checkbox onChange={onChange}>Remember me</Checkbox>
                        </div> */}

                    <Form.Item>
                      <Button1
                        htmlType="submit"
                        className="btn-primary"
                        style={{
                          width: "100%",
                        }}
                      >
                        Sign Up
                      </Button1>
                      <div>
                        <h3 className="text-center">or</h3>
                      </div>
                      <div
                        onClick={() =>
                          setState({
                            registerVisible: false,
                          })
                        }
                        className="goooleee"
                      >
                      </div>

                      <p style={{ fontSize: "12px", marginTop: "5px" }}>
                        By creating an account, I agree to Roamhome's <Link target="_blank" to="/terms">Terms of Use</Link> and <Link target="_blank" to="/policies">Privacy Policy</Link>.
                      </p>
                    </Form.Item>
                  </Form>
                  <GoogleBtn />
                  {/* <Facebookbtn /> */}
                </div>{" "}
              </Col>
            </Row>
          </Modal>
          <Modal
            title={
              <Title level={2} style={{ textAlign: "center" }}>
                <Text strong>Verify your Email account</Text>
              </Title>
            }
            className="pop_up_otp"
            visible={modal.visible}
            id="myForm"
            footer={[
              <div>
                <Button1 onClick={handleCancel} key="submit" htmlType="submit">
                  Cancel
                </Button1>
                <Button1 form="myForm" key="submit" htmlType="submit">
                  Submit
                </Button1>
              </div>,
            ]}
          >
            <Row>
              <Col xs={18} lg={24}>
                <div bordered={true}>
                  <Form
                    id="myForm"
                    onFinish={verifyOTP}
                    onFinishFailed={onFinishFailed}
                    size="large"
                  >
                    <p>You are one step away from creating your account. You can verify your account by submiting an OTP below or you may close this tab and verify your account by visiting the link that has been sent on your email.</p>
                    <Form.Item
                      label="OTP"
                      name="otp"
                      className="opt_form"
                      rules={[
                        {
                          required: true,
                          message: "Please input your OTP!",
                        },
                      ]}
                    >
                      <Input style={{ textAlign: "center" }} />
                    </Form.Item>
                  </Form>
                </div>{" "}
              </Col>
            </Row>
          </Modal>
        </div>
      ) : (
        ""
      )}
      {/*user_level ? (
        <div className="web-user-details">
          <Link to="/profile">
            <Avatar
              style={{
                backgroundColor: "#f56a00",
                verticalAlign: "middle",
              }}
              size="large"
            >
              <span style={{ textTransform: "capitalize" }}> {user_name}</span>
            </Avatar>
          </Link>

          <Button1
            className="menu_btn"
            type="link"
            icon={<PoweroffOutlined />}
            onClick={LogOut}
          >
            Logout
          </Button1>
        </div>
      ) : (
        ""
      )*/}
      {user_level ? (
        <>
        {<div className="your-bookings">
          <Link to="/your_bookings">Your Bookings</Link>
        </div>}
        <div className="web-user-details">
          
          <Dropdown overlay={menu_} trigger={['click']} placement="bottomCenter" arrow>
            <Avatar
              style={{
                backgroundColor: "#2d2d2d",
                verticalAlign: "middle",
                cursor: "pointer"
              }}
              size="large"
            >
              <span style={{ textTransform: "capitalize" }}> {user_name.charAt(0)}</span>
            </Avatar>
          </Dropdown>
          

          <Button1
            className="menu_btn"
            type="link"
            icon={<PoweroffOutlined />}
            onClick={LogOut}
          >
            Logout
          </Button1>
        </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
export default withRouter(Header);
