import React from 'react'
import { Link } from 'react-router-dom'

function OurStory({data}) {
  return (
    <div className='wrapPopularDestinations'>
            
    <div className='titleMobileWrap'>
        <div className='captionWrap'>
           Our Story
        </div>
    </div>
    <div className='ourStorySection'>
        <div className='ourStoryImg'>
             <img src={data.section_7.img} alt="ourstory" />
        </div>
        <div className='ourStoryDescription'>
            {data.section_7.description}
        </div>
        <div className='readMoreStory'>
            <Link to="/about-us">
              Read more
            </Link>
        </div>
    </div>
    </div>
  )
}

export default OurStory