import React from 'react'

function AdditionalRules({data}) {
  return (
    <div className='amenitiesWrapper'>
    <div className='amenitiesContentWrap'>
        <div className='amenitiesLegend'>
            Additional Rules
        </div>
        <div className='additionalRulesContent'>
            {data.additional_rules}
        </div>
    </div>
    </div>
  )
}

export default AdditionalRules