import { Grid } from '@material-ui/core'
import React from 'react'

function WhyChooseUs({data}) {
  return (
    <div className='wrapPopularDestinations'>
          <div className='titleMobileWrap'>
                <div className='captionWrap'>
                    Why Choose Us
                </div>
               
        </div>
        <div className='whyChooseBox'>
            <Grid alignItems='center'  container>
            {data.section_1.reasons.map((item, idx) => (
                
                    <Grid className='reasonBox' key={idx} item xs={6}>
                        <div className="iconReason">
                            <img src={item.icon} height="35px"/>
                        </div>
                        <div className='titleReason'>
                            {item.title}
                        </div>
                    </Grid>
                
            ))}
            </Grid>
        </div>
    </div>
  )
}

export default WhyChooseUs