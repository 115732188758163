import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ProfileHeader from "./booking_header";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  message,
  notification,
  Modal,
  Empty,
} from "antd";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined, ExclamationCircleFilled, CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import io from "socket.io-client";
import { bookingStatus } from "../Utils/session";

function Booking(props) {
  const { confirm } = Modal;

  const [booking, setBooking] = useState({
    confirmed: [],
    waiting: [],
    cancelled: [],
    requested: []
  });

  const [loading, setLoading] = useState(false);

  const [socket, setSocket] = useState();
 // const [socket_connected, setSocketConnected] = useState(false);
  useEffect(() => {
    const sock = io("https://api.roamhome.in:5555/" ,{ transports: ['websocket', 'polling', 'flashsocket'] })
    setSocket(sock)
  //  setSocketConnected(true)
    return () => {
  //    setSocketConnected(false)
      sock.close()
    }
  },[])

  const [tab, setTab] = useState("tab1");

  useEffect(() => {
    fetchView();
  }, []);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  async function fetchView() {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/history`,
        { email: sessionStorage.getItem("email") },
        config
      );
      const data = await res.data;
      const confirmed_bookings = data.filter(
        (b) => b.booking_status === "confirmed"
      );
      const waiting_bookings = data.filter(
        (b) => b.booking_status === "waiting_payment"
      );
      const cancelled_bookings = data.filter(
        (b) => b.booking_status === "cancelled"
      );
      
      const requested_bookings = data.filter(
        (b) => b.booking_status === "actionable"
      )

      setBooking({
        confirmed: confirmed_bookings,
        waiting: waiting_bookings,
        cancelled: cancelled_bookings,
        requested: requested_bookings
      });
      
      setLoading(false);
    } catch (error) {
     
    }
  }

  const handleBook = async (x) => {
    
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/repayment`,
        { booking_id: x.booking_id },
        config
      );
      const data = await res.data;
    
      if (data.status === 400) {
        message.error(data.remarks);
      }
      if (data.status === 200) {

        if (data.response) {
          handleRzp(
            data.author,
            data.response.order_id,
            data.response.amountamount,
            data.response.currency,
            data.email
          );
        } else {
          openNotificationWithIcon("info", data.remarks);
        }
      }
    } catch (error) {
     
    }
  };

  const handleRzp = (name, order_id, amount, currency, email) => {
    bookingStatus(true);
    var options = {
      key: "rzp_live_OPRLWprpiyvPTS",
      amount: amount,
      currency: currency,
      name: "RoamHome",
      description: "Homes as unique as you.",
      image: "https://example.com/your_logo",
      order_id: order_id,
      handler: async function (response) {
        const rzpdata = await response;
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        };
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/check_payment`,
            rzpdata,
            config
          );
          const resdata = await res.data;
          
          if (resdata.status === 200) {
            if(socket !== null) {
              socket.emit("newNotification",{"type":"booking",name})
            }
            openNotificationWithIcon("success", "Payment Successfull");
            props.history.push("/thank-you");
          }
          
        } catch (error) {
          
        }
      },
      prefill: {
        name: name,
        email: email,
      },
      theme: {
        color: "#F37254",
      },
    };

    var rzp1 = new window.Razorpay(options);

    rzp1.open();

  };

  function showConfirm(booking_id, date_from, date_to) {
    confirm({
      title: "Do you want to Cancel the booking?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Please, check the cancellation policy and refund rules on our website",
      async onOk() {
        return handleCancel(booking_id, date_from, date_to);
      },
      onCancel() {},
    });
  }

  const handleCancel = async (booking_id, date_from, date_to) => {
    const dataTo = {
      booking_id,
      date_from,
      date_to,
      booking_status: "cancelled",
      payment_agent: [],
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/private/booking/booking/modify`,
        dataTo,
        config
      );
      //eslint-disable-next-line
      const data = await res.data;
      fetchView();
    } catch (error) {
      
    }
  };

  const BookingStatus = (props) => {
    
    if (props.data === "onhold") {
    return (
      <span>
      <InfoCircleFilled />
      &nbsp;Please wait while we process your request.
      </span>
    );
    }

    if (props.data === "approved") {
      return (
        <span>
        <CheckCircleFilled />
        &nbsp;Your request has been approved, please complete the booking by making the payment.
        </span>
      );
    }

    if (props.data === "rejected") {
      return (
        <span>
        <ExclamationCircleFilled />
        &nbsp;We are unable to complete your request for booking at this time.<br/>&nbsp;&nbsp;&nbsp; Please select a different home, we have over 300+ homes listed for you.
        </span>
      );
    }
  }

  const contentList = {
    tab1: (
      <div>
        {!loading ? (
          booking.confirmed.length > 0 ? (
            booking.confirmed.map((conf) => (
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title={conf.property_name}
                extra={<b>#{conf.booking_id}</b>}
              >
                <div>
                  <div>
                    <p>
                      Check-in : <b>{moment(conf.date_from).format("ll")}</b>
                    </p>
                    <p>
                      Check-out : <b>{moment(conf.date_to).format("ll")}</b>
                    </p>
                    <p>
                      Guests : <b>{conf.num_guests}</b>
                    </p>
                    <p>
                      Total Paid : &#8377; <b>{conf.grand_total_amount}</b>
                    </p>
                  </div>
                </div>
                <Col lg={24}>
                  <Row justify="space-between">
                    <Col lg={3}>
                      <Link to={`/rating/${conf.property_id}`}>
                        <Button type="primary">Add Review</Button>
                      </Link>
                    </Col>
                    <Col lg={3}>
                      <Button
                        type="danger"
                        onClick={() =>
                          showConfirm(
                            conf.booking_id,
                            conf.date_from,
                            conf.date_to
                          )
                        }
                      >
                        Cancel Booking
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Card>
            ))
          ) : (
            <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
              <Empty />
            </div>
          )
        ) : (
          <div>
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </div>
    ),
    tab2: (
      <div>
        {booking.cancelled.length > 0 ? (
          booking.cancelled.map((conf) => (
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title={conf.property_name}
              extra={<b>#{conf.booking_id}</b>}
            >
              <div>
                <div>
                  <p>
                    Check-in : <b>{moment(conf.date_from).format("ll")}</b>
                  </p>
                  <p>
                    Check-out : <b>{moment(conf.date_to).format("ll")}</b>
                  </p>
                  <p>
                    Guests : <b>{conf.num_guests}</b>
                  </p>
                  <p>
                    Total Paid : &#8377; <b>{conf.grand_total_amount}</b>
                  </p>
                  <p>
                    Refund Status : <b>{conf.booking_refund}</b>
                  </p>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
            <Empty />
          </div>
        )}
      </div>
    ),
    tab3: (
      <div>
        {!loading ? (
          booking.waiting.length > 0 ? (
            booking.waiting.map((conf) => (
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title={conf.property_name}
                extra={<b>#{conf.booking_id}</b>}
              >
                <div>
                  <div>
                    <p>
                      Check-in : <b>{moment(conf.date_from).format("ll")}</b>
                    </p>
                    <p>
                      Check-out : <b>{moment(conf.date_to).format("ll")}</b>
                    </p>
                    <p>
                      Guests : <b>{conf.num_guests}</b>
                    </p>
                    <p>
                      Total Pending : &#8377; <b>{conf.grand_total_amount}</b>
                    </p>
                  </div>
                </div>
                <Col lg={24}>
                  <Button type="primary" onClick={() => handleBook(conf)}>
                    Make Payment
                  </Button>
                </Col>
              </Card>
            ))
          ) : (
            <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
              <Empty />
            </div>
          )
        ) : (
          <div>
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </div>
    ),
    tab4: (
      <div>
        {!loading ? (
          booking.requested.length > 0 ? (
            booking.requested.map((conf) => (
              <Card
                style={{ marginTop: 16 }}
                type="inner"
                title={conf.property_name}
                extra={<b>#{conf.booking_id}</b>}
              >
                <div>
                  <div>
                    <p>
                      Check-in : <b>{moment(conf.date_from).format("ll")}</b>
                    </p>
                    <p>
                      Check-out : <b>{moment(conf.date_to).format("ll")}</b>
                    </p>
                    <p>
                      Guests : <b>{conf.num_guests}</b>
                    </p>
                    <p>
                      Total Pending : &#8377; <b>{conf.grand_total_amount}</b>
                    </p>
                  </div>
                </div>
                <div className="status">
                  <BookingStatus data={conf.twoItems[0].status}></BookingStatus>
                </div>
                <Col lg={24}>
                  {conf.twoItems[0].status === "approved" ? (
                    <Button type="primary" onClick={() => handleBook(conf)}>
                      Make Payment
                    </Button>
                  ) : ("")}
                </Col>
              </Card>
            ))
          ) : (
            <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
              <Empty />
            </div>
          )
        ) : (
          <div>
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </div>
    ),
  };

  const tabList = [
    {
      key: "tab1",
      tab: "Confirmed",
    },
    {
      key: "tab2",
      tab: "Cancelled",
    },
    {
      key: "tab3",
      tab: "Payment Pending",
    },
    {
      key: "tab4",
      tab: "Requested Bookings"
    }
  ];

  return (
    <div>
      <ProfileHeader />
      <div className="container">
        <Row>
          <Col lg={24}>
            <Card
              style={{ width: "100%" }}
              title="My Bookings"
              tabList={tabList}
              activeTabKey={tab}
              onTabChange={(key) => {
                setTab(key);
              }}
            >
              {contentList[tab]}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(Booking);
