import React from "react";

function GeneralTerms() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <p>
        We do not accept payment by cash. The entire payment has to be made by
        electronic means, as has been provided by us, and a 100% is required to
        be made at the time of blocking the dates. We do not accept any payments
        that have not been made by electronic means.
      </p>
    </div>
  );
}

export default GeneralTerms;
