import React from "react";

function Early() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <h4>
        On many of our properties the Caretakers or other staff entrusted with
        the Check-ins and Check-outs might not be available at odd hours, and in
        certain instances, even during office hours.
      </h4>
      <h3>Check-ins</h3>
      <p>
        If and when guests are checking-in at hours when the caretaker or other
        assigned staff is not available, then such guests must check themselves
        in. We take the responsibility of ensuring that the keys are placed at a
        point from where the guests can collect them with ease at the time of
        check-in. If there is a communication lapse between the guests and us
        regarding the exact timing of the check-in or alteration in the time of
        check-in, then we will not be held responsible for an unsatisfactory or
        untimely check-in. If due to unforeseen circumstances, the caretaker or
        other assigned staff is not able to check the guests in as described or
        expected, then we will not be held responsible for the same. Though we
        will make exceptional efforts to ensure that such situations do not
        arise.
      </p>
      <h3>Check-outs</h3>
      <p>
        If and when the guests are checking-out beyond the time prescribed, then
        they must seek permission of the company. If the guest wishes to
        check-out before the prescribed time, then the onus is on them to ensure
        that our caretaker or other assigned staff is there to inspect the
        premises at such time. If a guest checks-out prior to the assigned
        check-out time on the booking, without informing us or without the
        caretaker or assigned staff inspecting the premises, and in the
        eventuality that we find damage caused to the property after this early
        check-out, the company will assume the guest to have caused damage to
        the property and take due action.
      </p>
    </div>
  );
}

export default Early;
