import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import 'react-multi-carousel/lib/styles.css';
import {MonitorOutlined} from "@ant-design/icons";
// import { SRLWrapper } from "simple-react-lightbox";
// import { useLightbox } from 'simple-react-lightbox'

function Slider_({ data }) {
  // const {openLightbox} = useLightbox()


  const img = data[0].img_1920.map(e => 
    ({src: e.url, thumbnail: e.url.replace("original","thumbnail2"), caption: "", width: 1920, height: 'auto'})
  )


  // const new_ = (index) => {
  //   openLightbox(index)
  // }


  return (
    <div className="slider_">
       {/* <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                breakpoint: {
                    max: 3000,
                    min: 1024
                },
                items: 3,
                partialVisibilityGutter: 40
                },
                mobile: {
                breakpoint: {
                    max: 464,
                    min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
                },
                tablet: {
                breakpoint: {
                    max: 1024,
                    min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
                }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            >


            {data[0].img_960.map((e,index) => (
               
                <div className="mt-2" key={index} onClick={() => toggleLightbox(index)}>
                <img className="media-img card-img-top card-img-hero" src={e.url} alt="Alt text"/>
                </div>
            ))
            }

            


        </Carousel> */}
            <br/>

            
            <Carousel centered infinite arrows slidesPerPage={2}>
                        {data[0].img_960.map((x,index) => (
                          <div className="mt-2 overlapm" key={index} onClick={() => {}}>
                            <img src={x.url} alt={"slider"} />
                            <div className="overlow"><MonitorOutlined/></div>
                          </div>
                        ))}
            </Carousel>
            
           
            {/* <SRLWrapper images={img} /> */}
             {/* 
              {data[0].img_1920.map((x) => (
                
                  <img className="drm" src={x.url} />
                
              ))}
              
              </SRLWrapper> */}
            
    </div>
  );
}

export default Slider_;