/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  notification,
  Skeleton,
  message,
  Input,
  Divider,
  Drawer
} from "antd";
import {
  ShareAltOutlined
} from '@ant-design/icons';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "../../assets/styles/react-dates/react-dates-override.scss";
import { DateRangePicker } from 'react-dates';
import { Link, withRouter } from "react-router-dom";
import { Tooltip } from "antd";
import { GoogleApiWrapper } from "google-maps-react";
import "@brainhubeu/react-carousel/lib/style.css";
import moment from "moment";
import axios from "axios";
// import SimpleReactLightbox from "simple-react-lightbox";
import Slider from "react-slick";
import SliderOR from "./slider.js";
import MapboxMap from "./Map.js";
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/BlackborderHeart.svg";
import Infant from "../../assets/images/viewSvg/infant_friendly.svg";
import NotInfant from "../../assets/images/viewSvg/not_infant_friendly.svg";
import Smoking from "../../assets/images/viewSvg/Smoking.svg";
import NoSmoking from "../../assets/images/viewSvg/no_smoking.svg";
import Pets from "../../assets/images/viewSvg/Pets.svg";
import NoPets from "../../assets/images/viewSvg/no_pets.svg";
import NoParty from "../../assets/images/viewSvg/no_party.svg";
import Party from "../../assets/images/viewSvg/Party.svg";
import Bed from "../../assets/images/bed.svg";
import Guest from "../../assets/images/Guests.svg";
import Place from "../../assets/images/place.svg";
import BedRooms from "../../assets/images/bed.svg";
import BathTab from "../../assets/images/bathTap.svg";
import NoNetwork from "../Home/noNetwork";
import AmenitiesIcon from "./amenitiesIcon";
import parse from "html-react-parser";
import {
  StarFilled,
  PlusOutlined,
  MinusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Reviews from "./Reviews"
import Nearby from "./Nearby";
import queryString from 'query-string';
import { useLocation } from 'react-router';

function PropertyView(props) {

  const location = useLocation();
  const {
    checkin : checkInPara,
    num_guests,
    num_nights,
    
  } = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);
    //window.Tawk_API.hideWidget()
    
  }, []);



  const [network, setNetwork] = useState(true);
  const [show, setShow] = useState(false);
  const [amentities_show,setamentities_show] = useState(false);
  const [home, setHome] = useState({});
  const [reviews, setReviews] = useState(null);
  const [price, setPrice] = useState();
  const [disable, setDisable] = useState(true);
  const [similarProprties, setSimilarProperties] = useState({});
  const [favourites, setFavourites] = useState();
  const [visible, setVisible] = useState(false);
  const [coupon, setCoupon] = useState("");

  const [open, setOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedInput2, setFocusedInput2] = useState(null);

  const {
    property_id,
  } = props.match.params;
  const [listing_id, setListingId] = useState(null);
  const [guests, setGuests] = useState({
    adults: num_guests ? parseInt(num_guests) : 1,
    children: 0,
  });

  const [checkin, setCheckin] = useState(
    checkInPara ? moment(checkInPara,"DD-MM-YYYY").format("YYYY-MM-DD") : ""
  );

  const [checkout, setCheckout] = useState(
    checkInPara ? moment(checkInPara,"DD-MM-YYYY").add(parseInt(num_nights),"days") : ""
  );

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
    });
  };



 /* let d = [
    {
        start: moment("1.01.1900 00:00", format),
        end: yesterday
      }
  ];
  */

  let d = [];

  const [disabledDates, setdisabledDates] = useState(d);

  // useEffect(() => {
   
  // }, [disabledDates])

  



  useEffect(() => {
    const token = sessionStorage.getItem("token");

    window.scrollTo(0, 0);

    if (token) {
      fetchFavourites();
    }

    fetchView();
   /* fetchReviews();
    fetchPrice();
    FetchSimilarProperties();
    fetchBlockDates();
    get_blocked_dates(); */
    
    //eslint-disable-next-line
  }, [property_id]);

  useEffect(() => {
    if (listing_id !== null) {
      fetchReviews();
      fetchPrice();
      FetchSimilarProperties();
      get_blocked_dates();
    }
    //eslint-disable-next-line
  }, [listing_id])



  const get_blocked_dates = async () => {
    let cur = moment().format("MM,YYYY")
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      try {
        
        
        for(let i=1;i<7;i++) {          
          let m = cur.split(',');

          const res1 = await axios.get(
            `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/get_blocks/${listing_id}/${m[0]}/${m[1]}`,
            config
          );
  
          const data1 = await res1.data;
          const new_ = await data1.map(e => (
              {start: moment(e.ota_date_from),
              end: moment(e.ota_date_to)}
          ))
         
          d.push(...new_)
          setdisabledDates(prev => [...prev, ...new_])
          cur = moment().add(i,"months").format("MM,YYYY")
          
          
        }

        
        // setReviews(data);
        //d.push(...new_)

      } catch (error) {
       
      }
  }

  async function fetchView() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v2/public/channel_manager/get_property/${property_id}`,
        config
      );
      const data = await res.data;
      setHome(data);
      setListingId(data.listing_id);
     
    } catch (error) {
    
      setNetwork(false);
    }
   
  }

  async function fetchReviews() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/reviews/all/${listing_id}`,
        config
      );
      const data = await res.data;
      setReviews(data);
    
    } catch (error) {
    
    }
  }



  async function FetchSimilarProperties() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/suggestions/location/${listing_id}`,
        config
      );
      const data = await res.data;
      setSimilarProperties(data);
    
    } catch (error) {
      
    }
  }

  async function fetchPrice() {
    
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }

    if(checkin !== "" && checkout !=="") {
      const postData = {
        properties: [
          {
            property_id: listing_id,
            start_date: moment(checkin).format("YYYY-MM-DD"),
            end_date: moment(checkout).format("YYYY-MM-DD"),
            coupon_code: coupon,
            guests: guests.adults,
            children: "",
          },
        ],
      };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ORIGIN}api/v2/public/booking/price_query`,
          postData,
          config
        );
        const data = await res.data;
        if (data.status === 200) {
         
          setPrice(data.bifurcated[0]);
          setDisable(false);
        }
        setPrice(data.bifurcated[0]);
        setDisable(false);
       
      } catch (error) {
        if (error.response) {
          setDisable(true)
          message.info(error.response.data.remarks);
        }
      }

    }

  }


  useEffect(() => {
    if(price !== undefined) {
      setHome({...home,price_night:price.per_night_average})
    }
    //eslint-disable-next-line
  },[price])
  const shareurl = window.location.href;
  async function fetchFavourites() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/favourites/${listing_id}`,
        config
      );

      const data = await res.data;
      if (res.status === 200) {
        setFavourites(data.favourite);
      }
    
    } catch (error) {
     
    }
  }

  const handleFavorite = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
        { listing_id },
        config
      );

      const data = await res.data;
     
      if (data.statues === 200) {
        message.success(data.remarks);
      }
      fetchFavourites();
    } catch (error) {
     
      message.info("Please Login to add this property to favourites!");
    }
  };

  const handleAdult = (e, val) => {
    if (e === 0) {
      setGuests({ ...guests, adults: val - 1 });
    }
    if (e === 1) {
      setGuests({ ...guests, adults: val + 1 });
    }
  };

  const click = () => {
    const format = "DD.MM.YYYY HH:mm";
    const yesterday = moment().subtract(1, "days").utcOffset(330).format(format)
    const last_check_in = moment().add(365, "days").utcOffset(330).format(format)

    if(!moment(checkin).isValid() || !moment(checkout).isValid() || new Date(checkin) < moment(yesterday,format).toDate() || new Date(checkout) < Date.now() || new Date(checkin) > moment(last_check_in,format).toDate() || new Date(checkout) > moment(last_check_in,format).toDate()) {
      openNotificationWithIcon("error","Dates are not valid.")
      return
    }

    ReactGA.event({
      "category": "Trying_to_Book",
      "action": JSON.stringify({"type":"booking_init", "payload": {"property_id":listing_id}}),
    })

    props.history.push(
      `/property-details/${listing_id}/google/${guests.adults}/${
        guests.children
      }/${moment(checkin).format()}/${moment(checkout).format()}/payments`
    );
  };

  const clickPropertySimilar = (id) => {
    props.history.push(`/desc/${id}`);
   
  };

  useEffect(() => {
    if (listing_id !== null) {
      if(checkin !== null && checkout !== null) {
        fetchPrice()
      }
    }
    //eslint-disable-next-line
  },[checkin,checkout,guests])

  const time = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
  ];

  const settings = {
    className: "center",
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };


  const parsedText = parse(home.property_desc ? home.property_desc : "");

  const [visible_drawer,setVisibile_drawer] = useState(false);

  const onCloseDrawer = () => {
    setVisibile_drawer(!visible_drawer)
  }

  const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);
}


  return (
    <div>
      <Helmet>
        <title>{home.seo_title}</title>
        <meta name="title" content={home.seo_title} />
        <meta
          name="description"
          content="Roamhome is a rental portal that provides curated homes with professional service. Our properties are spread across budgets without compromising our service."
        />
      </Helmet>
      {network ? (
        <div className="view-listing">
          <Row>
            <Col xs={24} lg={24} className="property_view">
                {!home.imgs ? (
                    <Skeleton active />
                ) : (
                    
                    <SliderOR data={home.imgs} />
                    
                    
                )}

              {/*<div className="property-slider">
                <SimpleReactLightbox>
                  <SRLWrapper className="property_view">
                    {!home.imgs ? (
                      <Skeleton active />
                    ) : (
                      <Carousel centered infinite arrows slidesPerPage={2}>
                        {home.imgs[0].img_960.map((x) => (
                          <div>
                            <img src={x.url} />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </SRLWrapper>
                </SimpleReactLightbox>
                        </div> */}

              <div className="slider small-carousal">
                <Slider {...settings}>
                  {home.imgs ? (
                    home.imgs[0].img_960.map((x) => (
                      <div className="slide">
                        <img src={x.url} />
                      </div>
                    ))
                  ) : (
                    <Skeleton />
                  )}
                </Slider>
              </div>

              <div className="property_link prop_link_pd">
                <p className="_link">
                  <Link to="/">Home</Link>{" "}
                </p>
                <span>
                  <RightOutlined />
                </span>
                <p className="_link">
                  <Link to="/search">Search</Link>{" "}
                </p>
                <span>
                  {" "}
                  <RightOutlined />{" "}
                </span>
                <p className="_link property_name">{home.state}</p>
              </div>
            </Col>
          </Row>
          <div className="containr_view cv_pd">
            <Row justify="space-between">
              <Col xs={24} lg={14}>
                <div className="top_bar">
                <div className="top_bar_ast">
                <Tooltip title={home.property_title}>
                  <h1 className="prop-title">{home.property_title}</h1>
                </Tooltip>
                <p className="location">{/*home.address*/}</p>
                </div>
                <div className="top_bar_bst">
                    <div class="share_btn" onClick={() => onCloseDrawer()}><ShareAltOutlined /></div>
                </div>
                <Drawer
                  title="Share"
                  placement="bottom"
                  closable={false}
                  onClose={onCloseDrawer}
                  visible={visible_drawer}
                  key="bottom"
                > 
                <div className="share_com">
                  <EmailShareButton url={shareurl}>
                    <EmailIcon size={48} round />
                  </EmailShareButton>
                  <WhatsappShareButton url={shareurl}>
                    <WhatsappIcon size={48} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={shareurl}>
                    <LinkedinIcon size={48} round />
                  </LinkedinShareButton>
                  <FacebookShareButton url={shareurl}>
                    <FacebookIcon size={48} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareurl}>
                    <TwitterIcon size={48} round/>
                  </TwitterShareButton>
                  <FacebookMessengerShareButton appId="315677186379398" url={shareurl}>
                    <FacebookMessengerIcon size={48} round/>
                  </FacebookMessengerShareButton>
                  <TelegramShareButton url={shareurl}>
                    <TelegramIcon size={48} round/>
                  </TelegramShareButton>
                  </div>
                </Drawer>
                </div>


                <Row className="icons">
                  <Col span={6} className="list_icons">
                    <img src={Place} alt="place" />
                    <p className="icon-title">{home.type_of_listing}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={Bed} alt="bed" />
                    <p className="icon-title">{home.num_of_bedroom} {(home.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={BathTab} alt="bed" />
                    <p className="icon-title">{home.num_of_bath} {(home.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                  </Col>
                  <Col span={6} className="list_icons">
                    <img src={Guest} alt="bed" />
                    <p className="icon-title">{home.num_of_guests} {(home.num_of_guests > 1) ? "Guests" : "Guest"}</p>
                  </Col>
                </Row>

                <div>
                  <h2>About The Listing</h2>
                  <div className={!show ? "prop-desc" : ""}>{parsedText}</div>
                  <span onClick={() => setShow(!show)} className="read-more">
                    {show ? "Show less" : "Read more..."}
                  </span>
                </div>

                <div>
                  <h2>Amenities</h2>
                  <Row className={!amentities_show ? "amenities_new amenities": "amenities"}>
                    <Col lg={24} xs={24}>
                      <Row>
                        {home.amenities
                          ? home.amenities.map((x) => (
                              <Col lg={8} xs={8}>
                                <div className="amenities_display">
                                  <img
                                    src={AmenitiesIcon(x)}
                                    alt="AmenitiesIcon"
                                  />
                                  <Tooltip title={x}>
                                    <p className="amenities_name">{x}</p>
                                  </Tooltip>
                                </div>
                              </Col>
                            ))
                          : ""}
                      </Row>
                    </Col>
                  </Row>
                  <span onClick={() => setamentities_show(!amentities_show)} className="read-more">
                  {amentities_show ? "Show less" : "Read more..."}
                  </span>
                </div>

                <div>
                  <h2>Facilities</h2>
                  <Row className="amenities">
                    <Col span={24}>
                      <Row>
                        {home.facilities
                          ? home.facilities.map((x) => (
                              <Col span={8}>
                                {x ? (
                                  <div className="amenities_display">
                                    <img
                                      src={AmenitiesIcon(x)}
                                      alt="Facilities"
                                    />
                                    <Tooltip title={x}>
                                      <p className="amenities_name">{x}</p>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                            ))
                          : ""}
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className="location">
                  <h2>Other Details</h2>
                  <p>
                    Check in After <b></b>:
                    <span style={{ marginLeft: "5px" }}>
                      {home.check_in > 11
                        ? `${time[home.check_in]} pm`
                        : `${time[home.check_in]} am`}
                    </span>
                  </p>
                  <p>
                    Check Out Before :{" "}
                    <span style={{ marginLeft: "5px" }}>
                      {home.check_out > 11
                        ? `${time[home.check_out]} pm`
                        : `${time[home.check_out]} am`}{" "}
                    </span>
                  </p>
                </div>

                <h2>Rules & Policies</h2>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.smoking ? (
                      <img src={Smoking} alt="smoking" />
                    ) : (
                      <img src={NoSmoking} alt="smoking no" />
                    )}
                  </p>
                  <p>
                    {home.smoking ? "Smoking Allowed" : "Smoking Not Allowed"}
                  </p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.pets ? (
                      <img src={Pets} alt="pets" />
                    ) : (
                      <img src={NoPets} alt="no pets" />
                    )}
                  </p>
                  <p>{home.pets ? "Pets Allowed" : "Pets Not Allowed"}</p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.party ? (
                      <img src={Party} alt="party" />
                    ) : (
                      <img src={NoParty} alt="no party" />
                    )}
                  </p>
                  <p>
                    {home.party ? "Drinking Allowed" : "Drinking Not Allowed"}
                  </p>
                </div>

                <div className="rule_policies">
                  <p className="yes_no_smoking">
                    {home.children ? (
                      <img src={Infant} alt="infant" />
                    ) : (
                      <img src={NotInfant} alt="no infant" />
                    )}
                  </p>
                  <p>
                    {home.children
                      ? "Children Allowed"
                      : "Children Not Allowed"}
                  </p>
                </div>

                <div>
                  <h2>Additional Rules</h2>
                  <p>{home.additional_rules}</p>
                </div>

                <>
                  <h2>Location</h2>
                  {home.latitude ? <MapboxMap lat={home.latitude} long={home.longitude}/>:""}
                  {/*<Map
                    className="map-wrapper"
                    google={props.google}
                    zoom={11}
                    center={{
                      lat: home.latitude,
                      lng: home.longitude,
                    }}
                    onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                  >
                    <Marker
                      position={{ lat: home.latitude, lng: home.longitude }}
                      name={"Current location"}
                      icon={Logo}
                    />
                  </Map>*/}
                </>

                <div style={{/* marginTop: "24rem" */}}>
                  <h2>Customer Review</h2>
                  {reviews !== null && (
                    <Reviews reviews={reviews}/>
                  )}
                </div>
                <Nearby property_id={property_id} />
              </Col>

              <Col xs={0} lg={8}>
                <Card className="stickys">
                  <div>
                    <div className="icon_heart">
                      <h4>
                        {/*<span style={{ fontSize: "23px" }} className="rupee">
                          {" "}
                          ₹{" "}
                        </span>*/}{" "}
                        {price ? numberFormat(price.per_night_average) : numberFormat(home.price_night ? home.price_night : 0)} <b className="fonts"> /night</b>
                      </h4>
                      {favourites === "true" ? (
                        <div className="heart_icons">
                          <img
                            src={HeartFilled}
                            onClick={() => handleFavorite()}
                            alt="haert-filled"
                          />
                        </div>
                      ) : (
                        <div className="heart_icons">
                          <img
                            src={HeartBorder}
                            onClick={() => handleFavorite()}
                            alt="heart"
                          />
                        </div>
                      )}
                    </div>
                    <div className="rating_icons">
                      <p className="stars">
                      {reviews !== null && (
                        reviews.length !== 0 && (
                          reviews.length === 1 ? 
                          <> <StarFilled className="star_icon" /> {reviews.length} Review</> : 
                          <> <StarFilled className="star_icon" /> {reviews.length} Reviews</>
                        )
                      )}
                      </p>
                    </div>
                    <p>Check-in - Check-Out</p>
                    {/*<div className="date_picker">
                      <RangePicker
                      placeholder={["Check-in","Check-out"]}
                      style={{ width: "100%" }}
                      className="date-picker input-field"
                      size="large"
                      disabledDate={current =>
                        disabledDates.some(date =>
                          current.isBetween(
                            moment(date["start"], format),
                            moment(date["end"], format),
                            "day"
                          )
                        )}
                      defaultValue={
                        check_in
                          ? [
                              moment(check_in, dateFormat),
                              moment(check_out, dateFormat),
                            ]
                          : ""
                      }
                      onChange={onChange}
                    />
                    </div>
                    */}
                    <div className="datepick">
                    <DateRangePicker
                          startDatePlaceholderText="Check-in"
                          endDatePlaceholderText="Check-out"
                          startDateId="startDate"
                          endDateId="endDate"
                          startDate={checkin && moment(checkin)}
                          minDate={moment().subtract(1, "days").format("MM/DD/YYYY")} 
                          maxDate={moment().add(365, "days").format("MM/DD/YYYY")} 
                          endDate={checkout && moment(checkout)}
                          onDatesChange={({ startDate, endDate }) => { 
                            setPrice();
                            setCheckin(startDate)
                            setCheckout(endDate)
                          }}
                          isDayBlocked={current =>
                            disabledDates.some(date =>
                              current.isBetween(
                                moment(date["start"]),
                                moment(date["end"]),
                                "day"
                              )
                            )}
                          numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                          displayFormat="YYYY-MM-DD"
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) => { setFocusedInput(focusedInput)}}
                          block
                      />
                    </div>
                    <div className="guest-dropdown">
                      <div className="adult-count">
                        {" "}
                        <p>Guests</p>
                        <div className="guests-count">
                          <span className="counnt">
                            <Button
                              disabled={guests.adults > 0 ? false : true}
                              shape="circle-outline"
                              icon={<MinusOutlined />}
                              size="small"
                              onClick={() => {
                                handleAdult(0, guests.adults);
                                setPrice();
                              }}
                            ></Button>
                          </span>
                          <span className="counnt">
                            <Button size="small" shape="circle-outline">
                              {guests.adults}
                            </Button>
                          </span>
                          <span className="counnt">
                            <Button
                              shape="circle-outline"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                handleAdult(1, guests.adults);
                                setPrice();
                              }}
                              size="small"
                            ></Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {price ? (
                    <div>
                      <div className="price">
                        <Row className="pricing_total">
                          <p>
                            {numberFormat(price.per_night_average)}x {price.days_to_booked}{" "}
                            nights
                          </p>
                          <p>
                            {numberFormat(price.base_price)}.00
                          </p>
                        </Row>

                        <Row className="pricing_total">
                          <p>Total Taxes</p>
                          <p>
                            {numberFormat(price.taxes_total)}
                          </p>
                        </Row>
                        <Row className="pricing_total total-bg">
                          <p>Total</p>
                          <p>
                            {numberFormat(price.grand_total)}
                          </p>
                        </Row>

                        {/*visible ? (
                          <Row
                            justify="space-around"
                            style={{ marginTop: "1rem" }}
                          >
                            <Input
                              size="large"
                              style={{ width: "200px" }}
                              placeholder="Coupon Code"
                              onChange={(e) => setCoupon(e.target.value)}
                            />

                            <Button
                              size="middle"
                              className="btn-primary"
                              style={{
                                width: "80px",
                                height: "40px",
                              }}
                              onClick={() => fetchPrice("val")}
                            >
                              Apply
                            </Button>
                          </Row>
                        ) : (
                          <Row justify="center" style={{ marginTop: "1rem" }}>
                            <a onClick={() => setVisible(true)}>
                              Have Promo Code ?
                            </a>
                          </Row>
                        )*/}
                      </div>
                      <div>
                        <div className="btn_search">
                          <Button
                            size="large"
                            className="btn-primary btn-large"
                            onClick={() => click()}
                            disabled={disable}
                          >
                            {home.instant_book ? "Book Now" : "Request to book"}{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : ( ""/*
                    <div className="btn_search">
                      <Button
                        size="large"
                        className="btn-primary btn-large"
                        onClick={() => fetchPrice("val")}
                      >
                        Check Availability
                      </Button>
                    </div> */
                  )}

                  <div className="footer_card">
                    <p>
                      You won’t be charged yet. <br></br>Certain reservations
                      may also require a security deposit.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <h2>Similar homes</h2>
              <div className="selections-card-wrapper">
                {similarProprties.length > 0
                  ? similarProprties.slice(0, 3).map((x, i) => (
                      // <Link to={`/property-details/${x.listing_id}`}>
                      <Card
                        onClick={() => clickPropertySimilar(x.listing_id)}
                        key={i}
                        className="selection-card"
                        style={{
                          boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                        }}
                        cover={
                          <img
                            src={
                              x.featured_img[0].img_960[0]
                                ? x.featured_img[0].img_960[0].url
                                : ""
                            }
                            alt="selection"
                          />
                        }
                      >
                        <div className="name_rating">
                          <Tooltip title={x.property_title}>
                            <h3>{x.property_title}</h3>
                          </Tooltip>

                          <p>
                            {x.rating}
                            <StarFilled className="star_icon" />
                          </p>
                        </div>
                        <p className="place_name">
                          {x.state} , {x.country}
                        </p>
                        <Row style={{ marginBottom: "5px" }}>
                          {" "}
                          <Col span={8}>
                            {" "}
                            <div>
                              <img src={Place} alt="bed" height="17px" />
                            </div>
                            <p className="name_icon">{x.type_of_listing}</p>
                          </Col>
                          <Col span={8}>
                            <div>
                              <img
                                src={BedRooms}
                                alt="Bedrooms"
                                height="17px"
                              />
                            </div>
                            <p className="name_icon">
                              {x.num_of_beds} Bedrooms
                            </p>
                          </Col>
                          <Col span={8}>
                            <div>
                              <img src={BathTab} alt="bathTub" height="17px" />
                            </div>
                            <p className="name_icon">
                              {x.num_of_bath} Bathrooms
                            </p>
                          </Col>
                        </Row>
                        <div className="d-flex">
                          <h4>
                            {" "}
                            <span className="rupee"> ₹ </span> {x.price_night}{" "}
                          </h4>
                          <b className="fonts"> /night</b>
                        </div>
                      </Card>
                      // </Link>
                    ))
                  : ""}
              </div>
            </Row>
          </div>
          <div className="bottom-sticky">
            <div className="top">
              <div onClick={() => {
                  
                  setOpen(!open)
                }}>
                <h2>
                  <span style={{ fontSize: "23px" }} className="rupee">
                    {" "}
                    ₹{" "}
                  </span>{" "}
                  {home.price_night} <b className="fonts"> /night</b>
                </h2>
                <div className="rating_icons">
                  <p className="stars">
                    {reviews !== null && (
                      reviews.length !== 0 && (
                        reviews.length === 1 ? 
                        <> <StarFilled className="star_icon" /> {reviews.length} Review</> : 
                        <> <StarFilled className="star_icon" /> {reviews.length} Reviews</>
                      )
                    )}
                  </p>
                </div>
              </div>
              <div>
                <div className="btn_search">
                  <Button
                    size="large"
                    className="btn-primary btn-large"
                    onClick={() => click()}
                    disabled={disable}
                  >
                    {home.instant_book ? "Book Now" : "Request to book"}
                  </Button>
                </div>
              </div>
            </div>
            {open ? (
              <Row>
                <Divider />

                <Col xs={24}>
                  <div>
                    <p>Check-in - Check-Out</p>
                    {/*<div className="date_picker">
                      <RangePicker
                        className="date-picker input-field"
                        style={{ width: "100%" }}
                        size="large"
                        defaultValue={
                          check_in
                            ? [
                                moment(check_in, dateFormat),
                                moment(check_out, dateFormat),
                              ]
                            : ""
                        }
                        disabledDate={current =>
                          disabledDates.some(date =>
                            current.isBetween(
                              moment(date["start"], format),
                              moment(date["end"], format),
                              "day"
                            )
                          )}
                        onChange={onChange}
                        format={dateFormat}
                      />
                    </div>
                            */}
                    <div className="datepick">
                    <DateRangePicker
                          startDatePlaceholderText="Check-in"
                          endDatePlaceholderText="Check-out"
                          startDateId="startDate1"
                          openDirection="up"
                          endDateId="endDate1"
                          startDate={checkin && moment(checkin)}
                          minDate={moment().subtract(1, "days").format("MM/DD/YYYY")} 
                          maxDate={moment().add(365, "days").format("MM/DD/YYYY")} 
                          endDate={checkout && moment(checkout)}
                          onDatesChange={({ startDate, endDate }) => { 
                            setPrice();
                            setCheckin(startDate)
                            setCheckout(endDate)
                          }}
                          isDayBlocked={current =>
                            disabledDates.some(date =>
                              current.isBetween(
                                moment(date["start"]),
                                moment(date["end"]),
                                "day"
                              )
                            )}
                          numberOfMonths={window.innerWidth < 600 ? 1 : 2}
                          displayFormat="YYYY-MM-DD"
                          focusedInput={focusedInput2}
                          onFocusChange={(focusedInput) => { setFocusedInput2(focusedInput)}}
                          block
                      />
                    </div>
                    <div className="guest-dropdown">
                      <div className="adult-count">
                        {" "}
                        <p>Guests</p>
                        <div className="guests-count">
                          <span className="counnt">
                            <Button
                              disabled={guests.adults > 0 ? false : true}
                              shape="circle-outline"
                              icon={<MinusOutlined />}
                              size="small"
                              onClick={() => {
                                handleAdult(0, guests.adults);
                                setPrice();
                              }}
                            ></Button>
                          </span>
                          <span className="counnt">
                            <Button size="small" shape="circle-outline">
                              {guests.adults}
                            </Button>
                          </span>
                          <span className="counnt">
                            <Button
                              shape="circle-outline"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                handleAdult(1, guests.adults);
                                setPrice();
                              }}
                              size="small"
                            ></Button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {price ? (
                    <div>
                      <div className="price">
                        <Row className="pricing_total">
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.per_night_average}x {price.days_to_booked}{" "}
                            nights
                          </p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.base_price}.00
                          </p>
                        </Row>

                        <Row className="pricing_total">
                          <p>Total Taxes</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.taxes_total}
                          </p>
                        </Row>
                        <Row className="pricing_total total-bg">
                          <p>Total</p>
                          <p>
                            <span className="rupee"> ₹ </span>
                            {price.grand_total}
                          </p>
                        </Row>

                        {visible ? (
                          <Row
                            justify="space-around"
                            style={{ marginTop: "1rem" }}
                          >
                            <Input
                              size="large"
                              style={{ width: "200px" }}
                              placeholder="Coupon Code"
                              onChange={(e) => setCoupon(e.target.value)}
                            />

                            <Button
                              size="middle"
                              className="btn-primary"
                              style={{
                                width: "80px",
                                height: "40px",
                              }}
                              onClick={() => fetchPrice("val")}
                            >
                              Apply
                            </Button>
                          </Row>
                        ) : (
                          <Row justify="center" style={{ marginTop: "1rem" }}>
                            <a onClick={() => setVisible(true)}>
                              Have Promo Code ?
                            </a>
                          </Row>
                        )}
                      </div>
                    </div>
                  ) : (""
                    /*<div className="btn_search">
                      <Button
                        size="large"
                        style={{ width: "auto" }}
                        className="btn-primary"
                        onClick={() => fetchPrice("val")}
                      >
                        Check Availability
                      </Button>
                    </div>*/
                  )}

                  <div className="footer_card">
                    <p>
                      You won’t be charged yet. <br></br>Certain reservations
                      may also require a security deposit.
                    </p>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}

const PropertyDetails = withRouter(PropertyView);

const LoadingContainer = (props) => (
  <div className="container">
    <Skeleton active />
    <Skeleton active />
  </div>
);

export default GoogleApiWrapper({
  apiKey: "AIzaSyBXZkEWMQhUxa6GXd-t5ZF-N_axqOdrjAE",
  LoadingContainer: LoadingContainer,
})(PropertyDetails);
