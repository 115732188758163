import React from "react";
/* import Logo from "../../assets/images/bannerLogo.svg"; */
import { Row, Col } from "antd";
//import Fade from "react-reveal/Fade";

function Banner({ data }) {
  
  return (
    <div className="container">
      <Row className="banner-wrapper" align="middle" gutter={16}>
        <Col className="banner-text" md={14} xs={24}>
          <p>{data["sub-title"]}</p>
          <h1>{data.title}</h1>
        </Col>
        <Col className="baner-image" offset={1} md={9} xs={20}>
          <img src={data.img} alt={data.title} height="150" />
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
