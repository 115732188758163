import React from 'react'
import MapboxMap from "../Map.js";
function Location({data}) {
  return (
    <div className='amenitiesWrapper'>
    <div className='amenitiesContentWrap'>
        <div className='amenitiesLegend'>
            Location
        </div>
        <div className='locationContent'>
            <MapboxMap lat={data.latitude} long={data.longitude}/>
        </div>
    </div>
    </div>
  )
}

export default Location