import React, { useState, useEffect } from "react";
import {
  Spin
} from "antd";
import { Helmet } from "react-helmet";
import axios from "axios";
import { withRouter } from "react-router-dom";
import NoNetwork from "../Home/noNetwork";



function SearchNew(props) {

  const [network, setNetwork] = useState(true);
  const [data, setData] = useState("");
 
  useEffect(() => {
    window.scrollTo(0, 0);
    Verify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Verify = async () => {
    const verifya = {"verification":props.match.params.verification}
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/auth/verify-link/social/roamhome`,
        verifya,
        config
      );
      const data = await res.data;
      setData(data)
    } catch (error) {
      setNetwork(false)
    }
  }


  return (
    <div>
      <Helmet>
        <title>
          {props.match.params.state
            ? `Search Results in ${
                props.match.params.state !== "null"
                  ? props.match.params.state
                  : "India"
              } - Roamhome Vacation Rentals`
            : " Roamhome Vacation Rentals"}
        </title>
        <meta name="description" content="Roamhome villa rentals" />
      </Helmet>
      {network ? (
        <div
          className="container"
          style={{ position: "relative", zIndex: "0" }}
        >
          {data === "" ? (
              <div className="verifying-remarks"><Spin size="large"/></div>
              ) : (
              <div className="verifying-remarks">{data.remarks}</div>
            
          )}
        </div>
      ) : (
        <NoNetwork />
      )}
    </div>
  );
}
export default withRouter(SearchNew);
