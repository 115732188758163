import React from "react";

function Cancel() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
      <li>
        The entire booking amount is required to be paid to reserve/block the
        dates of your stay at any of our properties. – The entire booking amount
        will be refunded to you if you cancel your booking within 48 hours of
        making the payment for the booking.
      </li>
      <li>
        {" "}
        100% of the total booking amount will be be refunded in case you cancel your booking thereafter, and upto 1 month before the check-in date (i.e. if the check-in is 12 PM, December 20, 2022, you will be eligible for 100% refund if you cancel your booking before 12 PM, November 20, 2022).
      </li>
      <li>
      75% of the total booking amount will be refunded in case you cancel your booking thereafter, and upto 15 days before the check-in date (i.e. if the check-in is 12 PM, December 20, 2022, you will be eligible for a 75% refund if you cancel your booking after 12 PM, November 20, 2022, and before 12 PM, December 5, 2022).
      </li>
      <li>
      50% of the total booking amount will be refunded in case you cancel your booking thereafter, and upto 7 days before the check-in date (i.e. if the check-in is 12 PM, December 20, 2022, you will be eligible for a 50% refund if you cancel your booking after 12 PM, December 5, 2022, and before December 13, 2022).
      </li>
      <li>
      No amount will be refunded for any cancellations less than 7 days before the check-in date. (i.e. if the check-in is 12 PM, December 20, 2022, you will receive no refunds if you cancel your booking after 12 PM, December 13, 2022). This applies even in cases when the booking is sought to be cancelled within 48 hours of confirmation or payment.
      </li>
      <li>Cancellation refunds will be processed within 7 Working days. In case of any cancellation under any policy, convenience charges and payment gateway charges are non-refundable.</li>
      <li>
        <strong>Covid Cancellation:</strong> If any member of your group tests positive for covid, then you will be entitled to travel credit for the coming year (i.e. if the check in is 12 PM, December 20, 2022, you will be able to use travel credit to check in till or before 12 PM, December 20, 2023) upon furnishing their covid positive report.
      </li>
    </div>
  );
}

export default Cancel;
