import React from "react";
import SERVER from "../../assets/images/server_down.svg";

export default function noNetwork() {
  return (
    <div
      className="container"
      style={{ display: "grid", justifyContent: "center" }}
    >
      <img src={SERVER} alt="connection_failed" height="250px" />
      <h1 style={{ marginTop: "3rem" }}>
        No Network, Please wait and refresh the page!{" "}
      </h1>
    </div>
  );
}
