import React from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Link } from "react-router-dom";

function PopularDestinations({ data }) {

  return (
    <div className="destination-wrapper container">
      <Fade left>
        <h1>{data.title}</h1>
      </Fade>
      <div className="row-wrapper row-wrapper-label">
        {data.experiences
          ? data.experiences
          .slice(0, 4)
          .map((x) => (
              <div className="col-place">
                <div className="img-wrapper">
                  <Flip left delay={500}>
                    <Link to={`/personalised/${x.title}`}>
                      <img
                        src={x.img}
                        alt={x.title}
                        style={{
                          width: "230px",
                          objectFit: "cover",
                          height: "300px",
                          borderRadius: "15px"
                        }}
                      />
                      <div className="imgCaption">{x.title}</div>
                    </Link>
                  </Flip>
                </div>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}

export default PopularDestinations;
