export const setSession = (authResult) => {
  // Set the time that the access token will expire at
  // let expiresAt = JSON.stringify(
  //   authResult.expiresIn * 1000 + new Date().getTime()
  // );
  sessionStorage.setItem("token", authResult.token);
  sessionStorage.setItem("email", authResult.email);
  sessionStorage.setItem("user_level", authResult.user_level);
  sessionStorage.setItem("google_token",authResult.google_token);
  sessionStorage.setItem("facebook_token",authResult.facebook_token);
};

export const setPaymentSession = (pay) => {
  sessionStorage.setItem("check_in", pay.check_in);
  sessionStorage.setItem("check_out", pay.check_out);
  sessionStorage.setItem("adults", pay.adults);
  sessionStorage.setItem("property_id", pay.property_id);
  sessionStorage.setItem("amount", pay.amount);
};

export const bookingStatus = (val) => {
  sessionStorage.setItem("status", val);
};

export const setFavSession = (data) => {
  localStorage.setItem("favItem", JSON.stringify(data));
};

export const sessionSort = (val) => {
  sessionStorage.setItem("sort", val ? val : "");
};

export const paginationActivity = (val) => {
  sessionStorage.setItem("pagination", val ? val : 1);
}

export const removeSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("email");
  sessionStorage.removeItem("user_level");
  sessionStorage.removeItem("google_token");
  sessionStorage.removeItem("facebook_token");

  window.location.reload();
};
