import { FileMarkdownTwoTone } from '@ant-design/icons'
import React, { useState } from 'react'
import parse from "html-react-parser";
import { Drawer } from 'antd';

function Description({ data }) {
    const [descriptionBox, setDescriptionBox] = useState(false)
    return (
        <div className='descriptionWrapper'>
            <div className='descriptionContentWrap'>
                <div className='descriptionHead'>
                    <div className='descriptionTitle'>
                        {data.property_title}
                    </div>
                    <div className='descriptionLocation'>
                        {data.city}, {data.state}
                    </div>
                </div>
                <div className='descriptionDetail'>
                    <div className='descriptionLegend'>
                        Details
                    </div>
                    <div className='descriptionDetails'>
                        <ul>
                            <li>{data.num_of_guests} guests</li>
                            <li>{data.num_of_bedroom} bedrooms</li>
                            <li>{data.num_of_beds} beds</li>
                            <li>{data.num_of_bath} bathrooms</li>
                        </ul>
                    </div>
                </div>
                <div className='descriptionAbout'>
                    <div className='descriptionLegend'>
                        About the listing
                    </div>
                    <div className='descriptionContent'>
                        {parse(data.property_desc, {trim: true})}
                    </div>
                    <div className='readMoreContent'>
                        <button onClick={() => {
                            setDescriptionBox(true)
                        }}>Read more</button>
                    </div>
                </div>
            </div>
            <>
            <Drawer title={"About the listing"} visible={descriptionBox} onClose={() => {setDescriptionBox(false)}} width={"100%"}>
                <div className='descriptionLongContent'>
                    {parse(data.property_desc, {trim: true})}
                </div>
            </Drawer>
            </>


        </div>
    )
}

export default Description