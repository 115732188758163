import React from "react";
import { Route, Switch } from "react-router-dom";
import GeneralTerms from "./generalTerms";
import PaymentPolicy from "./paymentsPolicy";
import CancelPolicy from "./cancel";
import Adds from "./Add";
import Juricdiction from "./juridiction";
import Early from "./Early";
import Indemnity from "./indemnity";
import Double from "./Double";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/terms" component={GeneralTerms} />
      <Route exact path="/terms/payment" component={PaymentPolicy} />
      <Route exact path="/terms/cancel" component={CancelPolicy} />
      <Route exact path="/terms/add" component={Adds} />
      <Route exact path="/terms/indemnity" component={Indemnity} />
      <Route exact path="/terms/early" component={Early} />
      <Route exact path="/terms/double" component={Double} />
      <Route exact path="/terms/juridiction" component={Juricdiction} />
    </Switch>
  );
};

export default Routes;
