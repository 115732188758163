import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  message,
} from "antd";
import axios from "axios";

import BedRooms from "../../assets/images/bed.svg";
import BathRooms from "../../assets/images/bathTap.svg";
import HeartFilled from "../../assets/images/HeartFilled.svg";
import HeartBorder from "../../assets/images/HeartBorder.svg";
import { setFavSession } from "../Utils/session";
import { Tooltip } from "antd";

import Place from "../../assets/images/place.svg";
import { StarFilled } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
function Selections(props) {
  const [click, setClick] = useState(false);
  const { data, history } = props;

  const [FavData, setFavData] = useState([
    { status: false, id: "" },
    { status: false, id: "" },
    { status: false, id: "" },
    { status: false, id: "" },
    { status: false, id: "" },
    { status: false, id: "" },
  ]);

  const FavList = JSON.parse(localStorage.getItem("favItem"));

  const handleFavorite = async (id, i, bool) => {
    const copy = FavData;
    copy[i].status = bool;
    copy[i].id = id;
    setFavData(copy);
    setFavSession(copy);

    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/booking/booking/add_favourite`,
        { property_id: id },
        config
      );

      const data = await res.data;
      
      if (data.statues === 200) {
        message.success(data.remarks);
      } else {
        message.info(data.remarks);
      }
      // fetchFavourites();
    } catch (error) {
     
    }
  };

  const handleRoute = (id) => {
    if (!click) {
     
      history.push(`/desc/${id}`);
    }
  };

  const numberFormat = (value) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0, 
    }).format(value);
}


  return (
    <div className="container selection-wrapper">
      {/* <Fade left delay={400}> */}
      <h1>{data.title}</h1> {/* </Fade> */}
      <div className="selections-card-wrapper">
        {data.properties
          ? data.properties.slice(0, 9).map((x, i) => (
            <Link rel="noopener noreferrer" to={`/desc/${x.url}`} onClick={(e) =>  {e.preventDefault(); handleRoute(x.url);}}>
              <Card
                key={i}
                className="selection-card"
                style={{
                  boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
                }}
                cover={
                  <div className="fav_img">
                    <img
                      src={
                        x.featured_img[0].img_960[0]
                          ? x.featured_img[0].img_960[0].url
                          : ""
                      }
                      alt={`Villa in ${x.city}, ${x.state} | ${x.property_title}`}
                    />
                    {FavList ? (
                      FavList[i].status ? (
                        <div className="heart_icon">
                          <img
                            src={HeartFilled}
                            alt="HeartBorder"
                            onMouseEnter={() => setClick(true)}
                            onMouseLeave={() => setClick(false)}
                            onClick={() =>
                              handleFavorite(x.listing_id, i, false)
                            }
                          />
                        </div>
                      ) : (
                        <div className="heart_icon">
                          <img
                            src={HeartBorder}
                            alt="HeartBorder"
                            onMouseEnter={() => setClick(true)}
                            onMouseLeave={() => setClick(false)}
                            onClick={() =>
                              handleFavorite(x.listing_id, i, true)
                            }
                          />
                        </div>
                      )
                    ) : (
                      <div className="heart_icon">
                        <img
                          src={HeartBorder}
                          alt="HeartBorder"
                          onMouseEnter={() => setClick(true)}
                          onMouseLeave={() => setClick(false)}
                          onClick={() => handleFavorite(x.listing_id, i, true)}
                        />
                      </div>
                    )}
                  </div>
                }
              >
                <div className="name_rating">
                  <Tooltip title={x.property_title}>
                    <h3>{x.property_title}</h3>
                  </Tooltip>
                  <p>
                    {x.rating}
                    <StarFilled className="star_icon" />
                  </p>
                </div>
                <p className="place_name">
                {x.city && 
                (
                    <>
                    <span>{x.city},</span>
                    {""}
                    <span> {x.state}</span>
                    </>
                )}
                {!x.city && (
                    <>
                    <span>{x.state},</span>
                    {""}
                    <span> {x.country}</span>
                    </>
                )}
                </p>
                <Row style={{ marginBottom: "5px" }}>
                  <Col span={8}>
                    {" "}
                    <div className="card_img_icon_svg">
                      <img src={Place} alt="bed" />
                    </div>
                    <p className="name_icon">{x.type_of_listing}</p>
                  </Col>
                  <Col span={8}>
                    <div className="card_img_icon_svg">
                      <img src={BedRooms} alt="Bedrooms" />
                    </div>
                    <p className="name_icon">{x.num_of_bedroom} {(x.num_of_bedroom > 1) ? "Bedrooms" : "Bedroom"}</p>
                  </Col>
                  <Col span={8}>
                    <div className="card_img_icon_svg">
                      <img src={BathRooms} alt="bathTub" />
                    </div>
                    <p className="name_icon">{x.num_of_bath} {(x.num_of_bath > 1) ? "Bathrooms" : "Bathroom"}</p>
                  </Col>
                </Row>
                <div className="d-flex">
                  <h4>
                    {x.currency} {numberFormat(x.price_night)}
                  </h4>
                  <b className="fonts"> /night</b>
                </div>
              </Card>
              </Link>
            ))
          : ""}{" "}
      </div>
      <div className="text-center" style={{ marginTop: "2rem" }}>
        <Link to={`/search`}>
          <Button size="large" className="btn-primary">
            Show more
          </Button>
        </Link>
      </div>
    </div>
  );
}
export default withRouter(Selections);
